import { Stack, SvgIcon, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React from 'react';
import { MuiSvgType } from 'utils/mui.utils';
import { useSettingsContext } from '../../../providers';
import { Dimens } from '../../../assets';

type IconPosition = 'start' | 'end';
type Color = 'success' | 'highlighted' | 'secondary'

export interface TagProps {
    icon?: MuiSvgType,
    iconPosition?: IconPosition,
    color?: Color;
    label: string | undefined;
    fontSize?: string;
    iconSize?: number;
    sx?: SxProps;
}

const Tag: React.FC<TagProps> = ({
	icon,
	iconPosition = 'start' as IconPosition,
	color = 'success' as Color,
	label,
	fontSize = Dimens.TEXT_SMALL as string,
	iconSize = 14,
	sx,
}) => {
	const { colors } = useSettingsContext();

	const getColor = () => {
		if (color === 'success') {
			return colors.recomended;
		}
		if (color === 'secondary') {
			return colors.textSecondary;
		}
		return colors.highlight;
	};

	const getPadding = () => {
		if (!icon) {
			return {
				pr: 2,
				pl: 2,
			};
		}

		if (iconPosition === 'start') {
			return {
				pl: 1,
				pr: 2,
			};
		}
		return {
			pr: 1,
			pl: 2,
		};
	};

	return (
		<Box
			sx={{
				background: getColor(),
				p: '2px',
				borderRadius: '16px',
				color: '#fff',
				width: 'fit-content',
				...sx,
			}}
		>
			<Stack direction="row" spacing={1} sx={{ ...getPadding() }} alignItems="center">
				{icon && iconPosition === 'start' && (
					<SvgIcon component={icon} sx={{ width: iconSize }} />
				)}

				<Typography fontSize={fontSize} fontWeight="600">{label}</Typography>
				{icon && iconPosition === 'end' && (
					icon
				)}
			</Stack>
		</Box>
	);
};

export default Tag;
