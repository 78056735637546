import React from 'react';
import { styled } from '@mui/material/styles';
import {
	Grid, GridSize, SxProps, useTheme,
} from '@mui/material';
import { Dimens } from 'assets';
import { Subtitle } from '../../atoms';

interface SimpleArticleProps {
    title: string,
    xs?: boolean | GridSize | undefined
    md?: boolean | GridSize | undefined
    sm?: boolean | GridSize | undefined
    maxWidth?: string;
    minWidth?: string;
    titleSx?: SxProps;
	childTextSize?: string | undefined
}

const SimpleArticle: React.FC<SimpleArticleProps> = ({
	children,
	title,
	xs = false,
	md = false,
	sm = false,
	maxWidth,
	minWidth,
	titleSx,
	childTextSize = Dimens.TEXT_SUBTITLE,
}) => {
	const theme = useTheme();

	const Label = styled('div')(() => ({
		fontSize: childTextSize,
		fontWeight: 500,
		whiteSpace: 'pre-line',
		marginTop: 10,
		color: theme.lighter,
	}));

	return (
		<Grid item xs={xs} md={md} sm={sm} maxWidth={maxWidth} minWidth={minWidth} sx={{ position: 'relative' }}>
			<Subtitle sx={titleSx}>{title}</Subtitle>
			<Label>{children}</Label>
		</Grid>
	);
};

export default SimpleArticle;
