import {
	FormControlLabel, Radio, RadioGroup, Skeleton, Stack, Typography, useTheme,
} from '@mui/material';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Dimens } from 'assets';
import { Card } from 'data/models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateKey } from 'utils/general.utils';
import { Card as NNCard, CardItem } from '..';
import { CheckItem } from '../../atoms';

export type SelectMode = 'saved-card' | 'new-card';

export interface CardSelectorProps {
   cards: Card[];
   minHeight?: number;
   title: string;
   loading?: boolean;
   onSelect?: (item: Card | undefined) => void;
   onSelectModeChange?: (mode: SelectMode) => void;
   onStripeFormComplete?: (complete: boolean) => void;
   onShouldSaveCardChange?: (save: boolean) => void;
}

const CardSelector: React.FC<CardSelectorProps> = ({
	cards,
	minHeight,
	title,
	loading,
	onSelect,
	onSelectModeChange,
	onStripeFormComplete,
	onShouldSaveCardChange,
}) => {
	const { t } = useTranslation('translations');
	const [selected, setSelected] = useState<Card | undefined>();
	const [radioValue, setRadioValue] = useState<SelectMode>('saved-card');
	const theme = useTheme();

	const handleClick = (item: Card) => {
		if (radioValue !== 'saved-card') return;
		setSelected(item);
		onSelect?.call(0, item);
	};

	useEffect(() => {
		const usableList = cards.filter((item) => item.isUsable);
		if (!selected && usableList.length > 0) {
			setSelected(usableList[0]);
			onSelect?.call(0, usableList[0]);
		}
		if (usableList.length === 0) {
			onSelectModeChange?.call(0, 'new-card');
		}
	}, [cards, onSelect, onSelectModeChange, selected]);

	const handleStripeOnChange = (event: StripeCardElementChangeEvent) => {
		onStripeFormComplete?.call(0, event.complete);
	};

	const cardList = cards.map((item) => (
		<CardItem
			key={generateKey(`${item.name}}`)}
			name={item.name}
			brand={item.brand}
			last4={item.last4}
			fullExpiry={item.fullExpiry}
			isUsable={item.isUsable && radioValue === 'saved-card'}
			country={item.country}
			selected={selected === item}
			onClick={() => handleClick(item)}
		/>
	));

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target as HTMLInputElement;
		setRadioValue(value as SelectMode);
		if (value !== 'saved-card') {
			setSelected(undefined);
			onSelect?.call(0, undefined);
		}
		onSelectModeChange?.call(0, value as SelectMode);
	};

	return (
		<NNCard
			shadows={false}
			contentSx={{ minHeight }}
			content={(
				<>
					<Typography sx={{ mb: 2 }} color={theme.disabled} fontSize={Dimens.TEXT_DEFAULT} fontWeight="700">
						{title}
					</Typography>
					<Stack spacing={2}>
						{loading && (
							<>
								<Skeleton variant="rectangular" height={50} width="100%" sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={50} width="100%" sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={50} width="100%" sx={{ borderRadius: '10px' }} />
							</>
						)}

						{!loading && cardList.length > 0 && (
							<RadioGroup defaultValue="saved-card" aria-label="select-card" name="card-radios" onChange={handleRadioChange}>
								<FormControlLabel value="saved-card" control={<Radio />} label={t('useASavedCard').toString()} />
								<Stack spacing={2} sx={{ p: 2 }}>
									{cardList}
								</Stack>
								<FormControlLabel value="new-card" control={<Radio />} label={t('useANewCard').toString()} />

								<form>
									<Stack sx={{ p: 2 }}>
										<CardElement id="card-element" onChange={handleStripeOnChange} />
										<CheckItem label={t('saveCardForFuture')} onChange={(e) => onShouldSaveCardChange?.call(0, e)} />
									</Stack>
								</form>
							</RadioGroup>
						)}

						{cardList.length === 0 && (
							<form>
								<Stack sx={{ p: 2 }}>
									<CardElement id="card-element" onChange={handleStripeOnChange} />
									<CheckItem label={t('saveCardForFuture')} onChange={(e) => onShouldSaveCardChange?.call(0, e)} />
								</Stack>
							</form>
						)}
					</Stack>
				</>
			)}
		/>
	);
};
export default CardSelector;
