/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import { ApiResponse, ArticleType, Category } from '../../models';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

const fetchVehicleCategories = async (): Promise<ApiResponse<Category[]>> => {
	const resp = await reqGet({
		url: Endpoint.VEHICLE_CATEGORIES,
	});
	const data = resp.data as ApiResponse<ArticleType[]>;
	data.statusCode = resp?.status;
	return data;
};
export const useVehicleCategories = () => useRequest(() => fetchVehicleCategories());
