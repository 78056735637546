import React from 'react';
import {
	AppBar as MaterialAppBar,
	Box, IconButton, Skeleton, Stack, Toolbar, Typography, useScrollTrigger, useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ajulio } from 'assets/icons';
import { Dimens } from 'assets';
import { useSettingsContext } from 'providers';
import useEffectCustom from 'hooks/useEffectCustom';
import { currencyService } from 'data/api';
import AppMenu from '../app-menu/AppMenu';

import CurrencySelect from '../currency-select/CurrencySelect';
import LanguageSelect from '../language-select/LanguageSelect';

import { UserMenu } from '../../organisms';

interface Props {
   children: React.ReactElement;
}

function ElevationScroll(props: Props) {
	const { children } = props;
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});

	return React.cloneElement(children, {
		elevation: trigger ? 2 : 0,
	});
}

const AppBar: React.FC = () => {
	const currency = currencyService.useFetchCurrencyRates();
	const theme = useTheme();
	const { updateUIMode, setCurrency } = useSettingsContext();

	useEffectCustom(() => {
		currency.execute();
	});

	return (
		<ElevationScroll>
			<MaterialAppBar color="inherit">
				<Toolbar sx={{
					mt: 1,
					ml: {
						xs: 0, md: 15, lg: 20, xl: 45,
					},
					mr: { xs: 0, lg: 20, xl: 45 },
				}}
				>
					<Box sx={{ mr: 1 }}>
						<Link to="/" style={{ textDecoration: 'none' }}>
							<img src={ajulio} alt="logo" width={Dimens.LOGO} />
						</Link>
					</Box>

					<Typography
						variant="h6"
						component="div"
						sx={{
							fontWeight: 700,
							fontSize: Dimens.APP_NAME,
							display: { xs: 'none', md: 'flex' },
						}}
					>
						<Link to="/" style={{ textDecoration: 'none', color: theme.palette.title?.color }}>
							{process.env.REACT_APP_NAME}
						</Link>
					</Typography>

					<Box sx={{ flexGrow: 1 }} />
					<Stack direction={{ xs: 'row-reverse', md: 'row' }} spacing={2}>
						<Box sx={{ mt: 1.5 }}>
							<AppMenu />
						</Box>
						<Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
							<LanguageSelect />
							{!currency.loading && <CurrencySelect data={currency.data} onChange={setCurrency} />}
							{currency.loading && (
								<Box>
									<Skeleton variant="rectangular" height={34} width="90px" sx={{ borderRadius: '5px' }} />
								</Box>
							)}

							<IconButton sx={{ display: { xs: 'none', md: 'flex' } }} color="inherit" onClick={updateUIMode}>
								{theme.palette.mode === 'dark' ? <Brightness7Icon sx={{ color: theme.lighter }} /> : <Brightness4Icon sx={{ color: theme.lighter }} />}
							</IconButton>
							<UserMenu />
						</Stack>
					</Stack>
				</Toolbar>
			</MaterialAppBar>
		</ElevationScroll>
	);
};

export default AppBar;
