import {
	CardMedia, Container, Grid, Stack, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { thirdSectionBanner } from 'assets/images';
import { CardCategory, CategorySkeleton } from 'components/molecules';
import { Footer } from 'components/organisms';
import { delay, log } from '../../../../utils/general.utils';
import { FilterButton, Subtitle, Title } from '../../../atoms';
import mockSearchResult from '../../../../data/mock/categories_by_filter.json';
import Category from '../../../../data/models/category.model';

const CommercialVehiclesScreen: React.FC = () => {
	const { t } = useTranslation('translations');
	const [type, setType] = React.useState<number>(1);
	const [typeClicked, setTypeClicked] = React.useState<number>(1);
	const [loading, setLoading] = React.useState(false);
	const [result, setResult] = React.useState<Category[] | undefined>();

	const theme = useTheme();

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const onClick = (mType: number) => {
		setTypeClicked(mType);
	};

	React.useEffect(() => {
		setLoading(true);
		(async () => {
			await delay(1000);
			const values = mockSearchResult.map((item) => Object.assign(new Category(), item));
			setResult(values);
			setType(typeClicked);
			setLoading(false);
		})();
	}, [typeClicked]);

	const handleCategoryClick = (category: Category) => {
		// Just to see what is happening
		// eslint-disable-next-line no-console
		log('ComercialVehicles', category);
		// setSelected(category)
		// setOpenDetail(true);
	};

	const resultFilter = result
		?.slice(2, result.length)
		.map((item) => (
			<CardCategory
				key={item.id}
				shortTitle={item.name || ''}
				title={item.cta || ''}
				subtitle=""
				image={item.image || ''}
				pricePerDay={item.pricePerDay || 0}
				features={item.features}
				mode="full"
				onClick={() => handleCategoryClick(item)}
			/>
		));
	return (
		<>
			<Container maxWidth="lg" sx={{ mt: 6, mb: 6 }}>
				<Grid
					container
					sx={{
						background: theme.palette.bgSecondary?.firstColor,
						pl: 8,
						pr: 8,
						borderRadius: '10px',
						flexDirection: { xs: 'column-reverse', md: 'row' },
					}}
				>
					<Grid item md={5} sx={{ p: 5 }}>
						<Stack direction="column" sx={{ height: '100%' }}>
							<Box sx={{ flexGrow: 1 }} />
							<Title>{t('commercialVehiclesTitle')}</Title>
							<Subtitle>{t('commercialVehiclesInfo')}</Subtitle>
						</Stack>
					</Grid>
					<Grid item md={7}>
						<CardMedia component="img" alt="Commercial vehicle banner" image={thirdSectionBanner} />
					</Grid>
				</Grid>

				<Grid container sx={{ mt: 2, mb: 2 }}>
					<Grid item md={3}>
						<Stack flexDirection="column" justifyItems="start" alignItems="start">
							<FilterButton
								text="Commercial 1"
								sx={{ mt: 2 }}
								loading={typeClicked === 1 && loading}
								disabled={typeClicked !== 1 && loading}
								selected={type === 1}
								onClick={() => onClick(1)}
							/>
							<FilterButton
								text="Commercial 2"
								sx={{ mt: 2 }}
								loading={typeClicked === 2 && loading}
								disabled={typeClicked !== 2 && loading}
								selected={type === 2}
								onClick={() => onClick(2)}
							/>
							<FilterButton
								text="Commercial 3"
								sx={{ mt: 2 }}
								loading={typeClicked === 3 && loading}
								disabled={typeClicked !== 3 && loading}
								selected={type === 3}
								onClick={() => onClick(3)}
							/>
						</Stack>
					</Grid>

					<Grid item md={9} sx={{ background: theme.palette.bgSecondary?.firstColor, borderRadius: '10px', minHeight: 500 }}>
						{loading && (
							<Stack sx={{ p: 2 }}>
								<CategorySkeleton quantity={6} />
							</Stack>
						)}
						{!loading && (
							<Stack sx={{ p: 2 }} spacing={2}>
								{resultFilter}
							</Stack>
						)}
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</>
	);
};

export default CommercialVehiclesScreen;
