import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
	Stack, SvgIcon, TextField, useTheme,
} from '@mui/material';
import { CalenderIcon } from 'assets/icons';
import useLanguage from 'providers/language.provider';
import React, { FC, useState } from 'react';

interface DatePickerProps {
	value?: Date;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleChange?: (date: any, keyboardInputValue?: string | undefined) => void;
	minDate?: Date;
	maxDate?: Date
}

const DatePicker: FC<DatePickerProps> = (props) => {
	const {
		value, handleChange, minDate, maxDate,
	} = props;
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const { localeFns } = useLanguage();

	return (
		<LocalizationProvider locale={localeFns} dateAdapter={AdapterDateFns}>
			<Stack direction="row" alignItems="center">
				<SvgIcon component={CalenderIcon} sx={{ mr: 1 }} onClick={() => setOpen(true)} />
				<DesktopDatePicker
					inputFormat="dd-MM-yyyy"
					open={open}
					minDate={minDate}
					maxDate={maxDate}
					onClose={() => setOpen(false)}
					value={value}
					disableMaskedInput
					onChange={(date, keyboardInputValue) => handleChange?.call(0, date, keyboardInputValue)}
					renderInput={(params) => (
						<TextField
							{...params}
							sx={{ color: theme.palette.title?.color }}
							variant="standard"
							InputProps={{
								...params.InputProps,
								disableUnderline: true,
								endAdornment: false,
							}}
						/>
					)}
				/>
			</Stack>
		</LocalizationProvider>
	);
};

export default DatePicker;
