import { Container, Grid } from '@mui/material';
import { NoResult } from 'assets/icons';
import { Subtitle, Title } from 'components/atoms';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface NoResultProps{
	subTitle: string
}

const NoResults: FC<NoResultProps> = (props) => {
	const { subTitle } = props;
	const { t } = useTranslation('translations');
	return (
		<Grid item md={12} xs={12}>
			<Container
				maxWidth="lg"
				sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			>
				<NoResult height="80%" width="80%" style={{ alignSelf: 'center' }} />
			</Container>
			<Title sx={{ textAlign: 'center' }}>{t('noCarsFoundTitle')}</Title>
			<Subtitle sx={{ textAlign: 'center' }}>{subTitle}</Subtitle>
		</Grid>
	);
};

export default NoResults;
