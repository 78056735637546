import React from 'react';
import {
	Box, IconButton, Stack, SvgIcon, Typography, useTheme,
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Delete } from 'assets/icons';

interface CardItemProps {
    name?: string;
    brand: string;
    country: string;
    last4: string;
    fullExpiry: string;
    isUsable: boolean;
    selected?: boolean;
    onClick: ()=>void;
	deletable?: boolean;
	deleteCard?: () => void
}

const CardItem: React.FC<CardItemProps> = ({
	name,
	brand,
	country,
	last4,
	fullExpiry,
	isUsable,
	selected,
	onClick,
	deletable,
	deleteCard,
}) => {
	const theme = useTheme();
	let color = '';
	if (!isUsable) {
		color = theme.disabled;
	} else if (selected) { color = 'title.color'; } else {
		color = 'title.color';
	}
	return (
		<Box onClick={onClick} style={{ ...((isUsable && !deletable) ? { cursor: 'pointer' } : {}) }}>
			<Box
				bgcolor="bgSecondary.firstColor"
				sx={{
					p: 1,
					borderRadius: 1,
					border: selected ? `0.5px solid ${theme.palette.primary.main}` : '',
					color,
				}}
			>
				<Stack
					direction="row"
					spacing={2}
					justifyContent="space-between"
				>
					<Stack direction="row" spacing={2} alignItems="center">
						<CreditCardIcon sx={{
							color: selected ? theme.palette.primary.main : theme.palette.title?.color,
						}}
						/>
						<Stack direction="column">
							<Typography fontSize="14px">{name?.toUpperCase()}</Typography>
							<Typography fontSize="14px">
								{' '}
								{brand.toUpperCase()}
								{' '}
								-
								{' '}
								{country}
								{' '}
								-
								{' '}
								{fullExpiry}
								{' '}
&nbsp;&nbsp;
								{' '}
								<span style={{ fontSize: 20 }}>**** **** **** </span>
								{last4}
							</Typography>
						</Stack>
					</Stack>
					{deletable && (
						<IconButton color="error" sx={{ p: 0, alignSelf: 'flex-end' }} onClick={() => deleteCard?.call(0)}>
							<SvgIcon component={Delete} />
						</IconButton>
					)}
				</Stack>
			</Box>
		</Box>
	);
};

export default CardItem;
