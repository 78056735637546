import {
	Container, Grid, Stack, Typography, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Footer } from 'components/organisms';
import {
	SimpleCardCategory, CardItineraryInfo, OptionalItemsSelector, SimpleArticle, Card,
} from 'components/molecules';
import { useHistory } from 'react-router-dom';
import { DefaultButton, Subtitle, Title } from '../../../atoms';
import { Dimens } from '../../../../assets';
import { delay } from '../../../../utils/general.utils';
import { AppLinks } from '../../../routes';

const ReservationExtrasScreen = (): JSX.Element => {
	const [loading, setLoading] = React.useState(true);
	const theme = useTheme();
	const history = useHistory();

	React.useEffect(() => {
		(async () => {
			await delay(1000);
			setLoading(false);
		})();
	}, []);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleReservationClick = () => {
		history.push(AppLinks.RESERVATION_COMPLETING);
	};

	return (
		<>
			<Box sx={{ background: theme.palette.bgSecondary?.firstColor, pb: 10 }}>
				<Container maxWidth="lg">
					<Grid container sx={{ pt: 2 }} spacing={2}>

						<Grid item xs={12} sm={6} md={4}>
							<Stack>
								<SimpleCardCategory features={[]} />
								<CardItineraryInfo pickupLocation="TESTE A" returnLocation="TESTE B" pickupDate="2021-01-10 16:35:54" returnDate="2021-02-10 16:35:54" />
							</Stack>

						</Grid>

						<Grid item xs={12} sm={6} md={8}>
							<div style={{ marginBottom: 18 }}>
								<Subtitle
									fontSize={Dimens.TEXT_DEFAULT as string}
								>
									Escolha os Extras (Sujeito a disponibilidade)
								</Subtitle>
							</div>

							<Stack>
								<OptionalItemsSelector
									title="OPCIONAIS"
									minHeight={250}
									loading={loading}
									optionals={[]}
								/>
								<Card
									shadows={false}
									content={(
										<>
											<Typography sx={{ mb: 2 }} color={theme.disabled} fontSize={Dimens.TEXT_DEFAULT} fontWeight="700">INFORMAÇÕES IMPORTANTES</Typography>
											<Grid container spacing={2}>
												<Grid item md={3}>
													<SimpleArticle title="REQUISITOS DO CONDUTOR" titleSx={{ fontWeight: '700 !important', fontSize: Dimens.TEXT_DEFAULT as string }}>
														<Subtitle fontSize={Dimens.TEXT_DEFAULT as string}>
															Idade Mínima 25
															1 Cartão de Crédito
															No momento de levantamento, apresente o mesmo cartão de crédito,
															utilizado para fazer a sua reserva online, em seu nome.
														</Subtitle>
													</SimpleArticle>
												</Grid>

												<Grid item md={3}>
													<SimpleArticle title="O QUE TRAZER CONSIGO" titleSx={{ fontWeight: '700 !important', fontSize: Dimens.TEXT_DEFAULT as string }}>
														<Subtitle fontSize={Dimens.TEXT_DEFAULT as string}>
															{`No momento de levantamento, por favor apresente a sua carta de condução
															e documento de identificação, bem como um cartão de crédito.`}
														</Subtitle>
													</SimpleArticle>
												</Grid>

												<Grid item md={3}>
													<SimpleArticle title="POLÍTICA DE COMBUSTÍVEL" titleSx={{ fontWeight: '700 !important', fontSize: Dimens.TEXT_DEFAULT as string }}>
														<Subtitle fontSize={Dimens.TEXT_DEFAULT as string}>
															{`No momento de levantamento, por favor apresente a
															sua carta de condução
															e documento de identificação, bem como um cartão de crédito.`}
														</Subtitle>
													</SimpleArticle>
												</Grid>

												<Grid item md={3}>
													<SimpleArticle title='O QUE SIGNIFICA "OU SIMILAR"?' titleSx={{ fontWeight: '700 !important', fontSize: Dimens.TEXT_DEFAULT as string }}>
														<Subtitle fontSize={Dimens.TEXT_DEFAULT as string}>
															{`A garantia de marca e modelo varia. A viatura alugada poderá não ser a apresentada.
															No entanto, será uma viatura idêntica do mesmo tipo, performance e grupo.`}
														</Subtitle>
													</SimpleArticle>
												</Grid>
											</Grid>
										</>
									)}
								/>

								<Grid container spacing={3}>
									<Grid item xs={12} sm={12} md={8}>
										<Card
											shadows={false}
											contentSx={{ height: '100%' }}
											content={(
												<>
													<Typography sx={{ mb: 2 }} color={theme.disabled} fontSize={Dimens.TEXT_DEFAULT} fontWeight="700">TERMOS</Typography>
													<Subtitle fontSize={Dimens.TEXT_DEFAULT as string}>
														<p>- Aceito as condições de aluguer</p>
														<p>
															{`Tarifa garantida. Impostos e Suplementos estão sujeitos a
														 alterações caso não estejam incluídos na tarifa.`}

														</p>
														<p>
															{`'Total' não inclui qualquer item adicional que poderá escolher na estação de aluguer 
														ou qualquer custo decorrente do aluguer (como danos, combustível ou encargos de viação).`}

														</p>
													</Subtitle>
												</>
											)}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={4}>
										<Card
											shadows={false}
											contentSx={{ height: '100%' }}
											content={(
												<>
													<Typography sx={{ mb: 2 }} color={theme.disabled} fontSize={Dimens.TEXT_DEFAULT} fontWeight="700">O QUE VOCÊ PAGA NO BALCÃO:</Typography>

													<Stack justifyContent="center" alignItems="center">
														<Title>€ 756,38</Title>
														<Subtitle>252€ / dia</Subtitle>
													</Stack>
													<Stack justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
														<DefaultButton text="Reservar" minWidth="150px" onClick={() => handleReservationClick()} />
													</Stack>
												</>
											)}
										/>
									</Grid>
								</Grid>
							</Stack>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Footer />
		</>
	);
};

export default ReservationExtrasScreen;
