/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export enum StorageKeys {
    TOKEN = '_AAYTML',
    AUTHENTICATION_TOKEN = '_AATLKS',
    REFRESH_TOKEN = '_AAQNAS',
    USER = '_AADFGO',
    UI_MODE = '_MMERT',
    INITIAL_SETTINGS = '_SS12GH',
    FILTER = '_FFIADJ',
    LANGUAGE = '_LDADDF',
	HASH = '_JHGSFRDFE'
}

export const setStorageItem = (key: StorageKeys, value: any) => {
	if (typeof value === 'string' || value instanceof String) {
		localStorage.setItem(key, (value as unknown) as string);
	} else {
		localStorage.setItem(key, JSON.stringify(value));
	}
};

export const getStorageItem = (key: StorageKeys) => {
	const mValue = localStorage.getItem(key);
	if (mValue == null) return null; // TO stop here
	try {
		return JSON.parse(mValue);
	} catch {
		return mValue;
	}
};

export const removeStorageItem = (key: StorageKeys) => {
	localStorage.removeItem(key);
};

export const clearStorage = () => {
	localStorage.clear();
};
