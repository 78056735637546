import React, { JSXElementConstructor, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Lottie } from '@crello/react-lottie';
import useEffectCustom from 'hooks/useEffectCustom';
import animationData from '../../../assets/lotties/car-running.json';
import { useSettingsContext } from '../../../providers';

export const LoadingMessage: React.FC = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	const Container = styled('div')(() => ({
		color: '#FFF',
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	}));

	return (
		<Container>
			<Lottie config={defaultOptions} height="200px" width="400px" />
		</Container>
	);
};
// @TODO see other solutions for it
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withSplashScreen = (WrappedComponent: JSXElementConstructor<any>)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
	: (props: any) => React.ReactElement => (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	props: any,
): React.ReactElement => {
	const [loading, setLoading] = useState<boolean>(true);
	const { getInitialRequest } = useSettingsContext();

	useEffectCustom(() => {
		(async () => {
			try {
				await getInitialRequest();
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		})();
	});

	return (
		<div>
			{loading && <LoadingMessage />}
			{!loading && <WrappedComponent {...props} />}
		</div>
	);
};

export default withSplashScreen;
