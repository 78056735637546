/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
	createContext, FC, useContext, useMemo, useState,
} from 'react';

export type AlertType = 'success' | 'info' | 'error' | 'warning';
export type AlertProps = {
    message: string;
    duration?: number;
    type?: AlertType;
}
export type FeedbackContextProps = {
    open:boolean;
    setAlert: (message: AlertProps) => void;
    handleAlertClose: (event?: React.SyntheticEvent | Event, reason?: string) => void
    alertProps: AlertProps | undefined;
}

export const FeedbackContext = createContext<FeedbackContextProps>({} as FeedbackContextProps);

export const FeedbackProvider: FC = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [alertProps, setAlertProps] = useState<AlertProps>();

	const setAlert = (mOptions: AlertProps) => {
		let options = mOptions;
		options = {
			...{
				duration: 6000,
				type: 'success',
			},
			...options,
		};

		setAlertProps(options);
		setOpen(true);
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const value = useMemo(
		() => ({
			open,
			setAlert,
			alertProps,
			handleAlertClose,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[open],
	);

	return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>;
};

export default function useFeedbackContext() {
	return useContext(FeedbackContext);
}
