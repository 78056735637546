enum Endpoint {
    COUNTRY_ICONS_URL = 'https://flagcdn.com/{0}.svg',

    INITIAL_SETTINGS = 'initial-settings',
    ORGANIZATIONS = 'organizations',
    CURRENCY_RATES = 'currency-rates',
    FILTERS = 'filters',
    CATEGORIES_BY_FILTER = 'categories/by-filter',

    RESERVATIONS = 'reservations',
    RESERVATION_SERVICES = 'reservations/{0}/services',
    RESERVATIONS_BY_ID = 'reservations/{0}',
    RESERVATION_CONTRACT = 'reservations/{0}/contract',
    RESERVATION_PROOF = 'reservations/{0}/proof',
    CANCEL_RESERVATION = 'reservations/{0}/cancel',
    RESERVATION_INVOICE = 'reservations/invoices/{0}',
    RESERVATION_TOLLS = 'reservations/{0}/tolls',
    RESERVATION_PAYMENT_SALT = 'reservations/create-payment-salt',
    RESERVATION_COMPLETE_PAYMENT = 'reservations/complete-payment',
    RESERVATION_TICKETS = 'reservations/{0}/tickets',
    TICKETS = 'tickets',
    TICKETS_MESSAGES = 'tickets/{0}/messages',
    COMMUNICATION_SUBJECTS = 'communication-subjects',

    // Authenticate / UserProfile
    AUTHENTICATE = 'user/authenticate',
    LOGOUT = 'user/logout',
    FORGOT_PASSWORD = 'user/forgot-password',
    CREATE_ACCOUNT = 'user/create-and-authenticate-user',
    SEND_NUMBER_TO_VERIFICATION = 'user/send-number-to-verification',
    TERMINATE_ACCOUNT = 'user/terminate-account',
    CHANGE_PASSWORD = 'change-password',
    REFRESH_TOKEN = 'user/refresh-token',

    // Cars
    CARS_BY_CATEGORY_ID = 'cars',
    VEHICLE_CATEGORIES = 'vehicle-categories',

    QUESTIONS = 'questions',
    QUESTION_CATEGORIES = 'question-categories',

    OPTIONALS = 'billable-items/optionals',

    CARDS = 'cards?clientId=17819&type=1',
    CARD_DELETE = '/cards/{0}',

    ARTICLE = 'articles',
    ARTICLE_TYPES = 'article-types',
}
export default Endpoint;
