import {
	CardMedia, Grid, Stack, styled,
} from '@mui/material';
import { Box } from '@mui/system';
import { Dimens } from 'assets';
import { useSettingsContext } from 'providers';
import { CarFeature } from 'data/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFeatureIcon, setOnErrorDefaultImage } from 'utils/general.utils';
import { Card } from '..';

import {
	FeatureInfo, PriceInfo, Subtitle, Title,
} from '../../atoms';

interface CardCarDetailProps {
   shortTitle?: string;
   title?: string;
   subtitle?: string;
   total?: number;
   pricePerDay?: number;
   image?: string;
   features?: CarFeature[];
}

const CardCarDetail: React.FC<CardCarDetailProps> = ({
	shortTitle, title, subtitle, total, pricePerDay, image = 'noImage', features,
}) => {
	const { t } = useTranslation('translations');
	const { colors } = useSettingsContext();
	const ShortTitle = styled('div')(() => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 400,
		color: colors.textDisabled,
	}));

	const featuresInfo = features
		?.filter((item) => item.type?.id === 1 && item.highlight)
		.slice(0, 4)
		.map((item) => (
			<FeatureInfo
				key={item.id}
				icon={getFeatureIcon(item.id)}
				showLabel
				label={item.name}
				sx={{ width: '100%' }}
				value={item.isBoolean ? true : item.value ?? 0}
			/>
		));

	const content = (
		<CardMedia
			component="img"
			alt="Car image"
			sx={{ borderRadius: '10px', height: '250px' }}
			src={image}
			onError={setOnErrorDefaultImage}
		/>
	);

	const footer = (
		<Stack direction="column" spacing={2}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={12}>
					<Stack direction="row" spacing={2}>
						<Stack direction="column">
							<ShortTitle>{shortTitle}</ShortTitle>
							<Title
								sx={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									lineClamp: 1,
									WebkitLineClamp: 1,
									display: '-webkit-box',
									WebkitBoxOrient: 'vertical',
								}}
							>
								{title?.toUpperCase()}
							</Title>
							<Subtitle fontWeight={400}>{subtitle}</Subtitle>
						</Stack>
						<Box sx={{ flexGrow: 1 }} />
					</Stack>
				</Grid>

				<Grid item xs={12} md={12} sx={{ mt: 2 }}>
					<Stack direction="column" spacing={1}>
						{featuresInfo}
					</Stack>
				</Grid>
				<Grid item xs={12} md={12} sx={{ mt: 2 }}>
					<Stack direction="row" justifyContent="flex-end">
						<Stack direction="column">
							<Subtitle>
								{pricePerDay}
								€
								{' '}
								/
								{' '}
								{t('day')}
							</Subtitle>
							<PriceInfo amount={total} />
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Stack>
	);

	return (
		<Grid item xs={12} sm={12} md={12}>
			<Card padding="0px" contentSx={{ background: 'none' }} content={content} shadows={false} footer={footer} />
		</Grid>
	);
};

export default CardCarDetail;
