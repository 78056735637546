import { Typography, SxProps } from '@mui/material';
import React from 'react';
import { Dimens } from '../../../assets';

interface SubtitleProps{
    sx?: SxProps,
    fontSize?: string,
	fontWeight?: number
}

const Subtitle: React.FC<SubtitleProps> = ({
	children,
	sx,
	fontSize = Dimens.TEXT_SUBTITLE as string,
	fontWeight = 500,
}) => (
	<Typography
		color="title.color"
		component="div"
		sx={sx}
		style={{
			fontSize,
			fontWeight,
		}}
	>
		{children}
	</Typography>
);

export default Subtitle;
