import { Stack, styled, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { Dimens } from 'assets';
import { useSettingsContext } from 'providers';
import { MuiSvgType } from 'utils/mui.utils';
import { Card } from '..';
import { Tag } from '../../atoms';

interface ReservationStatusProps {
   code: string;
   status: string | undefined;
   statusColor: string | undefined;
   statusIcon?: MuiSvgType;
   showBackIcon?: boolean;
   onBackClick?: () => void;
}

const ReservationStatus: React.FC<ReservationStatusProps> = ({
	code, status, statusColor, statusIcon, showBackIcon, onBackClick,
}) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const { colors } = useSettingsContext();
	const ShortTitle = styled('div')(() => ({
		fontSize: '12px',
		fontWeight: 600,
		color: colors.textDisabled,
	}));

	const Code = styled('div')(() => ({
		fontSize: '12px',
		fontWeight: 600,
		color: theme.palette.subtitle?.color,
	}));

	return (
		<Card
			contentSx={{ padding: 1.5 }}
			content={(
				<Stack direction="row" spacing={1} alignItems="center">
					{!!showBackIcon && (
						<Box style={{ cursor: 'pointer', display: 'flex' }} onClick={() => onBackClick?.call(0)}>
							{' '}
							<ArrowBackIosIcon sx={{ color: colors.disabled }} />
							{' '}
						</Box>
					)}
					<Stack direction="column">
						<ShortTitle>
							{t('code')}
							:
						</ShortTitle>
						<Code>{code}</Code>
					</Stack>
					<Box sx={{ flexGrow: 1 }} />
					<Tag
						sx={{ background: statusColor }}
						icon={statusIcon}
						label={status}
						fontSize={Dimens.TEXT_DEFAULT as string}
						iconSize={16}
					/>
				</Stack>
			)}
		/>
	);
};

export default ReservationStatus;
