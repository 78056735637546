/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApiResponse, InitialSettings } from '../../models';
import Endpoint from '../endpoints.api';
import { reqPost } from '../request.api';

export const initialSettings = async (uniqueHash: string | number):
	Promise<ApiResponse<InitialSettings>> => {
	const resp = await reqPost({
		url: Endpoint.INITIAL_SETTINGS,
		data: {
			deviceUniqueId: uniqueHash,
			deviceTypeId: 1,
			platformId: 1,
		},
	});
	const data = resp.data as ApiResponse<InitialSettings>;
	data.statusCode = resp.status;
	return data;
};
