import {
	Grid, Stack, Typography, useTheme,
} from '@mui/material';
import { Dimens } from 'assets';
import { useAuth } from 'providers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDateFormated } from 'utils/general.utils';
import { Card } from '..';
import { DefaultInput } from '../../atoms';

const PersonalDataConfirm = (): JSX.Element => {
	const { t } = useTranslation('translations');

	const theme = useTheme();

	const { user } = useAuth();

	return (
		<Card
			shadows={false}
			content={(
				<>
					{!!user && (
						<Typography sx={{ mb: 2 }} color={theme.disabled} fontSize={Dimens.TEXT_DEFAULT} fontWeight="700">
							{t('yourPersonalData')}
						</Typography>
					)}
					<Grid container sx={{ mt: 0 }} spacing={2}>
						{!!user && (
							<>
								<Grid item xs={12} md={6}>
									<Stack component="form" autoComplete="off">
										<DefaultInput
											readOnly
											label={t('name').toUpperCase()}
											value={user?.name}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12} md={6}>
									<Stack component="form" autoComplete="off">
										<DefaultInput readOnly label={t('birthday').toUpperCase()} value={getDateFormated(user?.birthdate)} />
									</Stack>
								</Grid>

								<Grid item xs={12} md={6}>
									<Stack component="form" autoComplete="off">
										<DefaultInput readOnly label={t('email').toUpperCase()} value={user?.email} />
									</Stack>
								</Grid>

								<Grid item xs={12} md={6}>
									<Stack component="form" autoComplete="off">
										<DefaultInput readOnly label="TEL." value={user?.phone} />
									</Stack>
								</Grid>
							</>
						)}

						{!user && (
							<div style={{ textAlign: 'center' }}>
								{`Para ter acesso a esta funcionalidade você deve realizar o login, 
                        se não possuir uma conta acesse a opção Criar Conta`}
							</div>
						)}
					</Grid>
				</>
			)}
		/>
	);
};

export default PersonalDataConfirm;
