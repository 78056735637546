export enum FeatureTypes {
    VEHICLE = 1,
    CONTRACT = 2
}
export interface CarFeatureType {
    id:number;
    name:string;
}

export default CarFeatureType;
