import {
	Avatar, Box, Divider, Stack, SxProps, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import {
	DefaultButton, DefaultInput, ListItem, Subtitle,
} from 'components/atoms';
import { Card } from 'components/molecules';
import { User } from 'data/models';
import { useAuth } from 'providers';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangePasswordDialog from '../change-password-dialog/ChangePasswordDialog';

interface UserDetailsProps {
	confirmPasswordChange: (currentPassword: string, newPassword: string) => void;
}

// const countries = [
// 	{
// 		id: 1,
// 		isoCode: 'PT',
// 		phoneCode: '351',
// 		name: 'Portugal',
// 		shortName: 'PT',
// 	},
// ];

const UserDetails: FC<UserDetailsProps> = ({ confirmPasswordChange }) => {
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const { user } = useAuth();

	const [isEdit, setEdit] = useState(false);
	const [mUser, setMUser] = useState<User|undefined>();
	const [openChangePassword, setOpenChangePassword] = useState(false);

	const textInputSx = {
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 400,
	} as SxProps;

	useEffect(() => {
		if (user) {
			setMUser(user);
		}
	}, [user, setMUser]);

	const showResetPassword = () => {
		setOpenChangePassword(true);
	};

	const closeResetPassword = () => {
		setOpenChangePassword(false);
	};

	const onEditClick = () => {
		setEdit(true);
	};

	const handleChangePassword = (currentPassword: string, newPassword: string) => {
		confirmPasswordChange(currentPassword, newPassword);
	};

	const readOnlyView = (
		<Box>
			<Box sx={{ pr: '20px' }}>
				<Divider sx={{
					ml: Dimens.LIST_ITEM_INNER_PADDING,
					mb: Dimens.LIST_ITEM_INNER_PADDING,
				}}
				/>
				<Stack direction="row">
					<Subtitle
						fontWeight={700}
						sx={{ color: theme.lighter }}
						fontSize={`${Dimens.TEXT_MEDIUM}`}
					>
						{t('clientDetails')}
					</Subtitle>

				</Stack>
				<Box sx={{ pl: Dimens.LIST_ITEM_INNER_PADDING }}>
					<ListItem
						label={t('name')}
						renderInput={
							<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.name}</Subtitle>
						}
					/>
					<ListItem
						label={t('email')}
						renderInput={(
							<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.email}</Subtitle>
						)}

					/>
					<ListItem label={t('nationality')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.nationality?.name}</Subtitle>} />
					<ListItem
						label={t('phone')}
						renderInput={(
							<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>
								{user?.phoneCountry?.phoneCode}
								{' '}
								{user?.phone}
							</Subtitle>
						)}
					/>
					<ListItem label={t('birthDate')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.birthdate}</Subtitle>} />
				</Box>
			</Box>
			<Box sx={{ pr: '20px', mt: Dimens.LIST_ITEM_INNER_PADDING }}>
				<Stack direction="row" justifyContent="space-between">
					<Subtitle
						fontWeight={700}
						sx={{ color: theme.lighter }}
						fontSize={`${Dimens.TEXT_MEDIUM}`}
					>
						Fiscal Address
					</Subtitle>
				</Stack>
				<Box sx={{ pl: Dimens.LIST_ITEM_INNER_PADDING }}>
					<ListItem label={t('country')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.country?.name}</Subtitle>} />
					<ListItem label={t('state')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.state?.name}</Subtitle>} />
					<ListItem label={t('city')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.city?.name}</Subtitle>} />
					<ListItem label={t('address')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.address}</Subtitle>} />
					<ListItem label={t('taxPayerNumber')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.taxId}</Subtitle>} />
				</Box>
			</Box>
		</Box>
	);

	const editView = (
		<Box>
			<Box sx={{ pr: '20px' }}>
				<Divider sx={{
					ml: Dimens.LIST_ITEM_INNER_PADDING,
					mb: Dimens.LIST_ITEM_INNER_PADDING,
				}}
				/>
				<Stack direction="row">
					<Subtitle
						fontWeight={700}
						sx={{ color: theme.lighter }}
						fontSize={`${Dimens.TEXT_MEDIUM}`}
					>
						{t('clientDetails')}
					</Subtitle>
				</Stack>
				<Box sx={{ pl: Dimens.LIST_ITEM_INNER_PADDING }}>
					<ListItem
						label={t('name')}
						renderInput={
							<DefaultInput value={mUser?.name} sx={textInputSx} />
						}
					/>
					<ListItem
						label={t('email')}
						renderInput={(
							<DefaultInput value={mUser?.email} sx={textInputSx} />
						)}

					/>
					<ListItem label={t('nationality')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.nationality?.name}</Subtitle>} />
					<ListItem
						label={t('phone')}
						renderInput={(
							<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>
								{user?.phoneCountry?.phoneCode}
								{' '}
								{user?.phone}
							</Subtitle>
						)}
					/>
					<ListItem label={t('birthDate')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.birthdate}</Subtitle>} />
				</Box>
			</Box>
			<Box sx={{ pr: '20px', mt: Dimens.LIST_ITEM_INNER_PADDING }}>
				<Stack direction="row" justifyContent="space-between">
					<Subtitle
						fontWeight={700}
						sx={{ color: theme.lighter }}
						fontSize={`${Dimens.TEXT_MEDIUM}`}
					>
						Fiscal Address
					</Subtitle>
				</Stack>
				<Box sx={{ pl: Dimens.LIST_ITEM_INNER_PADDING }}>
					<ListItem label={t('country')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.country?.name}</Subtitle>} />
					<ListItem label={t('state')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.state?.name}</Subtitle>} />
					<ListItem label={t('city')} renderInput={<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={400} sx={{ color: theme.light }}>{user?.city?.name}</Subtitle>} />
					<ListItem label={t('address')} renderInput={<DefaultInput value={mUser?.address} sx={textInputSx} />} />
					<ListItem label={t('taxPayerNumber')} renderInput={<DefaultInput value={mUser?.taxId} sx={textInputSx} />} />
				</Box>
			</Box>
		</Box>
	);

	return (
		<>
			<Card
				contentSx={{ padding: 3 }}
				content={(
					<Box>
						<Stack direction="row" justifyContent="flex-end">
							<DefaultButton text={isEdit ? t('save') : t('edit')} onClick={onEditClick} />
						</Stack>
						<Stack direction="row" style={{ marginTop: '-40', marginBottom: '27px' }}>
							<Avatar alt={user?.name || 'user image'} sx={{ width: 82, height: 82 }} src={user?.profileImage} />
							<Stack sx={{ pl: '35px' }} justifyContent="space-between">
								<Subtitle
									sx={{ color: theme.light }}
									fontWeight={600}
									fontSize={`${Dimens.TEXT_NORMAL}`}
								>
									{user?.name}
								</Subtitle>
								<Box>
									<Subtitle
										sx={{ color: theme.palette.title?.color }}
										fontWeight={400}
										fontSize={`${Dimens.TEXT_DEFAULT}`}
									>
										{user?.email}
									</Subtitle>
									<Subtitle
										sx={{ color: theme.lighter }}
										fontWeight={400}
										fontSize={`${Dimens.TEXT_DEFAULT}`}
									>
										{user?.address}
										,
										{' '}
										{user?.city.name}
										{' '}
										-
										{' '}
										{user?.country?.name}
									</Subtitle>
								</Box>
							</Stack>
						</Stack>
						{!isEdit && readOnlyView}
						{isEdit && editView}
						<Stack direction="row" justifyContent="center">
							<DefaultButton onClick={showResetPassword} variant="text" text={t('changePassword')} sx={{ color: theme.link, textTransform: 'none' }} />
						</Stack>
					</Box>
				)}
			/>
			{openChangePassword && (
				<ChangePasswordDialog
					open={openChangePassword}
					handleClose={closeResetPassword}
					handleChangePassword={handleChangePassword}
				/>
			)}
		</>
	);
};

export default UserDetails;
