import {
	Input,
	InputAdornment,
	ListItemText,
	Stack,
	SvgIcon,
	Theme,
	Typography,
	SxProps,
	useTheme,
} from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Country } from 'data/models';
import { getCountryFlagURL } from 'utils/general.utils';
import Dimens from 'assets/dimens';
import { ListButton } from '../../atoms';

interface CountryInputProps {
	countries: Country[];
	sx?: SxProps<Theme>;
	inputSx?: SxProps<Theme>;
	onChange?: (country: Country | undefined, input: string) => void;
	type?: 'text' | 'number';
	inputValue: string;
	countryValue: Country | undefined
	checked?: boolean | undefined;
}

const CountryInput: React.FC<CountryInputProps> = ({
	countries,
	sx,
	inputSx,
	type,
	onChange,
	inputValue,
	countryValue,
	checked,
}) => {
	const theme = useTheme();
	const handleChange = (inputVal: string, country?: Country) => {
		onChange?.call(0, country, inputVal);
	};

	const checkedIcon = () => {
		if (checked) {
			return {
				endAdornment: (
					<InputAdornment position="end">
						<SvgIcon color="success" component={CheckIcon} sx={{ width: 20 }} />
					</InputAdornment>
				),
			};
		}

		return {};
	};

	return (
		<Stack direction="row" spacing={1} sx={sx}>
			<ListButton
				data={countries}
				sx={{ pl: 0 }}
				variant="text"
				defaultValue={countryValue}
				onChange={(e) => {
					handleChange(inputValue, e);
				}}
				renderValue={(item) => (
					<Stack direction="row" spacing={2} sx={{ pl: 1, pr: 1 }}>
						{!!item?.shortName && <img width="24" alt={item.shortName} src={getCountryFlagURL(item?.shortName.toLowerCase() || '')} />}
						<Typography sx={{ ml: 0.2, fontSize: Dimens.TEXT_DEFAULT }}>
							+
							{item?.phoneCode}
						</Typography>
					</Stack>
				)}
				renderOption={(item) => (
					<Stack direction="row" spacing={2} sx={{ minWidth: '150px' }}>
						<img width="30" alt={item.shortName} src={getCountryFlagURL(item.shortName.toLowerCase())} />
						<ListItemText
							primaryTypographyProps={{
								sx: {
									fontSize: Dimens.TEXT_DEFAULT,
									textAlign: 'start',
								},
							}}
							primary={`${item.name}`}
						/>
					</Stack>
				)}
			/>

			<Input
				fullWidth
				type={type || 'text'}
				defaultValue={inputValue}
				sx={{
					color: theme.palette.title?.color,
					':before': { borderBottomColor: theme.palette.divider },
					':after': { borderBottomColor: theme.palette.divider },
					'&:hover': { borderBottomColor: theme.palette.divider },
					...inputSx,
				}}
				onChange={(e) => {
					handleChange(e.target.value, countryValue);
				}}
				inputProps={{ 'aria-label': 'description', 'aria-valuemax': 9 }}
				{...checkedIcon()}
			/>
		</Stack>
	);
};

export default CountryInput;
