import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { SxProps, Theme } from '@mui/material';

interface DefaultButtonProps {
   loading?: boolean;
   disabled?: boolean;
   text: string;
   color?: string;
   variant?: 'outlined' | 'contained' | 'text' | undefined;
   maxWidth?: string;
   minWidth?: string;
   onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
   type?: 'button' | 'submit' | 'reset';
   sx?: SxProps<Theme>;
}

const DefaultButton: React.FC<DefaultButtonProps> = ({
	loading = false,
	disabled = false,
	text,
	color,
	variant = 'contained',
	maxWidth,
	minWidth,
	onClick,
	type,
	sx = {},
}) => (
	<LoadingButton
		color="primary"
		onClick={(error) => onClick?.call(error)}
		disabled={disabled}
		loading={loading}
		variant={variant}
		type={type}
		sx={{
			fontSize: '12px',
			p: '8px 12px 8px 12px',
			borderRadius: '24px',
			boxShadow: 0,
			color: color ?? '#FFFFFF',
			minWidth,
			maxWidth,
			...sx,
		} as SxProps<Theme>}
	>
		{text}
	</LoadingButton>
);

export default DefaultButton;
