import {
	Grid, GridSize, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Dimens } from 'assets';
import { Title } from 'components/atoms';
import { Card } from '..';

interface CounterCardProps {
   name: string;
   address: string;
   phone: string;
   email: string;
   latitude: number;
   longitude: number;
   postalCode: string;
   city: string;
   xs?: boolean | GridSize | undefined;
   md?: boolean | GridSize | undefined;
   sm?: boolean | GridSize | undefined;
}

const CounterCard: React.FC<CounterCardProps> = ({
	name,
	address,
	phone,
	email,
	latitude,
	longitude,
	postalCode,
	city,
	xs = false,
	md = false,
	sm = false,
}) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const Label = styled('div')(({ fontColor }: {fontColor?: string}) => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 500,
		color: fontColor ?? theme.disabled,
	}));

	const content = (
		<>
			<Stack direction="row" spacing={1} alignItems="center">
				<RoomIcon color="primary" />
				<Label>{t('counter')}</Label>
				<Box sx={{ flexGrow: 1 }} />
				<Label
					onClick={() => {
						window.open(`https://maps.google.com?q=${latitude},${longitude}`);
					}}
					sx={{ color: theme.link, '&:hover': { cursor: 'pointer' } }}
				>
					{t('seeOnMap')}
				</Label>
			</Stack>
			<Box sx={{ mb: 2 }}>
				<Title sx={{ mt: 2 }}>{name}</Title>
				<Label fontColor={theme.light}>{address}</Label>
				<Label fontColor={theme.light}>
					{postalCode}
					{' '}
					{city}
				</Label>
			</Box>
		</>
	);

	const footer = (
		<>
			<Stack direction="row" spacing={1} alignItems="center">
				<LocalPhoneIcon color="primary" />
				<Label fontColor={theme.palette.title?.color}>{phone}</Label>
			</Stack>
			<Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
				<EmailIcon color="primary" />
				<Label style={{ width: 212 }} fontColor={theme.palette.title?.color}>{email}</Label>
			</Stack>
		</>
	);

	return (
		<Grid item xs={xs} md={md} sm={sm}>
			<Card
				content={content}
				footer={footer}
				footerColor="bgSecondary.firstColor"
				shadows={false}
			/>
		</Grid>
	);
};

export default CounterCard;
