import { ExpandLess } from '@mui/icons-material';
import { Fab, useTheme } from '@mui/material';
import React, { useState } from 'react';

const ScrollToTopButton = (): React.ReactElement => {
	const theme = useTheme();
	const [visible, setVisible] = useState(false);

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setVisible(true);
		} else if (scrolled <= 300) {
			setVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	window.addEventListener('scroll', toggleVisible);
	return (
		<Fab
			aria-label="add"
			disableRipple
			sx={{
				height: '41px',
				width: '41px',
				bgcolor: theme.palette.bgSecondary?.firstColor,
				position: 'fixed',
				right: '10%',
				bottom: '50px',
				display: visible ? 'flex' : 'none',
				alignItems: 'center',
				justifyContent: 'center',
				zIndex: 1000,
				':&hover': {
					bgcolor: theme.palette.bgSecondary?.firstColor,
				},
			}}
			onClick={scrollToTop}
		>
			<ExpandLess sx={{ width: 40, color: theme.palette.title?.main }} />
		</Fab>
	);
};

export default ScrollToTopButton;
