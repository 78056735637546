enum AppLinks {
    HOME = '/',
    SPECIAL_OFFER_DETAIL = '/special-offer/:id',
    COMMERCIALS_VEHICLES = '/commercials',

    MY_RESERVATIONS = '/reservation/history',
    MY_RESERVATION_DETAIL = '/reservation/history/:id',
    MY_RESERVATION_DOCUMENTS = '/reservation/history/:id/tickets',
    RESERVATION_SEARCH = '/reservation/',
    RESERVATION_EXTRAS = '/reservation/extras',
    RESERVATION_COMPLETING = '/reservation/complete',

    FAQ = '/faq',

    PROFILE = '/profile'
}

export default AppLinks;
