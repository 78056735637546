import { Typography, Theme, SxProps } from '@mui/material';
import React from 'react';
import { Dimens } from '../../../assets';

interface TitleProps {
	sx?: SxProps<Theme>;
	fontSize?: string,
}

const Title: React.FC<TitleProps> = ({
	children,
	sx = {},
	fontSize = Dimens.TEXT_TITLE as string,
}) => (
	<Typography
		color="title.color"
		component="div"
		sx={{ ...sx }}
		style={{
			fontSize,
			fontWeight: 700,
			fontFamily: 'din_condensed',
		}}
	>
		{children}
	</Typography>
);

export default Title;
