import { CardMedia } from '@mui/material';
import { Dimens } from 'assets';
import { Subtitle, Title } from 'components/atoms';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getImagePlaceHolder, setOnErrorDefaultImage } from 'utils/general.utils';

interface VehicleCardProps {
    title?:string;
    subtitle?:string;
    image?:string;
	showSimilar?: boolean
}

const VehicleCard: React.FC<VehicleCardProps> = ({
	title = '',
	subtitle = '',
	image,
	showSimilar,
}) => {
	const { t } = useTranslation('translations');
	return (
		<>
			<Subtitle
				fontSize={Dimens.TEXT_DEFAULT as string}
				sx={{
					color: 'subtitle2.color',
					fontWeight: 600,
				}}
			>
				{subtitle}
			</Subtitle>
			<Title
				sx={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					lineClamp: 1,
					WebkitLineClamp: 1,
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
				}}
			>
				{title}
			</Title>

			{showSimilar && (
				<Subtitle
					fontSize={Dimens.TEXT_DEFAULT as string}
					sx={{
						color: 'title.color',
						fontWeight: 600,
					}}
				>
					{t('orSimilar')}
				</Subtitle>
			)}

			<CardMedia
				component="img"
				alt="Car image"
				sx={{ borderRadius: '10px', mt: 1, height: { md: '120px', xs: '200px' } }}
				onError={setOnErrorDefaultImage}
				src={image || getImagePlaceHolder()}
			/>
		</>
	);
};

export default VehicleCard;
