import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	// Skeleton,
	Typography,
	SxProps,
	useTheme,
	useMediaQuery,
	TextField,
	InputAdornment,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ExpandMore, Person, Search } from '@mui/icons-material';
import { Dimens } from 'assets';
import { questionService } from 'data/api';
import { QuestionCategory } from 'data/models';
import { useTranslation } from 'react-i18next';
import useEffectCustom from 'hooks/useEffectCustom';
import { TopicButton } from '../../atoms';

interface FaqGroupProps {
   categories: QuestionCategory[];
   sx?: SxProps
}

const FaqGroup: React.FC<FaqGroupProps> = ({ categories, sx }) => {
	const [selected, setSeleted] = useState<QuestionCategory | undefined>();
	const [search, setSearch] = useState('');

	const useFetchQuestions = questionService.useFetchQuestions();

	const theme = useTheme();
	const isXS = useMediaQuery(theme.breakpoints.up('xs'));
	const isSM = useMediaQuery(theme.breakpoints.up('sm'));
	const isMD = useMediaQuery(theme.breakpoints.up('md'));
	const { t } = useTranslation('translations');

	const handleClick = async (item: QuestionCategory) => {
		if (selected === item) {
			setSeleted(undefined);
		} else {
			await useFetchQuestions.execute(item.id);
			setSearch('');
			setSeleted(item);
		}
		return null;
	};

	useEffect(() => {
		setSeleted(undefined);
	}, [isXS, isSM, isMD]);

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	const searchQuestions = () => {
		useFetchQuestions.execute(selected?.id, search);
	};

	useEffectCustom(() => {
		const delayDebounceFn = setTimeout(() => { // TODO Limit the api call to only one
			searchQuestions();
		}, 600);
		return () => clearTimeout(delayDebounceFn);
	}, [search]);

	const questionsTitle = categories.map((item) => (
		<Grid key={item.id} item xs={12} sm={6} md={4}>
			<TopicButton
				icon={Person}
				label={item.value}
				selected={selected === item}
				onClick={() => handleClick(item)}
			/>
		</Grid>
	));

	const questionsArr = useFetchQuestions.data?.map((item, index) => (
		<Grid item xs={12} md={12} key={`${item.question}-${index.toString()}`}>
			<Accordion sx={{ boxShadow: 'none' }}>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography
						fontSize={Dimens.TEXT_DEFAULT as string}
						color={theme.light}
						fontWeight={600}
					>
						{item.question}

					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography fontSize={Dimens.TEXT_DEFAULT as string} color={theme.light}>
						{item.answer}
					</Typography>
				</AccordionDetails>
			</Accordion>
		</Grid>
	));

	return (
		<>
			<TextField
				size="small"
				variant="standard"
				onChange={handleChange}
				value={search}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<Search sx={{ color: 'primary.main' }} />
						</InputAdornment>
					),
					disableUnderline: true,
				}}
				placeholder={t('tryRefund')}
				fullWidth
				sx={{
					mt: 4,
					bgcolor: 'white',
					border: '0px solid white',
					borderRadius: '5px',
					pl: 1,
					pt: '8px',
					pb: '3px',
				}}
			/>
			<Typography sx={{
				mt: 6, mb: 4, fontSize: Dimens.TEXT_SUBTITLE, color: theme.lighter, textAlign: 'center',
			}}
			>
				{t('helpAndSupportInfo')}
			</Typography>
			<Grid container spacing={2} sx={sx}>
				{questionsTitle}
				<Grid container item md={12} spacing={1}>
					{/* {!!useFetchQuestions.loading &&
					<Skeleton variant="rectangular" height={200} width="100%"
					sx={{ borderRadius: '10px' }} />} */}
					{(!useFetchQuestions.loading && !!selected) && questionsArr}
					{(useFetchQuestions.data && useFetchQuestions.data?.length > 0 && search) && questionsArr}
				</Grid>
			</Grid>
		</>
	);
};

export default FaqGroup;
