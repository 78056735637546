/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import useRequest from '../useRequest.api';
import {
	ApiResponse, CardPaymentIntent, Reservation, ServicesData,
} from '../../models';
import { ReservationCreateData, ReservationFile } from '../../models/reservation.model';
import Toll from '../../models/toll.model';

const fetchReservations = async (): Promise<ApiResponse<Reservation[]>> => {
	const resp = await reqGet({
		url: Endpoint.RESERVATIONS,
	});
	const data = resp.data as ApiResponse<Reservation[]>;
	data.statusCode = resp?.status;
	return data;
};

export const useFetchReservations = () => useRequest(() => fetchReservations());

const fetchReservationById = async (id: number): Promise<ApiResponse<Reservation>> => {
	const resp = await reqGet({
		url: Endpoint.RESERVATIONS_BY_ID.format(id),
	});
	const data = resp.data as ApiResponse<Reservation>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchReservationById = () => useRequest(([id]) => fetchReservationById(id));

const cancelReservation = async (id: number, cancelReasonId: number)
	: Promise<ApiResponse<void>> => {
	const resp = await reqGet({
		url: Endpoint.CANCEL_RESERVATION.format(id),
		data: {
			cancelReasonId,
		},
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp?.status;
	return data;
};
export const useCancelReservation = () => {
	return useRequest(([id, cancelReasonId]) => cancelReservation(id, cancelReasonId));
};

const fetchServices = async (reservationID: number): Promise<ApiResponse<ServicesData>> => {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_SERVICES.format(reservationID),
	});
	const data = resp.data as ApiResponse<ServicesData>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchService = () => useRequest(([id]) => fetchServices(id));

const fetchInvoice = async (invoiceID: number): Promise<ApiResponse<ReservationFile>> => {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_INVOICE.format(invoiceID),
	});
	const data = resp.data as ApiResponse<ReservationFile>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchInvoice = () => useRequest(([invoiceID]) => fetchInvoice(invoiceID));

const fetchReservationContract = async (reservationID: number)
	: Promise<ApiResponse<ReservationFile>> => {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_CONTRACT.format(reservationID),
	});
	const data = resp.data as ApiResponse<ReservationFile>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchReservationContract = () => useRequest(([id]) => fetchReservationContract(id));

const fetchReservationProof = async (reservationID: number)
	: Promise<ApiResponse<string>> => {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_PROOF.format(reservationID),
	});

	const data = resp.data as ApiResponse<string>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchReservationProof = () => useRequest(([id]) => fetchReservationProof(id));

const fetchTolls = async (reservationID: number): Promise<ApiResponse<Toll[]>> => {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_TOLLS.format(reservationID),
	});
	const data = resp.data as ApiResponse<Toll[]>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchTolls = () => useRequest(([reservationID]) => fetchTolls(reservationID));

const newReservation = async (book: ReservationCreateData): Promise<ApiResponse<Reservation>> => {
	const startDate = new Date(book.startDate).toISOString();
	const endDate = new Date(book.endDate).toISOString();
	const resp = await reqPost({
		url: Endpoint.RESERVATIONS,
		data: { ...book, startDate, endDate },
	});
	const data = resp.data as ApiResponse<Reservation>;
	data.statusCode = resp?.status;
	return data;
};
export const useNewReservation = () => useRequest(([book]) => newReservation(book));

export const createPaymentSalt = async (book: ReservationCreateData)
	: Promise<ApiResponse<CardPaymentIntent>> => {
	const startDate = new Date(book.startDate).toISOString();
	const endDate = new Date(book.endDate).toISOString();
	const resp = await reqPost({
		url: Endpoint.RESERVATION_PAYMENT_SALT,
		data: { ...book, startDate, endDate },
	});
	const data = resp.data as ApiResponse<CardPaymentIntent>;
	data.statusCode = resp.status;
	return data;
};

const completePayment = async (clientSecret: string): Promise<ApiResponse<Reservation>> => {
	const resp = await reqPost({
		url: Endpoint.RESERVATION_COMPLETE_PAYMENT,
		data: {
			clientSecret,
		},
	});
	const data = resp.data as ApiResponse<Reservation>;
	data.statusCode = resp.status;
	return data;
};

export const useCompletePayment = () => {
	return useRequest(([clientSecret]) => completePayment(clientSecret));
};
