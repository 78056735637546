/* eslint-disable func-names */
/* eslint-disable no-extend-native */

import moment from 'moment';

declare global {
    interface Date {
        isAfter(date:Date | null):boolean;
		nextDay(): Date;
        isSameOrAfter(date:Date | null): boolean;
        /**
         * This function will considere just the hour
         * @param date string HH:mm
         */
        isHourAfter(date: Date | null) : boolean;
    }
}

Date.prototype.isAfter = function (date: Date | null) {
	if (!date) return false;
	return moment(this).isAfter(date, 'days');
};

Date.prototype.nextDay = function (): Date {
	return moment(this).add(1, 'days').toDate();
};

Date.prototype.isSameOrAfter = function (date: Date | null) {
	if (!date) return false;
	return moment(this).isSameOrAfter(date);
};

Date.prototype.isHourAfter = function (date: Date | null) {
	if (!date) return false;
	const thisMoment = moment(this);
	const dateMoment = moment(date);

	const tHour = thisMoment.hour();
	const tMinute = thisMoment.minute();

	const dHour = dateMoment.hour();
	const dMinute = dateMoment.minute();

	return tHour > dHour || (tHour >= dHour && tMinute > dMinute);
};

export {};
