import React, { useEffect } from 'react';

import {
	ListItemText, Stack, Typography, Box,
} from '@mui/material';
import { Dimens } from 'assets';
import useLanguage from 'providers/language.provider';
import { Country } from 'data/models';
import { getCountryFlagURL } from 'utils/general.utils';
import { ListButton } from '../../atoms';
import mockCountries from '../../../assets/localization/supported-languages.json';

const LanguageSelect = (): JSX.Element => {
	const { changeLocale } = useLanguage();

	const [selectedValue, setSelectedValue] = React.useState<Country>();
	const [data, setData] = React.useState<Country[]>([]);

	useEffect(() => {
		const values = mockCountries.map((item) => item as Country);
		setData(values);
		setSelectedValue(values[0]);
	}, []);

	useEffect(() => {
		if (selectedValue) {
			changeLocale(selectedValue);
		}
	}, [selectedValue, changeLocale]);

	return (
		<ListButton
			data={data}
			defaultValue={selectedValue}
			renderValue={(item) => (
				<Stack direction="row" spacing={2}>
					<img width="24" alt={item?.id.toString()} src={getCountryFlagURL(item?.shortName.toLowerCase() || '')} />
					<Typography
						sx={{ color: (theme) => theme.disabled, fontSize: Dimens.TEXT_DEFAULT }}
					>
						{item?.isoCode.toUpperCase()}
					</Typography>
				</Stack>
			)}
			sx={{ minWidth: '80px' }}
			renderOption={(item) => (
				<Box onClick={() => setSelectedValue(item)}>
					<Stack direction="row" spacing={2} sx={{ minWidth: '150px' }}>
						<img width="30" alt={item.id.toString()} src={getCountryFlagURL(item.shortName.toLowerCase())} />
						<ListItemText
							primaryTypographyProps={{
								sx: {
									fontSize: Dimens.TEXT_DEFAULT,
									textAlign: 'start',
								},
							}}
							primary={`${item.name}`}
						/>
					</Stack>
				</Box>
			)}
		/>
	);
};

export default LanguageSelect;
