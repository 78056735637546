import React from 'react';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const TransitionSlideUp = React.forwardRef((
	props: TransitionProps & {
        children: React.ReactElement;
    },
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

export default TransitionSlideUp;
