import { LoadingButton } from '@mui/lab';
import {
	SvgIcon, Typography, SxProps, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { MuiSvgType } from 'utils/mui.utils';
import { useSettingsContext } from '../../../providers';
import { convertAndFormatToCurrency } from '../../../utils/general.utils';

interface OptionalItemButtonProps {
    loading?: boolean;
    text: string;
    selected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    sx?: SxProps;
    price: number;
    priceWithDiscount: number;
    label?: string;
    icon?: MuiSvgType
}

const OptionalItemButton: React.FC<OptionalItemButtonProps> = ({
	loading = false,
	text,
	selected = false,
	disabled,
	onClick,
	sx,
	price,
	priceWithDiscount,
	label,
	icon,
}) => {
	const { currency } = useSettingsContext();
	const theme = useTheme();
	const sxButton = {
		fontSize: '11px',
		padding: '8px 12px 8px 12px',
		fontWeight: 700,
		borderRadius: '3px',
		boxShadow: 'none',
		color: selected ? '#FFFFFF' : 'title.color',
		bgcolor: selected ? 'primary.main' : '#FFFFFF00',
		'&:hover': {
			color: selected ? '#FFFFFF' : 'title.color',
			bgcolor: selected ? 'primary.main' : '#FFFFFF00',
		},
		...sx,
	} as SxProps;

	const iconStyle = {
		color: selected ? '#FFFFFF' : 'title.color',
		width: 16,
	} as SxProps;

	const mPrice = convertAndFormatToCurrency(price, currency);
	const mPriceWithDiscount = convertAndFormatToCurrency(priceWithDiscount, currency);
	return (
		<LoadingButton
			variant="outlined"
			onClick={() => { onClick?.call(0); }}
			disabled={disabled}
			loading={loading}
			sx={{ ...sxButton }}
		>
			<Box
				id="content"
				sx={{
					display: 'flex',
					flexWrap: 'nowrap',
					alignItems: 'center',
					gap: 1,
					color: selected ? '#FFFFFF' : theme.disabled,
				}}
			>
				{icon && <SvgIcon className="icon-bt" component={icon} sx={iconStyle} />}
				{text}
				<Box sx={{ flexGrow: 1 }} />
				<Typography
					component="div"
					sx={{
						ml: 0.5,
						fontSize: '12px',
						color: selected ? '#FFFFFF' : theme.disabled,
						fontWeight: 600,
						textTransform: 'lowercase',
					}}
				>
					<span style={
						mPrice !== mPriceWithDiscount ? {
							fontSize: '10px',
							fontStyle: 'oblique',
							textDecoration: 'line-through',
							color: theme.palette.subtitle2?.color,
						} : {}
					}
					>
						{mPrice}
					</span>
					{mPrice !== mPriceWithDiscount && (
						<span style={{ marginLeft: '8px' }}>
							{mPriceWithDiscount}
						</span>
					)}
					{label && `/ ${label}`}
				</Typography>
			</Box>
		</LoadingButton>
	);
};

export default OptionalItemButton;
