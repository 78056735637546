import {
	Autocomplete,
	Dialog,
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
} from '@mui/material';
import { ticketService } from 'data/api';
import { useFeedbackContext } from 'providers';
import { TicketSubject } from 'data/models';
import React, { useEffect, useState } from 'react';
import useEffectCustom from 'hooks/useEffectCustom';
import {
	DefaultButton, DefaultInput,
} from '../../atoms';

interface TicketCreateDialogProps {
   open: boolean;
   reservationID: number;
   onClose: () => void;
   onSaved: () => void;
}

const TicketCreateDialog: React.FC<TicketCreateDialogProps> = ({
	open, reservationID, onClose, onSaved,
}) => {
	const [selected, setSelected] = useState<TicketSubject>();
	const [message, setMessage] = useState<string>();
	const { setAlert } = useFeedbackContext();
	const useAddMessage = ticketService.useAddMessage();

	const useFetchSubjects = ticketService.useFetchSubjects();
	const useSaveTicket = ticketService.useSaveTicket();

	useEffectCustom(() => {
		useFetchSubjects.execute();
	});

	const handleSaveClick = () => {
		useSaveTicket.execute(selected?.id, reservationID, message).then((hasError) => {
			if (!hasError) {
				setSelected(undefined);
				setMessage('');
				onClose();
				onSaved();
			}
		});
	};

	useEffect(() => {
		const { info } = useSaveTicket;
		if (info) {
			setAlert({
				message: info.message,
				type: info.statusCode !== 200 ? 'error' : 'success',
			});
		}
	}, [setAlert, useSaveTicket]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle id="alert-dialog-title">Novo ticket</DialogTitle>
			<DialogContent>
				<Stack direction="column" spacing={2} component="form">
					<Autocomplete
						id="select-on-focus"
						options={useFetchSubjects.data || []}
						filterSelectedOptions
						getOptionLabel={(option) => option.name}
						onChange={(event, item) => setSelected(item)}
						disableClearable
						sx={{ flexGrow: 1 }}
						renderInput={(params) => (
							<TextField {...params} InputProps={{ ...params.InputProps, disableUnderline: true }} label="Assunto" variant="standard" />
						)}
					/>

					<DefaultInput label="Mensagem" value={message} fullWidth onChange={(value) => setMessage(value)} />

					<DefaultButton
						disabled={!message || !selected}
						loading={useAddMessage.loading}
						onClick={handleSaveClick}
						sx={{ alignSelf: 'end' }}
						text="Enviar"
						minWidth="100px"
					/>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

export default TicketCreateDialog;
