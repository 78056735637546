/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
/* eslint-disable no-extend-native */

declare global {
    interface String{
        format(...args:any):string
        formatMap(args:any):string
    }
}

String.prototype.format = function (...args: any) {
	// check if the argument is there
	return this.replace(/{([0-9]+)}/g, (match, index) => (typeof args[index] === 'undefined' ? match : args[index]));
};

String.prototype.formatMap = function (args:any) {
	return this.replace(/:([a-zA-Z]+)(\/|)/g, (match) => {
		const key = match.replace(/[:\/]/g, '');
		// check if the argument is there
		return args[key] ? `${args[key]}/` : match;
	});
};

export {};
