import {
	Card, SvgIcon, Typography, Box, SxProps, useTheme,
} from '@mui/material';
import React from 'react';
import { MuiSvgType } from 'utils/mui.utils';
import { Dimens } from '../../../assets';

interface TopicButtonProps {
    label: string;
    icon?: MuiSvgType;
    sx?: SxProps;
    selected?: boolean;
    onClick?: () => void
}

const TopicButton: React.FC<TopicButtonProps> = ({
	label,
	sx,
	selected,
	onClick,
	icon,
}) => {
	const container = {
		borderRadius: Dimens.DEFAULT_RADIUS,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: 'none',
		cursor: 'pointer',
		gap: 2,
		p: 4,
		...(selected ? { bgcolor: 'selected.bgColor' } : {}),
		...sx,
	} as SxProps;
	const theme = useTheme();

	const handleClick = () => {
		onClick?.call(0);
	};

	return (
		<Box onClick={handleClick}>
			<Card sx={container}>
				{icon && <SvgIcon component={icon} sx={{ color: (selected ? 'selected.color' : 'primary.main') }} />}
				<Typography sx={{
					fontSize: Dimens.TEXT_SUBTITLE,
					color: selected ? 'selected.color' : theme.lighter,
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					lineClamp: 1,
					WebkitLineClamp: 1,
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
				}}
				>
					{' '}
					{label}
					{' '}

				</Typography>
			</Card>
		</Box>
	);
};

export default TopicButton;
