/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import { FilterData, ApiResponse } from '../../models';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

const fetchFilters = async (): Promise<ApiResponse<FilterData>> => {
	const resp = await reqGet({
		url: Endpoint.FILTERS,
	});
	const data = resp.data as ApiResponse<FilterData>;
	data.statusCode = resp.status;
	return data;
};

export const useFetchFilter = () => useRequest(() => fetchFilters(), {
	cache: true,
	cacheKey: 'filters',
});
