import React from 'react';

const CarTraceIcon:React.FC = () => (
	<svg viewBox="0 0 1192 523" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="22" cy="473.5" r="4.5" stroke="#CDCDD7" />
		<path fillRule="evenodd" clipRule="evenodd" d="M9.375 520.625C9.375 521.662 8.53688 522.5 7.5 522.5C6.465 522.5 5.625 521.662 5.625 520.625C5.625 519.588 6.465 518.75 7.5 518.75C8.53688 518.75 9.375 519.588 9.375 520.625Z" fill="#CDCDD7" />
		<path fillRule="evenodd" clipRule="evenodd" d="M24.375 520.625C24.375 521.662 23.5369 522.5 22.5 522.5C21.465 522.5 20.625 521.662 20.625 520.625C20.625 519.588 21.465 518.75 22.5 518.75C23.5369 518.75 24.375 519.588 24.375 520.625Z" fill="#CDCDD7" />
		<path fillRule="evenodd" clipRule="evenodd" d="M19.6875 499.062C19.6875 498.537 19.275 498.125 18.75 498.125C18.2419 498.125 17.8125 498.537 17.8125 499.062C17.8125 499.588 18.2419 500 18.75 500C19.275 500 19.6875 499.588 19.6875 499.062ZM18.75 502.812C17.4 502.812 16.2 503.544 15.5437 504.631C15.2062 505.175 15.0187 505.812 15 506.488C16.1062 507.125 17.3812 507.5 18.75 507.5C20.1169 507.5 21.3938 507.125 22.5 506.488C22.4812 505.812 22.2937 505.175 21.9563 504.631C21.3 503.544 20.1 502.812 18.75 502.812ZM18.75 492.5C14.625 492.5 11.25 495.856 11.25 500C11.25 501.988 12.0375 503.787 13.3125 505.137C13.4794 504.462 13.7812 503.844 14.175 503.281C14.8687 502.325 15.8438 501.594 16.9875 501.237C16.35 500.731 15.9375 499.944 15.9375 499.062C15.9375 497.506 17.1937 496.25 18.75 496.25C20.3062 496.25 21.5625 497.506 21.5625 499.062C21.5625 499.944 21.15 500.731 20.5106 501.237C21.6562 501.594 22.6313 502.325 23.3231 503.281C23.7188 503.844 24.0188 504.481 24.1875 505.156C25.4625 503.806 26.25 501.988 26.25 500C26.25 495.856 22.8938 492.5 18.75 492.5Z" fill="#CDCDD7" />
		<path fillRule="evenodd" clipRule="evenodd" d="M29.9194 516.594L28.9294 514.349C28.8075 514.072 28.5581 513.871 28.2619 513.811L20.4133 512.188L16.1215 509.934C15.4165 509.478 14.1715 509.375 13.7909 509.375H8.36849C7.45726 509.375 6.16916 510.05 5.68916 510.376C5.1323 510.753 3.7917 511.82 3.32108 512.199L1.17987 512.714C0.756122 512.816 0.459877 513.196 0.461752 513.629L0.474877 515H0.937994C1.45549 515 1.87548 515.42 1.87548 515.938C1.87548 516.457 1.45549 516.875 0.937994 516.875H0.375504L0.013635 518.977C-0.0726136 519.479 0.259256 519.959 0.756122 520.057L3.74482 520.625H3.75045C3.75045 518.555 5.42854 516.875 7.50038 516.875C9.57035 516.875 11.2503 518.555 11.2503 520.625H18.7502C18.7502 518.555 20.4283 516.875 22.5001 516.875C24.5701 516.875 26.2501 518.555 26.2501 520.625H29.0625C29.58 520.625 30 520.207 30 519.688V516.972C30 516.843 29.9719 516.712 29.9194 516.594Z" fill="#CDCDD7" />
		<path fillRule="evenodd" clipRule="evenodd" d="M1184.35 5.6625L1181.07 10.8187C1181.01 10.9125 1180.92 11.0062 1180.81 11.0812C1180.81 11.1 1180.79 11.1 1180.77 11.1C1180.62 11.1937 1180.47 11.25 1180.3 11.25H1180.28C1180.11 11.25 1179.96 11.1937 1179.81 11.1187C1179.81 11.1 1179.79 11.1187 1179.78 11.1C1179.66 11.025 1179.59 10.95 1179.51 10.8375L1177.24 7.55625C1176.96 7.14375 1177.08 6.5625 1177.49 6.2625C1177.92 5.9625 1178.5 6.075 1178.8 6.50625L1180.24 8.60625L1182.78 4.65C1183.06 4.21875 1183.64 4.0875 1184.07 4.36875C1184.5 4.65 1184.63 5.23125 1184.35 5.6625ZM1180.75 0C1176.61 0 1173.25 3.35625 1173.25 7.5C1173.25 11.6438 1176.61 15 1180.75 15C1184.89 15 1188.25 11.6438 1188.25 7.5C1188.25 3.35625 1184.89 0 1180.75 0Z" fill="#CDCDD7" />
		<path fillRule="evenodd" clipRule="evenodd" d="M1171.38 28.125C1171.38 29.1619 1170.54 30 1169.5 30C1168.47 30 1167.62 29.1619 1167.62 28.125C1167.62 27.0881 1168.47 26.25 1169.5 26.25C1170.54 26.25 1171.38 27.0881 1171.38 28.125Z" fill="#CDCDD7" />
		<path fillRule="evenodd" clipRule="evenodd" d="M1186.38 28.125C1186.38 29.1619 1185.54 30 1184.5 30C1183.47 30 1182.62 29.1619 1182.62 28.125C1182.62 27.0881 1183.47 26.25 1184.5 26.25C1185.54 26.25 1186.38 27.0881 1186.38 28.125Z" fill="#CDCDD7" />
		<path fillRule="evenodd" clipRule="evenodd" d="M1191.92 24.0938L1190.93 21.8494C1190.81 21.5719 1190.56 21.3712 1190.26 21.3112L1182.41 19.6875L1178.12 17.4338C1177.42 16.9781 1176.17 16.875 1175.79 16.875H1170.37C1169.46 16.875 1168.17 17.55 1167.69 17.8762C1167.13 18.2531 1165.79 19.32 1165.32 19.6988L1163.18 20.2144C1162.76 20.3156 1162.46 20.6963 1162.46 21.1294L1162.47 22.5H1162.94C1163.46 22.5 1163.88 22.92 1163.88 23.4375C1163.88 23.9569 1163.46 24.375 1162.94 24.375H1162.38L1162.01 26.4769C1161.93 26.9794 1162.26 27.4594 1162.76 27.5569L1165.74 28.125H1165.75C1165.75 26.055 1167.43 24.375 1169.5 24.375C1171.57 24.375 1173.25 26.055 1173.25 28.125H1180.75C1180.75 26.055 1182.43 24.375 1184.5 24.375C1186.57 24.375 1188.25 26.055 1188.25 28.125H1191.06C1191.58 28.125 1192 27.7069 1192 27.1875V24.4725C1192 24.3431 1191.97 24.2119 1191.92 24.0938Z" fill="#CDCDD7" />
		<path d="M26 464C26 464 115 334.174 329.5 329.651C544 325.127 598 271.297 658 231.942C718 192.587 771 102.116 924.5 152.327C1078 202.539 1177.5 51 1177.5 51" stroke="#CDCDD7" strokeLinecap="round" strokeLinejoin="bevel" strokeDasharray="9" />
		<circle cx="1177" cy="46" r="4.5" stroke="#CDCDD7" />
	</svg>

);

export default CarTraceIcon;
