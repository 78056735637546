import React from 'react';
import {
	Stack, styled, SvgIcon, SvgIconTypeMap, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useSettingsContext } from '../../../providers';

interface LabelIconBoxProps {
    label: string;
	// eslint-disable-next-line @typescript-eslint/ban-types
	icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
	iconColor?: string
}

const LabelIconBox: React.FC<LabelIconBoxProps> = ({
	label, icon = AccessAlarmsIcon, iconColor,
}) => {
	const { colors } = useSettingsContext();
	const theme = useTheme();
	const ShortTitle = styled('div')(() => ({
		fontSize: '14px',
		fontWeight: 600,
		color: iconColor ?? colors.textDisabled,
	}));

	return (
		<Box
			sx={{
				background: theme.palette.bgSecondary?.contrast,
				borderRadius: '8px',
				width: 'fit-content',
				mr: 1,
			}}
		>
			<Stack
				direction="row"
				spacing={1}
				sx={{
					pl: 1, pr: 2, pt: 0.4, pb: 0.4,
				}}
				alignItems="center"
			>
				<SvgIcon component={icon} sx={{ width: 20, color: iconColor ?? colors.highlight }} />
				<ShortTitle>{label}</ShortTitle>
			</Stack>
		</Box>
	);
};

export default LabelIconBox;
