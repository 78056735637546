import {
	Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import React from 'react';

interface ConfirmDialogProps {
    title?: string;
    message: string;
    open: boolean;
    onConfirm: () => void;
    onClose: () => void,
    confirmBtnText:string;
    cancelBtnText?: string;

}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
	title,
	message,
	open,
	confirmBtnText,
	cancelBtnText,
	onConfirm,
	onClose,
}) => (
	<Dialog
		open={open}
		onClose={onClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
	>
		{!!title && (
			<DialogTitle id="alert-dialog-title">
				{title}
			</DialogTitle>
		)}
		<DialogContent>
			<DialogContentText id="alert-dialog-description">
				{message}
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose}>{cancelBtnText || 'Cancelar'}</Button>
			<Button onClick={onConfirm} autoFocus>
				{confirmBtnText}
			</Button>
		</DialogActions>
	</Dialog>
);

export default ConfirmDialog;
