import {
	Container, Grid, Stack, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { buildsBackgroundSvg, YellowCar } from 'assets/images';
import { MainPanel } from 'components/organisms';
import React from 'react';

const FirstSection: React.FC = () => {
	const theme = useTheme();
	return (
		<section>
			<Box
				sx={{
					background: {
						md: `url(${buildsBackgroundSvg}) repeat-x`,
						xs: 'none',
					},
					position: 'relative',
					minHeight: '80vh',
					bgcolor: theme.palette.bgSecondary?.secondColor,
				}}
			>
				<Container maxWidth="lg">
					<Stack direction={{ xs: 'column-reverse', md: 'row' }}>
						<Stack direction="column" alignItems="center">
							<MainPanel />
						</Stack>
						<Grid item md={12} xs={8} sx={{ ml: { md: 4, xs: 8 } }}>
							<YellowCar />
						</Grid>
					</Stack>
				</Container>
			</Box>
		</section>
	);
};

export default FirstSection;
