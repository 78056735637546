/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';
import { ApiResponse, BillableItem } from '../../models';

const fetchOptionals = async (): Promise<ApiResponse<BillableItem[]>> => {
	const resp = await reqGet({
		url: Endpoint.OPTIONALS,
	});
	const data = resp.data as ApiResponse<BillableItem[]>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchOptionals = () => useRequest(() => fetchOptionals(), {
	cache: true,
	cacheKey: 'billable-items',
});
