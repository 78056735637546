import {
	Grid, GridSize, Skeleton, Stack,
} from '@mui/material';
import React from 'react';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Box } from '@mui/system';
import { useSettingsContext } from 'providers';
import { Card } from '..';

interface CounterSkeletonCardProps {
    xs?: boolean | GridSize | undefined
    md?: boolean | GridSize | undefined
    sm?: boolean | GridSize | undefined
}

const CounterSkeletonCard: React.FC<CounterSkeletonCardProps> = ({
	xs = false, md = false, sm = false,
}) => {
	const { colors } = useSettingsContext();

	const content = (
		<>
			<Stack direction="row" spacing={1} alignItems="center">
				<RoomIcon sx={{ color: colors.highlight }} />
				<Skeleton variant="rectangular" height={25} width="40%" sx={{ borderRadius: '10px' }} />
				<Box sx={{ flexGrow: 1 }} />
				<Skeleton variant="rectangular" height={25} width="40%" sx={{ borderRadius: '10px' }} />
			</Stack>
			<Box sx={{ mb: 2 }}>
				<Skeleton variant="rectangular" height={25} width="100%" sx={{ borderRadius: '10px', mt: 2 }} />
				<Skeleton variant="rectangular" height={25} width="60%" sx={{ borderRadius: '10px', mt: 2 }} />
			</Box>
		</>
	);

	const footer = (
		<>
			<Stack direction="row" spacing={1} alignItems="center">
				<LocalPhoneIcon sx={{ color: colors.highlight }} />
				<Skeleton variant="rectangular" height={25} width="50%" sx={{ borderRadius: '10px' }} />
			</Stack>
			<Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
				<EmailIcon sx={{ color: colors.highlight }} />
				<Skeleton variant="rectangular" height={25} width="50%" sx={{ borderRadius: '10px' }} />
			</Stack>
		</>
	);

	return (
		<Grid item xs={xs} md={md} sm={sm}>
			<Card content={content} footer={footer} />
		</Grid>
	);
};

export default CounterSkeletonCard;
