import React from 'react';
import {
	Container, Grid, Stack, Box, SxProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CommentCard, Subtitle, Title } from '../../atoms';
import mockComments from '../../../data/mock/comments.json';

const FourthSection = (): JSX.Element => {
	const { t } = useTranslation('translations');
	const boxStyle = {
		bgcolor: 'bgSecondary.firstColor',
		position: 'relative',
	} as SxProps;

	const comments = mockComments.map((item) => (
		<CommentCard
			key={item.comment}
			comment={item.comment}
			md={6}
			image={item.profileImage}
			sxBox={{ minHeight: '200px' }}
			identification={item.identification}
			city={item.city}
		/>
	));

	return (
		<section>
			<Box sx={boxStyle}>
				<Container maxWidth="lg" sx={{ pt: 5, pb: 5, position: 'relative' }}>
					<Title sx={{ textAlign: 'center' }}>{t('whatOrCustomersSay').toUpperCase()}</Title>
					<Subtitle sx={{ textAlign: 'center' }}>{t('weHaveTheBestServiceInTheRegion')}</Subtitle>

					<Grid item sx={{ mt: 4 }}>
						<Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
							{comments}
						</Stack>
					</Grid>
				</Container>
			</Box>
		</section>
	);
};

export default FourthSection;
