import {
	Box,
	Stack,
	Typography,
	// Typography
} from '@mui/material';
import { Dimens } from 'assets';
import { Ticket } from 'data/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFullDateFormated } from 'utils/general.utils';
import {
	DefaultButton,
	Subtitle,
	Tag,
	// Tag
} from '../../atoms';
import Card from '../card/Card';

interface TicketItemProps {
    ticket: Ticket;
    onClick?: ()=>void
}

const TicketItem: React.FC<TicketItemProps> = ({
	ticket,
	onClick,
}) => {
	const { t } = useTranslation('translations');
	return (
		<Card
			content={(
				<Stack direction="row" justifyContent="space-between">
					<Stack direction="row" sx={{ mb: 3 }}>
						<Subtitle sx={{ color: 'primary.main' }}>
							#
							{ticket.id}
						</Subtitle>
						<Box sx={{ ml: 1 }}>
							<Subtitle>
								{t('ticket')}
								{' '}
								-
								{' '}
								{ticket.subject.name}
							</Subtitle>
							<Typography sx={{ fontSize: Dimens.TEXT_DEFAULT, color: (theme) => theme.disabled }}>
								{getFullDateFormated(ticket.registration)}
							</Typography>
							<Typography sx={{
								fontSize: Dimens.TEXT_DEFAULT,
								color: (theme) => theme.disabled,
								mt: 1.5,
							}}
							>
								{t('message')}
							</Typography>
							<Typography sx={{
								fontSize:
								Dimens.TEXT_DEFAULT,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								lineClamp: 2,
								WebkitLineClamp: 2,
								display: '-webkit-box',
								WebkitBoxOrient: 'vertical',
							}}
							>
								{ticket.note}
							</Typography>
						</Box>
					</Stack>
					<Stack justifyContent="space-between" alignItems="center">
						<Tag sx={{ background: ticket.status.color }} label={ticket.status.name} fontSize="9px" />
						<DefaultButton onClick={onClick} sx={{ alignSelf: 'end' }} text={t('seeMore')} minWidth="100px" />
					</Stack>
				</Stack>
			)}
		/>
	);
};

export default TicketItem;
