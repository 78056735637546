import { Box, Tab, Tabs } from '@mui/material';
import { ArticleType } from 'data/models';
import React, { FC } from 'react';

interface TabOffersProps {
	value: number;
	articleTypes?: ArticleType[];
	onClick?: (event: React.SyntheticEvent, newValue: string) => void
}

const TabOffers: FC<TabOffersProps> = (props) => {
	const { value, articleTypes, onClick } = props;
	return (
		<Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
			<Tabs value={value} onChange={onClick} aria-label="nav tabs example" centered variant="fullWidth" visibleScrollbar>
				{articleTypes?.map((article) => (
					<Tab
						label={article.name}
						value={article.id}
						key={article.id}
					/>
				))}
			</Tabs>
		</Box>
	);
};

export default TabOffers;
