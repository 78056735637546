import { Stack, SvgIcon } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { MuiSvgType } from 'utils/mui.utils';
import Dimens from '../../../assets/dimens';

interface ReadonlyLabelProps{
    icon?: MuiSvgType;
    label: string;
    value: string;
}
const ReadonlyLabel: React.FC<ReadonlyLabelProps> = ({
	icon,
	label,
	value,
}) => {
	const Label = styled('div')(() => ({
		fontSize: Dimens.FEATURE_TEXT,
		fontWeight: 500,
		color: 'title.color',
	}));

	const Value = styled('div')(() => ({
		fontSize: Dimens.FEATURE_TEXT,
		fontWeight: 600,
		color: 'title.color',
		wordWrap: 'inherit',
	}));

	return (
		<Stack direction="row" spacing={2}>
			{icon && (<SvgIcon component={icon} color="primary" sx={{ width: 20 }} />)}
			<Stack direction="column">
				<Label>{label}</Label>
				<Value>{value}</Value>
			</Stack>
		</Stack>
	);
};

export default ReadonlyLabel;
