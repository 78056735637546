import { Box, Stack, SvgIcon } from '@mui/material';
import { Subtitle, Title } from 'components/atoms';
import React, { FC } from 'react';
import {
	getDayFromDate, getDayShortName, getMonthFormated, getTimeFromDate,
} from 'utils/general.utils';

interface CounterLabelProps {
	icon: React.ElementType;
	name?: string;
	date?: string;
}

const CounterLabel: FC<CounterLabelProps> = (props) => {
	const { icon, name, date } = props;
	return (
		<Stack direction="row" sx={{ mt: 2 }}>
			<SvgIcon
				component={icon}
				sx={{
					color: 'primary.main',
				}}
				fontSize="large"
				viewBox="0 0 30 30"
			/>
			<Box sx={{ ml: 2 }}>
				<Title fontSize="20px" sx={{ fontWeight: '300' }}>{name}</Title>
				<Subtitle>
					{getDayShortName(date)}
					,
					{' '}
					{getDayFromDate(date)}
					/
					{getMonthFormated(date)}
					{' '}
					-
					{' '}
					{getTimeFromDate(date)}
				</Subtitle>
			</Box>
		</Stack>
	);
};

export default CounterLabel;
