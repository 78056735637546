import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useFeedbackContext } from 'providers';
import React from 'react';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const FeedbackSnackbar: React.FC = () => {
	const { open, alertProps, handleAlertClose } = useFeedbackContext();

	return (
		<Snackbar
			open={open}
			autoHideDuration={alertProps?.duration ?? 6000}
			onClose={handleAlertClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		>
			<Alert onClose={handleAlertClose} severity={alertProps?.type} sx={{ width: '100%' }}>
				{alertProps?.message}
			</Alert>
		</Snackbar>
	);
};
export default FeedbackSnackbar;
