import { Stack, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import React, { FC, ReactNode } from 'react';
import Subtitle from '../subtitle/SubTitle';

interface ListItemProps {
	label?: string;
	renderInput: ReactNode
}

const ListItem: FC<ListItemProps> = (props) => {
	const { label, renderInput } = props;
	const theme = useTheme();
	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: Dimens.LIST_ITEM_MARGIN }}>
			<Subtitle fontSize={`${Dimens.TEXT_DEFAULT}`} fontWeight={600} sx={{ color: theme.light }}>{label}</Subtitle>
			{renderInput}
		</Stack>
	);
};

export default ListItem;
