import React from 'react';
import {
	Box, Stack, styled, Typography,
} from '@mui/material';
import { Dimens } from 'assets';
import { useSettingsContext } from 'providers';
import { FilterButton } from 'components/atoms';
import { generateKey } from 'utils/general.utils';
import { Close } from '@mui/icons-material';

interface FilterGroupProps<T> {
   title: string;
   options: T[];
   fontColor?: string;
   fontSize?: string;
   onSelect?: (value: T | T[] | undefined | []) => void;
   renderLabel: (value: T) => string;
   defaultValues?: T | T[] | undefined;
   multiple?: boolean;
   disabled?: boolean;
}

const FilterGroup = <T, >(props: FilterGroupProps<T>): JSX.Element => {
	const {
		title, options, fontColor, fontSize = Dimens.TEXT_DEFAULT as string,
		onSelect, renderLabel, defaultValues, multiple, disabled,
	} = props;

	const [addedDefault, setAddedDefault] = React.useState(false);

	const [value, setValue] = React.useState<T | T[] | undefined>();
	const { colors } = useSettingsContext();

	const ShortTitle = styled('div')(() => ({
		fontSize,
		fontWeight: 600,
		color: fontColor || colors.textDisabled,
	}));

	const addValue = React.useCallback((item: T): void => {
		if (multiple) {
			setValue((state) => {
				if (state) {
					const arr = Array.isArray(state) ? state : [state];
					const index = arr.indexOf(item);
					if (index === -1) {
						return [...arr, item];
					}
					arr.splice(index, 1);
					return [...arr];
				}
				return [item];
			});
		} else {
			setValue(value !== item ? item : undefined);
		}
	}, [multiple, value]);

	React.useEffect(() => {
		if (!addedDefault && defaultValues) {
			addValue(defaultValues as T);
			setAddedDefault(true);
		}
	}, [defaultValues, addedDefault, addValue]);

	React.useEffect(() => {
		onSelect?.call(0, value);
	}, [value, onSelect]);

	const isSelected = (item: T) => {
		if (Array.isArray(value)) {
			return value.indexOf(item) !== -1;
		}
		return item === value;
	};

	const optionsList = options.map((item, index) => (
		<FilterButton
			key={generateKey(`features_${index}`)}
			disabled={!!disabled}
			text={renderLabel(item)}
			selected={isSelected(item)}
			onClick={() => addValue(item)}
		/>
	));

	return (
		<Stack direction="column" width="100%">
			<Stack direction="row" justifyContent="space-between">
				<ShortTitle>
					{title}
				</ShortTitle>
				<Stack
					direction="row"
					alignItems="center"
					sx={{ '&:hover': { cursor: 'pointer' } }}
					onClick={() => {
						setValue([]);
						onSelect?.call(0, value);
					}}
				>
					<Close sx={{ color: 'red', fontSize: Dimens.TEXT_DEFAULT }} />
					<Typography sx={{ color: 'red', fontSize: Dimens.TEXT_DEFAULT, ml: 1 }}>clear</Typography>
				</Stack>
			</Stack>

			<Box sx={{
				mt: 2,
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				gap: 2,
				justifyContent: 'start',
			}}
			>
				{optionsList}
			</Box>
		</Stack>
	);
};

export default FilterGroup;
