import React, { useEffect, useState, useCallback } from 'react';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { organizationService } from 'data/api';
import { Filter, Organization } from 'data/models';
import { filterHoursAfter, getDateFormated } from 'utils/general.utils';
import useEffectCustom from 'hooks/useEffectCustom';
import { EndLocation, StartLocation } from 'assets/icons';
import SelectCounter from '../select-counter/SelectCounter';
import DateRange from '../date-range/DateRange';
import { DefaultButton } from '../../atoms';

interface ItineraryFormProps {
   onClick?: (filter: Filter) => void;
}

const ItineraryForm: React.FC<ItineraryFormProps> = ({ onClick }) => {
	const { t } = useTranslation('translations');

	const [pickup, setPickup] = useState<Organization>();
	const [returnL, setReturnL] = useState<Organization>();
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [endDate, setEndDate] = useState<Date>(new Date().nextDay());
	const [startTime, setStartTime] = useState<string | undefined>();
	const [endTime, setEndTime] = useState<string | undefined>();

	const enableBtn = !!pickup && !!returnL && !!startDate && !!endDate && !!startTime && !!endTime;

	const organizations = organizationService.useFetchOrganizations();

	const handleClick = () => {
		onClick?.call(0, {
			pickupLocation: pickup,
			returnLocation: returnL,
			startDate,
			endDate,
			startTime,
			endTime,
		} as Filter);
	};

	useEffectCustom(() => {
		organizations.execute();
	});

	const adjustDatesAndStartTime = useCallback((availableDates: string[], org: Organization) => {
		if (availableDates.length > 0) {
			const today = new Date();
			if (!(new Date(`${startTime} 00`)).isHourAfter(today) && getDateFormated(startDate) === getDateFormated(today)) {
				setStartTime(availableDates[0]);
			}
		} else {
			setStartDate((prev) => prev.nextDay());
			setEndDate((prev) => prev.nextDay());
			setStartTime(org.availableHours[0]);
		}
	}, [startTime, startDate]);

	useEffectCustom(() => { // INIT -> Adjust date base on the current time
		const { data } = organizations;
		if (data && data.length > 0) {
			const org = data[0];
			setPickup(org);
			setReturnL(org);
			const availableDates = filterHoursAfter(startDate, org?.availableHours || []);
			adjustDatesAndStartTime(availableDates, org);
			setEndTime(org.availableHours[0]);
		}
	}, [organizations.data]);

	useEffect(() => {
		if (pickup) {
			const availableDates = filterHoursAfter(startDate, pickup?.availableHours || []);
			adjustDatesAndStartTime(availableDates, pickup);
		}
	}, [pickup, startDate, adjustDatesAndStartTime]);

	return (
		<div>
			<SelectCounter
				onChange={(item) => setPickup(item)}
				icon={StartLocation}
				data={organizations.data}
				label={t('pickupLocation')}
				mt={4}
				value={pickup}
			/>
			<SelectCounter
				onChange={(item) => setReturnL(item)}
				icon={EndLocation}
				data={organizations.data}
				label={t('returnLocation')}
				mt={4}
				value={returnL}
			/>
			<DateRange
				mt={2}
				startTimeAvailable={filterHoursAfter(startDate, pickup?.availableHours || [])}
				endTimeAvailable={returnL?.availableHours || []}
				valueDateRange={[startDate, endDate]}
				valueTime={[startTime, endTime]}
				onDateChange={(start, end) => {
					setStartDate(start);
					setEndDate(end);
				}}
				onTimeChange={(start, end) => {
					setStartTime(start);
					setEndTime(end);
				}}
			/>

			<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
				<Box sx={{ flexGrow: 1 }} />
				<DefaultButton
					sx={{ textTransform: 'none' }}
					disabled={!enableBtn}
					text={t('search')}
					minWidth="110px"
					onClick={handleClick}
				/>
				<Box sx={{ flexGrow: 1 }} />
			</Stack>
		</div>
	);
};

export default ItineraryForm;
