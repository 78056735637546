/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosError } from 'axios';
import { useState } from 'react';
import { log } from '../../utils/general.utils';
import { useCache } from '../../providers';
import { ApiResponse, ApiResponseBase } from '../models';

const TAG = 'useRequest';

type Options = {
  cache?: boolean;
  cacheKey?: string;
}

const defaultOptions = {
	cache: false,
};

const getCacheKey = (key: string, ...params:any): string => key.format(params);

export const useRequest = <T>(
	request: (...params: any) => Promise<ApiResponse<T>>,
	options?: Options,
) => {
	const mOptions = { ...defaultOptions, ...options };
	const { addToCache, getFromCache } = useCache();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<T>();
	const [info, setInfo] = useState<ApiResponseBase>();
	const [error, setError] = useState<boolean>();

	/**
   * return true if the request was successfully
   * @param params ...any
   * @returns boolean
   */
	const execute = async (...params: any): Promise<boolean> => {
		setInfo(undefined);
		setLoading(true);
		const enableCache = !!mOptions?.cache;
		const cacheKey = getCacheKey(mOptions?.cacheKey || '', params);
		if (enableCache) {
			if (!options?.cacheKey) { // if is undefined, null or empty
				throw new Error('Cache is enabled but Cache Key has not been set');
			}
			const cache = getFromCache(cacheKey);
			if (cache) {
				const resp = cache as ApiResponse<T>;
				setData(resp.data);
				setInfo(resp as ApiResponseBase);
				setError(!!resp.errorType);
				setLoading(false);
				log(TAG, `Using cache to ${cacheKey}`);
				return !!resp.errorType;
			}
			log(TAG, `Cache is empty to ${cacheKey}`);
		}

		try {
			const response = await request(params);
			if (enableCache) {
				addToCache(cacheKey, response);
			}
			setData(response.data);
			setInfo(response as ApiResponseBase);
			setError(!!response.errorType);
			setLoading(false);
			return !!response.errorType;
		} catch (e) {
			const axiosResp = (e as AxiosError);
			const resp = axiosResp?.response?.data as ApiResponseBase;
			setInfo({
				message: resp?.message, title: resp?.title || '', errorType: resp?.errorType, statusCode: axiosResp?.response?.status,
			});
			setError(true);
			log(TAG, resp);
			setLoading(false);
			return true;
		}
	};

	return {
		execute,
		loading,
		data,
		info,
		error,
		setData,
	};
};

export default useRequest;
