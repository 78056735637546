import {
	CardMedia, Grid, Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Box, SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Dimens } from 'assets';
import { CarFeature, Status } from 'data/models';
import { getFeatureIcon, setOnErrorDefaultImage } from 'utils/general.utils';
import {
	CheckedLabel, DefaultButton, FeatureInfo, PriceInfo, Subtitle, Tag, Title,
} from '../../atoms';
import { Card } from '..';

type Mode = 'full' | 'short';

// @todo this component can be split in other two or three components
interface CardCategoryProps {
   shortTitle: string;
   title: string;
   subtitle: string;
   pricePerDay: number | undefined;
   numberOfDays?: number;
   image: string;
   features?: CarFeature[];
   mode?: Mode;
   btnName?: string;
   hideBtn?: boolean;
   status?: Status;

   onClick?: () => void;
   renderOtherSection?: () => React.ReactNode;
}

const CardCategory: React.FC<CardCategoryProps> = ({
	shortTitle,
	title,
	subtitle,
	pricePerDay,
	numberOfDays,
	image,
	features = [] as CarFeature[],
	mode = 'short' as Mode,
	btnName = '',
	hideBtn,
	status,
	onClick,
	renderOtherSection,
}) => {
	const { t } = useTranslation('translations');
	const pricePerDayL = pricePerDay || 0;
	const amount = numberOfDays ? pricePerDayL * numberOfDays : pricePerDay;
	const getQtdMainColumns = () => (mode === 'full' ? 9 : 12);

	const getColumns = () => (mode === 'full' ? [6, 3] : [7, 5]);

	const ShortTitle = styled('div')(({ theme }) => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 600,
		color: theme.disabled,
	}));

	const centerContentBox = {
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	} as SxProps;

	const shortTitleSize = () => {
		if (mode === 'short') {
			return {
				minHeight: {
					xs: 'auto',
					sm: '108px',
				},
			};
		}
		return {};
	};

	const handleClick = () => {
		onClick?.call(0);
	};

	const contractFeatures = features
		?.filter((item) => item.type?.id === 2 && item.highlight)
		.map((item) => <CheckedLabel key={item.id} label={item.name} />);

	const otherFeatures = features
		?.filter((item) => !item.highlight)
		.slice(0, 4)
		.map((item) => (
			<FeatureInfo
				key={item.id}
				icon={getFeatureIcon(item.id)}
				showLabel
				label={item.name}
				value={item.isBoolean ? true : item.value ?? 0}
			/>
		));

	const content = (
		<Stack direction="row" spacing={2}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={12}>
					<Stack direction="row" spacing={2}>
						<Stack direction="column" sx={{ ...shortTitleSize() }}>
							<ShortTitle>{shortTitle}</ShortTitle>
							<Title
								sx={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									lineClamp: 2,
									WebkitLineClamp: 2,
									display: '-webkit-box',
									WebkitBoxOrient: 'vertical',
								}}
							>
								{title.toUpperCase()}
							</Title>
							<Subtitle>{subtitle}</Subtitle>
						</Stack>
						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
						{mode === 'short' && (
							<Stack sx={{ display: { xs: 'none', md: 'flex' } }} direction="column" spacing={2} alignSelf="center">
								<PriceInfo label={t('day')} amount={amount} pricePerDay={pricePerDay} />
								{!hideBtn && <DefaultButton onClick={handleClick} sx={{ alignSelf: 'end' }} text={t('reserve')} />}
							</Stack>
						)}
					</Stack>
				</Grid>

				<Grid item xs={12} md={getColumns()[0]}>
					<CardMedia
						component="img"
						alt="Car image"
						sx={{ borderRadius: '10px', mt: 2, height: { md: '120px', xs: '200px' } }}
						onError={setOnErrorDefaultImage}
						src={image}
					/>
					<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
						{contractFeatures}
					</Stack>
				</Grid>
				<Grid item xs={6} md={getColumns()[1]} sx={{ mt: 2 }}>
					<Stack direction="column" spacing={1}>
						{features}
					</Stack>
				</Grid>

				{mode === 'short' && (
					<Grid item xs={6} md={getColumns()[1]} sx={{ mt: 2, display: { xs: 'flex', md: 'none' } }} flexDirection="column" alignItems="center">
						<Stack direction="column" alignItems="center" spacing={1}>
							<PriceInfo label="dia" amount={amount} pricePerDay={pricePerDay} />
							{!hideBtn && <DefaultButton onClick={handleClick} text={btnName} />}
						</Stack>
					</Grid>
				)}

				{mode === 'full' && (
					<Grid item xs={6} md={3} sx={{ mt: 2 }}>
						{renderOtherSection ? (
							renderOtherSection()
						) : (
							<Stack direction="column" spacing={1}>
								{otherFeatures}
							</Stack>
						)}
					</Grid>
				)}
			</Grid>
		</Stack>
	);

	return (
		<Grid container spacing={0.2}>
			<Grid item xs={12} md={getQtdMainColumns()}>
				<Card contentSx={{ mb: 0 }} shadows={false} content={content} />
			</Grid>
			{mode === 'full' && (
				<Grid item xs={12} md={3}>
					<Card
						contentSx={{ height: '100%' }}
						shadows={false}
						content={(
							<Grid container item sx={{ pt: 2, pb: 2, minHeight: '70px' }}>
								<Box sx={centerContentBox}>
									{status && (
										<Tag
											sx={{ background: status.color }}
											label={status.name}
											fontSize={Dimens.TEXT_DEFAULT as string}
											iconSize={16}
										/>
									)}
									<Stack direction="column" alignItems="center" sx={{ mt: { xs: 0, md: 2 }, mb: { xs: 0, md: 2 } }}>
										<PriceInfo label={t('day')} amount={amount} pricePerDay={pricePerDay} sx={{ alignItems: 'end' }} />
										<CheckedLabel fontSize={Dimens.TEXT_DEFAULT as string} label={t('freeCancelation')} />
									</Stack>
									{!hideBtn && <DefaultButton onClick={handleClick} text={btnName ?? t('reserve')} minWidth="150px" />}
								</Box>
							</Grid>
						)}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default CardCategory;
