import {
	Container, Divider, Stack, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Dimens } from 'assets';
import { getStorageItem, StorageKeys } from 'data/storage';
import { DefaultButton, Subtitle } from '../../atoms';

interface SimpleFooterProps {
   onlyContact?: boolean;
   hideDivider?: boolean;
   handleMyReservationClick?: () => void
}

const SimpleFooter: React.FC<SimpleFooterProps> = ({
	onlyContact, hideDivider, handleMyReservationClick,
}) => {
	const { t } = useTranslation('translations');
	const initialSettings = getStorageItem(StorageKeys.INITIAL_SETTINGS);
	const { mainOffice } = initialSettings;
	const theme = useTheme();

	return (
		<section>
			{!hideDivider && <Divider orientation="horizontal" sx={{ mb: 4 }} />}
			<Container maxWidth="lg" sx={{ justifyContent: 'center', alignItems: 'center' }}>
				{!onlyContact && (
					<>
						<Typography sx={{ color: theme.light }} fontWeight={500} fontSize={Dimens.TEXT_NORMAL} textAlign="center">
							{t('reservationCorrectionInfo')}
						</Typography>

						<Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ mt: Dimens.DEFAULT_PADDING }}>
							<DefaultButton onClick={() => handleMyReservationClick?.call(0)} text={t('manageReservations')} />
						</Stack>
					</>
				)}

				<Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ mt: 2, mb: 4 }}>
					<CallOutlinedIcon />
					<Subtitle sx={{ mr: 4 }}>
						{' '}
						(
						{mainOffice?.phoneCountry?.phoneCode}
						)
						{' '}
						{mainOffice.phone}
						{' '}
						{mainOffice.email}
					</Subtitle>
					<Box sx={{ flexGrow: 0.02 }} />
					<EmailOutlinedIcon />
					<Subtitle>
						{' '}
						{mainOffice.fullAddress}
					</Subtitle>
				</Stack>
			</Container>
		</section>
	);
};

export default SimpleFooter;
