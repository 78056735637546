import { Stack } from '@mui/material';
import React from 'react';
import { StepButton } from '../../atoms';

interface SteppersProps {
    steps: string[],
    selected: number,
    onClick?: (number: number) => void
}

const Steppers: React.FC<SteppersProps> = ({
	steps, selected, onClick,
}) => {
	const handleOnClick = (number: number) => {
		if (onClick) onClick(number);
	};

	const stepsL = steps.map((item, index) => (
		<StepButton
			key={item}
			number={index + 1}
			label={item}
			onClick={handleOnClick}
			selected={index + 1 === selected}
		/>
	));

	return (
		<Stack direction="row" spacing={2} alignItems="center">
			{stepsL}
		</Stack>
	);
};

export default Steppers;
