import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Stack, Typography, useTheme } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useTranslation } from 'react-i18next';
import { Dimens } from 'assets';
import { useAuth } from 'providers';
import { validateEmail } from 'utils/general.utils';
import { ForgotPasswordDialog } from '..';
import { Subtitle, DefaultInput, DefaultButton } from '../../atoms';

export type LoginAction = 'login' | 'create-account';

interface LoginFormProps {
   onClick?: (action: LoginAction, email?: string, password?: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
	onClick,
}) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const [email, setEmail] = useState<string>('');
	const [emailChecked, setEmailChecked] = useState<boolean>(false);
	const [password, setPassword] = useState<string>('');
	const [error, setError] = useState<string | undefined>();
	const [open, setOpen] = useState(false);

	const { loginReqInfo } = useAuth();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const btnDisable = () => {
		const mEmail = email || '';
		const mPassword = password || '';
		return !(!!mEmail.trim() && !!mPassword.trim());
	};

	useEffect(() => {
		setEmailChecked(validateEmail(email || ''));
		setError(undefined);
	}, [email, password]);

	useEffect(() => {
		if (loginReqInfo.error) {
			setError(loginReqInfo.info?.message);
		} else {
			setError(undefined);
		}
	}, [loginReqInfo]);

	return (
		<Stack direction="column" spacing={2} sx={{ mt: 4 }} component="form" autoComplete="off">
			<DefaultInput label={t('email').toUpperCase()} value={email} icon={EmailIcon} onChange={(value) => setEmail(value)} checked={emailChecked} />

			<DefaultInput
				label={t('password').toUpperCase()}
				value={password}
				icon={VpnKeyIcon}
				type="password"
				onChange={(value) => setPassword(value)}
				autoComplete="off"
				checked={(password || '').length > 3}
			/>

			<Box sx={{ flexGrow: 1 }} />

			<Typography sx={{
				color: 'red', fontSize: Dimens.TEXT_DEFAULT, mt: 10, textAlign: 'center',
			}}
			>
				{error}
			</Typography>
			<Stack direction="row" spacing={2}>
				<DefaultButton
					loading={loginReqInfo.loading}
					type="submit"
					disabled={btnDisable() || !emailChecked || (password || '').length < 3}
					text={t('Login')}
					minWidth="110px"
					onClick={(e) => {
						e?.preventDefault();
						onClick?.call(0, 'login', email, password);
					}}
				/>
				<DefaultButton
					text={`${t('create')} ${t('account')}`}
					minWidth="110px"
					color={theme.palette.title?.color}
					variant="outlined"
					disabled={loginReqInfo.loading}
					onClick={() => onClick?.call(0, 'create-account')}
				/>
			</Stack>
			<Box onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
				<Subtitle sx={{ textAlign: 'center', color: theme.link }}>{t('forgotPasswordInfo')}</Subtitle>
			</Box>
			<ForgotPasswordDialog open={open} onClose={handleClose} />
		</Stack>
	);
};

export default LoginForm;
