import {
	AppBar,
	Button,
	Container,
	Dialog,
	Divider,
	Grid,
	IconButton,
	Slide,
	Stack,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	FilterGroup, RangeSlider, CategorySkeleton, ReservationSuccessDialog,
} from 'components/molecules';
import { CardItineraryEdit, CategoryDialog, Footer } from 'components/organisms';
import useEffectCustom from 'hooks/useEffectCustom';
import CategoryCard from 'components/molecules/card/CategoryCard';
import NoResults from 'components/organisms/no-results/NoResults';
import { North, South } from '@mui/icons-material';
import { useSettingsContext } from '../../../../providers';
import { Title } from '../../../atoms';
import Category from '../../../../data/models/category.model';
import {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	dateToString,
	generateKey,
	getPricePerDayWithDiscount,
	getPriceWithVAT,
	getReservationQtdDays,
	setCategoriesByPrice,
} from '../../../../utils/general.utils';
import { Dimens } from '../../../../assets';
import { filterService, categoryService, carService } from '../../../../data/api';
import { FilterOptions } from '../../../../data/api/services/category.service';
import { CarFeature, Filter, FilterMinimal } from '../../../../data/models';
import { getStorageItem, StorageKeys } from '../../../../data/storage';
import { AppLinks } from '../../../routes';

const Transition = React.forwardRef((
	props: TransitionProps & {
      children: React.ReactElement;
   },
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const ReservationSearchScreen = (): JSX.Element => {
	const { t } = useTranslation('translations');
	const { colors } = useSettingsContext();
	const [selected, setSelected] = React.useState<Category>();
	const [openDetail, setOpenDetail] = React.useState<boolean>(false);

	const [openFilter, setOpenFilter] = React.useState(false);
	const [priceRange, setPriceRange] = React.useState<number[]>([0, 0]);

	const history = useHistory();

	/* FilterSelected >=============== */
	const [filter, setFilter] = useState<Filter>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [numberOfDays, setNumberOfDays] = useState(1);
	const [priceSelectedRange, setPriceSelectedRange] = useState<number[]>([0, 0]);
	const [categories, setCategories] = useState<Category[] | null>();
	const [vehicleFeatures, setVehicleFeatures] = useState<CarFeature[] | null>();
	const [contractFeatures, setContractFeatures] = useState<CarFeature[] | null>();
	const [orderBy, setOrderBy] = useState(0);

	const [searchResult, setSearchResult] = useState<JSX.Element[] | undefined>();
	const [openReservationSuccess, setReservationSuccess] = useState<boolean>(false);

	/* Request >=============== */
	const useFetchFilters = filterService.useFetchFilter();
	const useFetchCategories = categoryService.useFetchCategories();
	const useFetchImages = carService.useGetCarsByCategoryID();

	const theme = useTheme();
	const showFilter = useMediaQuery(theme.breakpoints.up('sm'));

	useEffect(() => {
		if (showFilter && openFilter) {
			setOpenFilter(false);
		}
	}, [openFilter, showFilter]);

	useEffectCustom(() => {
		const mFilter = getStorageItem(StorageKeys.FILTER) as Filter;
		if (mFilter) {
			setFilter(mFilter);
			useFetchFilters.execute();
		} else {
			history.push(AppLinks.HOME);
		}
	}, [history]);

	useEffect(() => {
		if (filter) {
			const days = getReservationQtdDays(
				dateToString(filter?.startDate, filter?.startTime),
				dateToString(filter?.endDate, filter?.endTime),
			);
			setNumberOfDays(days === 0 ? 1 : days);
		}
	}, [filter]);

	useEffect(() => {
		setPriceRange([
			useFetchFilters.data?.priceRange?.min || 0, useFetchFilters.data?.priceRange?.max || 0,
		]);
	}, [useFetchFilters.data]);

	useEffectCustom(() => {
		if (selected) {
			useFetchImages.execute(selected?.id);
		}
	}, [selected]);

	const doSearch = () => {
		setOrderBy(0);
		if (filter) {
			useFetchCategories.execute({
				startDate: dateToString(filter?.startDate, filter?.startTime),
				endDate: dateToString(filter?.endDate, filter?.endTime),
				pickupOrganizationId: filter.pickupLocation?.id,
				returnOrganizationId: filter.returnLocation?.id,
				minPrice: priceSelectedRange[0],
				maxPrice: priceSelectedRange[1],
				categories: categories?.map((item) => item.id) || [],
				features: [...(contractFeatures || []), ...(vehicleFeatures || [])].map((item) => item.id),
			} as FilterOptions);
		}
	};

	useEffectCustom(() => {
		doSearch();
	}, [priceSelectedRange, filter, categories, contractFeatures, vehicleFeatures]);

	const handleFilterOpen = () => {
		setOpenFilter(true);
	};

	const handleFilterClose = () => {
		setOpenFilter(false);
	};

	const handleCategoryClick = (category: Category) => {
		setSelected(category);
		setOpenDetail(true);
	};

	const handleOnDetailClose = () => {
		setOpenDetail(false);
	};

	const onAscendingClick = () => {
		setOrderBy(1);
		setCategoriesByPrice(useFetchCategories.setData, 1);
	};
	const onDescendingClick = () => {
		setOrderBy(2);
		setCategoriesByPrice(useFetchCategories.setData, 2);
	};

	const handleItinerarySearch = (params: FilterMinimal) => {
		setFilter({
			startTime: filter?.startTime ?? null,
			endTime: filter?.endTime ?? null,
			startDate: params.startDate,
			endDate: params.endDate,
			pickupLocation: params.pickupLocation,
			returnLocation: params.returnLocation,
		});
	};

	useEffect(() => {
		if (numberOfDays) {
			const resultFilter = useFetchCategories.data?.categories?.map((item) => {
				const pricePerDay = getPriceWithVAT(item.pricePerDay);
				const pricePerDayWithDiscount = getPricePerDayWithDiscount(
					numberOfDays,
					item.pricePerDay,
					item.id,
					useFetchCategories.data?.quantityDiscounts,
					useFetchCategories.data?.campaigns,
					true,
				);
				return (
					<Grid key={generateKey(`category_${item.id}`)} item xs={12} sm={12} md={12}>
						<CategoryCard
							title={item.cta}
							subtitle={item.name}
							image={item.image}
							features={item.features}
							pricePerDay={pricePerDay}
							totalPrice={pricePerDay * numberOfDays}
							pricePerDayWithDiscount={pricePerDayWithDiscount}
							totalPriceWithDiscount={pricePerDayWithDiscount * numberOfDays}
							onClick={() => handleCategoryClick(item)}
						/>
					</Grid>
				);
			});

			setSearchResult(resultFilter);
		}
	}, [
		numberOfDays,
		useFetchCategories.data,
		orderBy,
	]);

	const filters = (
		<>
			<RangeSlider
				defaultValues={priceRange}
				onChange={(value) => setPriceSelectedRange(value)}
				min={priceRange[0]}
				max={priceRange[1]}
				label={t('priceRange')}
				valueText={(item) => `€ ${item}`}
			/>

			<Stack direction="column" spacing={2} alignItems="start" sx={{ mt: 4 }}>
				<FilterGroup
					onSelect={(values) => {
						if (Array.isArray(values)) {
							setVehicleFeatures(values);
						}
					}}
					disabled={useFetchCategories.loading}
					multiple
					title={t('vehicle').toUpperCase()}
					options={useFetchFilters?.data?.vehicleFeatures || []}
					renderLabel={(item) => `${item.name}`}
				/>
				<Divider orientation="horizontal" sx={{ mt: 4, mb: 4, width: '100%' }} />

				<FilterGroup
					onSelect={(values) => {
						if (Array.isArray(values)) {
							setContractFeatures(values);
						}
					}}
					disabled={useFetchCategories.loading}
					multiple
					title={t('contract')}
					options={useFetchFilters?.data?.contractFeatures || []}
					renderLabel={(item) => `${item.name}`}
				/>
				<Divider orientation="horizontal" sx={{ mt: 4, mb: 4, width: '100%' }} />

				<FilterGroup
					onSelect={(values) => {
						if (Array.isArray(values)) {
							setCategories(values);
						}
					}}
					disabled={useFetchCategories.loading}
					multiple
					title={t('categories').toUpperCase()}
					options={useFetchFilters?.data?.categories || []}
					renderLabel={(item) => `${item.name}`}
				/>
			</Stack>
		</>
	);

	const handleSuccessClose = () => {
		setReservationSuccess(false);
		history.push(AppLinks.MY_RESERVATIONS);
	};

	const handleSuccessOpen = () => {
		setReservationSuccess(true);
	};

	return (
		<>
			<Box
				sx={{
					background: theme.palette.bgSecondary?.firstColor,
				}}
			>
				<Container sx={{ pb: 10 }}>
					<Grid container spacing={4} sx={{ pt: 2 }}>
						{/* Start Filtros */}
						<Grid item md={3} sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}>
							<Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
								<Title>{t('filters')}</Title>
							</Stack>
							{filters}
						</Grid>

						<Grid item xs={12} md={12} sx={{ display: { md: 'none', sm: 'block', xs: 'block' } }}>
							<Stack direction="row" spacing={1} justifyContent="end">
								<Button
									variant="text"
									sx={{ color: 'title.color', fontSize: Dimens.TEXT_DEFAULT }}
									onClick={handleFilterOpen}
									endIcon={<FilterListIcon sx={{ color: colors.highlight }} />}
								>
									{t('seeMyFilters')}
								</Button>
							</Stack>
							<Dialog
								fullScreen
								open={openFilter}
								onClose={handleFilterClose}
								TransitionComponent={Transition}
							>
								<AppBar>
									<Toolbar>
										<IconButton edge="start" color="inherit" onClick={handleFilterClose} aria-label="close">
											<CloseIcon />
										</IconButton>
										<Title fontSize="16px" sx={{ ml: 1 }}>
											{t('filters')}
										</Title>
										<Box sx={{ flexGrow: 1 }} />
										<Button autoFocus color="inherit" onClick={handleFilterClose}>
											{t('save')}
										</Button>
									</Toolbar>
								</AppBar>
								<div style={{ padding: '24px', marginTop: '70px' }}>{filters}</div>
							</Dialog>
						</Grid>

						{/* End Filters */}
						{!useFetchCategories.loading && (
							<Grid container spacing={2} item md={9} sx={{ height: 'fit-content' }}>
								{/* Search bar */}
								{/* {suggestCategories} */}
								<Grid item md={12} sm={12} xs={12} sx={{ mt: 1 }}>
									<CardItineraryEdit filter={filter} handleItineraryClick={handleItinerarySearch} />
									<Stack direction="row" spacing={1}>
										<Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 2 }}>
											<Typography fontSize={Dimens.TEXT_DEFAULT} color="title.color">
												{t('yourFilterResults')}
											</Typography>
											<Typography
												fontSize={Dimens.TEXT_DEFAULT}
												fontWeight={700}
												sx={{ color: theme.disabled }}
											>
												{searchResult?.length}
												{' '}
												{t('vehicles')}
											</Typography>
										</Stack>
										<Box sx={{ flexGrow: 1 }} />
										<Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 2 }}>
											<Typography fontSize={Dimens.TEXT_DEFAULT} color="title.color">
												{t('orderBy')}
											</Typography>
											<Stack
												direction="row"
												alignItems="center"
												onClick={onAscendingClick}
												sx={{ '&:hover': { cursor: 'pointer' } }}
											>
												<Typography
													fontSize={Dimens.TEXT_DEFAULT}
													fontWeight={700}
													sx={{
														color: orderBy === 1 ? theme.palette.title?.main : theme.disabled,
													}}
												>
													{t('pricePopularity')}
												</Typography>
												<South sx={{ color: orderBy === 1 ? theme.palette.title?.main : theme.disabled, fontSize: Dimens.TEXT_DEFAULT, ml: '5px' }} />
											</Stack>
											<Stack
												direction="row"
												alignItems="center"
												onClick={onDescendingClick}
												sx={{
													'&:hover': { cursor: 'pointer' },
												}}
											>
												<Typography
													fontSize={Dimens.TEXT_DEFAULT}
													fontWeight={700}
													sx={{ color: orderBy === 2 ? theme.palette.title?.main : theme.disabled }}
												>
													{t('pricePopularity')}
												</Typography>
												<North sx={{ color: orderBy === 2 ? theme.palette.title?.main : theme.disabled, fontSize: Dimens.TEXT_DEFAULT, ml: '5px' }} />
											</Stack>
										</Stack>
									</Stack>
								</Grid>
								{(
									!useFetchCategories.loading
									&& useFetchCategories.data
									&& useFetchCategories.data?.categories?.length === 0)
									&& <NoResults subTitle={t('noCarsFoundDescription')} />}
								{searchResult}
							</Grid>
						)}

						{useFetchCategories.loading && (
							<Grid item md={9}>
								<Stack direction="column" spacing={4} sx={{ mt: 4 }}>
									<CategorySkeleton quantity={10} mtItem={2} />
								</Stack>
							</Grid>
						)}
					</Grid>

					{selected && (
						<CategoryDialog
							key={`category_${selected.id}`}
							open={openDetail}
							category={selected}
							itemsDiscount={useFetchCategories.data?.quantityDiscounts || []}
							campaigns={useFetchCategories.data?.campaigns || []}
							loadingImages={useFetchImages.loading}
							images={useFetchImages.data || []}
							onClose={handleOnDetailClose}
							included={['Super CDW', 'Imposto', 'Suplemento Condutor Jovem', 'Massagem']}
							notIncluded={['Seguro', 'Troca de Pneu', 'Abastecimento', 'Alguma coisa']}
							handleSuccessOpen={handleSuccessOpen}
						/>
					)}
				</Container>
			</Box>
			<Footer />
			<ReservationSuccessDialog
				open={openReservationSuccess}
				onClose={handleSuccessClose}
			/>
		</>
	);
};

export default ReservationSearchScreen;
