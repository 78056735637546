import React, { useEffect, useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import {
	Avatar, Button, IconButton, LinearProgress, Link, Popover, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dimens } from 'assets';
import { Subtitle } from 'components/atoms';
import { useAuth } from 'providers';
import { User } from 'data/models';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { AppLinks } from 'components/routes';
import { AccountForm, LoginForm } from '..';
import { LoginAction } from '../login-form/LoginForm';

const UserMenu: React.FC = () => {
	const { t } = useTranslation('translations');
	const history = useHistory();

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const [opt, setOpt] = useState(1);
	const {
		login, user, logout, logoutReqInfo, createAccount,
	} = useAuth();

	const open = Boolean(anchorEl);
	const id = open ? 'user-popover' : undefined;

	useEffect(() => {
		if (!open) {
			setOpt(1);
		}
	}, [open]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogoutClick = () => {
		logout().then(() => {
			handleClose();
		});
	};

	const handleLoginClick = (action: LoginAction, email?: string, password?: string) => {
		switch (action) {
		case 'create-account':
			setOpt(2);
			break;
		case 'login':
			if (!email || !password) return;
			login(email, password);
			break;
		default:
			break;
		}
	};

	const handleCreateClick = (mUser: User, password: string, code: string) => {
		createAccount(mUser, password, code).then(() => {
			handleClose();
		});
	};

	const onUserProfileClick = () => {
		history.push(AppLinks.PROFILE);
	};

	return (
		<>
			<IconButton aria-describedby={id} aria-label="user information" component="span" onClick={handleClick}>
				<Avatar alt={user?.name || 'user image'} sx={{ width: 24, height: 24 }} src={user?.profileImage} />
			</IconButton>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{user ? (
					<Stack direction="column" alignItems="center" justifyContent="center" sx={{ minWidth: 200, m: 2 }} spacing={0}>
						<Avatar alt={user?.name || 'user image'} src={user?.profileImage} sx={{ width: 64, height: 64 }} />
						<Typography fontSize={Dimens.TEXT_DEFAULT as string} sx={{ mt: 0.5, cursor: 'pointer' }} onClick={onUserProfileClick}>
							{t('edit')}
						</Typography>
						<Subtitle sx={{ mt: 2 }}>{user?.name}</Subtitle>
						<Typography fontSize={Dimens.TEXT_DEFAULT as string}>{user?.email}</Typography>
						<Typography fontSize={Dimens.TEXT_DEFAULT as string}>
							(
							{user?.phoneCountry?.phoneCode}
							)
							{' '}
							{user?.phone}
						</Typography>
						<Link
							component={RouterLink}
							to={AppLinks.MY_RESERVATIONS}
							underline="none"
							mt={2}
						>
							{t('myReservations')}
						</Link>
						<Button onClick={handleLogoutClick} color="secondary" sx={{ mt: 2 }}>
							<Stack direction="column" alignItems="center">
								<Stack direction="row" alignItems="center">
									<LogoutIcon sx={{ mr: 1 }} />
									{t('logout')}
								</Stack>
								{logoutReqInfo.loading && <LinearProgress sx={{ width: '100%' }} color="secondary" />}
							</Stack>
						</Button>
					</Stack>
				) : (
					<Stack direction="column" alignItems="center" justifyContent="center" sx={{ minWidth: 200, maxWidth: 350, m: 4 }} spacing={0}>
						{opt === 1 && <LoginForm onClick={handleLoginClick} />}
						{opt === 2 && <AccountForm onClick={handleCreateClick} />}
					</Stack>
				)}
			</Popover>
		</>
	);
};

export default UserMenu;
