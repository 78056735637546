import { BillableItem } from '.';
import CarFeature from './car-feature.model';
import Car from './car.model';

export default class Category {
    public id?: number;

    public name?: string;

    public cta?: string;

    public pricePerDay?: number;

    public pricePerHour?: number;

    public suggestedCarId?: number;

    public securityDeposit?: number;

    public image?: string;

    public cars?: Car[]

    public features?: CarFeature[]

    public description?: string;
}

export interface CampaignDiscount {
    id: number;
    name: string;
    itemId: number;
    startDate: string;
    endDate: string;
    discount: number;
    isPercentage: boolean;
    description: string;
    categories: number[];
    rules: string;
}

export interface QuantityDiscount {
    itemDiscountId: number;
    discount: number;
    minAmount: number;
    isPercentage: boolean;
    itemId: number;
    categoryId:number;
    registration:string;
}

export interface CategoryData {
    categories: Category[];
    campaigns: CampaignDiscount[];
    quantityDiscounts: QuantityDiscount[];
    extras: BillableItem[]
}
