import { LoadingButton } from '@mui/lab';
import { SxProps, Theme } from '@mui/material';
import React from 'react';

interface FilterButtonProps {
    loading?: boolean;
    text: string;
    selected?: boolean;
    disabled?: boolean;
    onClick?: () => void
    sx?: SxProps<Theme>
}

const FilterButton: React.FC<FilterButtonProps> = ({
	loading = false,
	text,
	selected = false,
	disabled,
	onClick,
	sx = {},
}) => {
	const sxButton = {
		fontSize: '10px',
		p: '8px 12px 8px 12px',
		fontWeight: 700,
		borderRadius: '3px',
		boxShadow: 'none',
		color: selected ? 'selected.color' : 'primary.contrastText',
		bgcolor: selected ? 'selected.bgColor' : 'bgSecondary.contrast',
		'&:hover': {
			color: 'selected.color',
			bgcolor: 'secondary.main',
		},
		...sx,
	} as SxProps<Theme>;

	return (
		<LoadingButton
			variant="outlined"
			onClick={() => { onClick?.call(0); }}
			disabled={disabled}
			loading={loading}
			sx={sxButton}
		>
			{text}
		</LoadingButton>
	);
};

export default FilterButton;
