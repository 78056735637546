import {
	CardMedia, Container, Grid, Skeleton, Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpecialOfferCard, TabOffers } from 'components/molecules';
import { thirdSectionBanner } from 'assets/images';
import useEffectCustom from 'hooks/useEffectCustom';
import { Title, Subtitle } from '../../atoms';
import { articleService } from '../../../data/api';

const SixthSection = (): JSX.Element => {
	const { t } = useTranslation('translations');
	const [type, setType] = useState<number>(1);

	const useFetchArticleTypes = articleService.useFetchArticleTypes();
	const {
		data, loading, execute,
	} = articleService.useFetchArticle();

	useEffectCustom(() => {
		useFetchArticleTypes.execute();
	});

	useEffect(() => {
		const types = useFetchArticleTypes.data;
		if (types && types.length > 0) {
			setType(types[0].id);
		}
	}, [useFetchArticleTypes.data]);

	const onClick = (_: React.SyntheticEvent, mType: string) => {
		setType(parseInt(mType, 10));
	};

	const articleList = data?.map((item) => (
		<SpecialOfferCard
			md={3}
			sm={12}
			xs={12}
			key={item.id}
			title={item.title}
			subtitle={item.description}
			image={item.image || ''}
			link={item.link}
		/>
	));

	useEffectCustom(() => {
		if (type) {
			execute(type);
		}
	}, [type]);

	return (
		<section id="offers">
			<Box sx={{
				mb: { md: 10, xs: 10 }, position: 'relative', minHeight: '90vh',
			}}
			>
				<Stack direction="column" sx={{ }} alignItems="center" justifyContent="center" flexDirection="column">
					<CardMedia component="img" alt="Beach view" sx={{ borderRadius: '5px' }} image={thirdSectionBanner} />
					<Container maxWidth="lg" sx={{ position: 'relative', mt: { md: -25 } }}>
						<Title sx={{ color: 'title.main', textAlign: 'center' }}>{t('specialOffersForYou')}</Title>
						<Subtitle sx={{ color: 'title.main', textAlign: 'center' }}>{t('completeListOfOffers')}</Subtitle>
						<Grid container>
							<Grid item md={12} sm={12} xs={12} lg={12} xl={12} sx={{ mt: { md: 5, xs: 2 } }}>
								<Grid container spacing={0}>
									<Stack
										direction="row"
										className="no-scroll"
										spacing={4}
										sx={{
											mt: 1, overflowX: 'scroll', overflowY: 'initial', width: '100%',
										}}
									>
										{!loading && articleList}
										{loading && (
											[1, 2, 3, 4].map((el) => (
												<Grid item md={3} sm={12} xs={12} key={el} sx={{ mb: 3 }}>
													<Skeleton variant="rectangular" height={300} sx={{ borderRadius: '10px' }} />
												</Grid>
											))
										)}
									</Stack>
								</Grid>
							</Grid>
						</Grid>
						<TabOffers value={type} articleTypes={useFetchArticleTypes.data} onClick={onClick} />
					</Container>
				</Stack>
			</Box>
		</section>
	);
};

export default SixthSection;
