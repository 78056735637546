/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import { ApiResponse, Organization } from '../../models';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

const fetchOrganizations = async (): Promise<ApiResponse<Organization[]>> => {
	const resp = await reqGet({
		url: `${Endpoint.ORGANIZATIONS}?TypeId=1`,
	});
	const data = resp.data as ApiResponse<Organization[]>;
	data.statusCode = resp?.status;
	return data;
};

export const useFetchOrganizations = () => useRequest(() => fetchOrganizations(), {
	cache: true,
	cacheKey: 'organizations',
});
