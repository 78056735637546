import { Stack, SvgIcon } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { MuiSvgType } from 'utils/mui.utils';
import { Dimens } from '../../../assets';
import { useSettingsContext } from '../../../providers';

interface LabelIconProps {
    label?:string;
    fontSize?: string;
    icon: MuiSvgType
    iconColor?: string
}

const LabelIcon: React.FC<LabelIconProps> = ({
	label,
	fontSize = Dimens.FEATURE_TEXT as string,
	icon,
	iconColor,
}) => {
	const { colors } = useSettingsContext();
	const Label = styled('div')(() => ({
		fontSize,
		fontWeight: 500,
		color: colors.primaryVariant,
	}));

	return (
		<Stack direction="row" spacing={0.5} alignItems="center" justifyContent="center">
			<SvgIcon component={icon} sx={{ color: iconColor || colors.success, width: 20 }} />
			<Label>{label}</Label>
		</Stack>
	);
};

export default LabelIcon;
