import {
	Autocomplete, Divider, Stack, SvgIcon, TextField, useTheme,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Dimens } from 'assets';
import { Organization } from 'data/models';
import { generateKey } from 'utils/general.utils';

interface SelectCounterProps {
    mt?: number;
    label?: string;
	icon: React.ElementType;
    data?: Organization[];
	disabled?: boolean;
    value?: Organization | undefined;
    onChange?: (value: Organization | undefined) => void;
	innerMarginTop?: number;
	innerMarginBottom?: number
	// variant?: 'filled' | 'outlined' | 'standard'
}

const SelectCounter: React.FC<SelectCounterProps> = ({
	mt = 1,
	label,
	icon,
	data = [],
	value,
	onChange,
	disabled,
	innerMarginTop = 2,
	innerMarginBottom = 1,
}) => {
	const theme = useTheme();
	const Label = styled('div')(() => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 400,
		color: theme.disabled,
	}));

	return (
		<div>
			<Stack sx={{ mt }}>
				{label && <Label>{label}</Label>}

				<Stack direction="row" sx={{ mt: innerMarginTop, mb: innerMarginBottom }} spacing={1} alignItems="center">
					<SvgIcon component={icon} viewBox="0 0 30 30" sx={{ color: 'primary.main' }} />
					<Autocomplete
						id="select-on-focus"
						key={generateKey(`auto-${value?.id}`)}
						options={data}
						filterSelectedOptions
						getOptionLabel={(option) => option.fantasyName}
						renderOption={(props, option) => (
							<li
								{...props}
								style={{ color: theme.palette.title?.color }}
								key={generateKey(option.id)}
							>
								{option.fantasyName}
							</li>
						)}
						onChange={(_, item) => onChange?.call(0, item)}
						disableClearable
						disabled={disabled}
						sx={{ flexGrow: 1 }}
						value={value}
						renderInput={(params) => (
							<TextField
								{...params}
								sx={{
									input: { color: theme.palette.title?.color },
									'.MuiSvgIcon-root': {
										color: theme.disabled,
									},
								}}
								InputProps={{ ...params.InputProps, disableUnderline: true }}
								variant="standard"
							/>
						)}
					/>
				</Stack>
				{label && <Divider orientation="horizontal" flexItem />}
			</Stack>
		</div>
	);
};

export default SelectCounter;
