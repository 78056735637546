import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useTranslation } from 'react-i18next';
import { userService } from 'data/api';
import { Country } from 'data/models';
import useEffectCustom from 'hooks/useEffectCustom';
import { DefaultInput } from '../../atoms';

interface VerificationNumberDialogProps {
   open: boolean;
   country: Country;
   phone: string;
   onEnteredCode: (code: string) => void;
   onCancel: () => void;
}

const VerificationNumberDialog: React.FC<VerificationNumberDialogProps> = ({
	open, country, phone, onCancel, onEnteredCode,
}) => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const [code, setCode] = useState<string>('');
	const {
		loading, execute,
	} = userService.useSendNumberToVerification();

	useEffectCustom(() => {
		if (open) {
			execute(country.id, phone, 'context');
		}
	}, [country.id, open, phone]);

	return (
		<Dialog open={open} onClose={onCancel}>
			<DialogTitle sx={{ color: theme.palette.title?.color }}>{t('verifyNumber')}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t('enterCodeSentTo')}
					{' '}
					<Typography fontWeight={700} sx={{ color: theme.palette.title?.color }}>
						+
						{country.phoneCode}
						{' '}
						{phone}
					</Typography>
				</DialogContentText>
				<Stack sx={{ mt: 2 }}>
					<DefaultInput type="number" label="Código" value={code} icon={VpnKeyIcon} onChange={(value) => setCode(value)} />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={onCancel}>
					{t('cancel')}
				</Button>
				<LoadingButton loading={loading} variant="text" color="secondary" onClick={() => onEnteredCode(code)}>
					{t('send')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default VerificationNumberDialog;
