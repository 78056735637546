import React from 'react';
import {
	CardMedia, Grid, Stack, styled,
} from '@mui/material';
import { Box } from '@mui/system';
import { Dimens } from 'assets';
import { useSettingsContext } from 'providers';
import { CarFeature, Optional } from 'data/models';
import { getFeatureIcon } from 'utils/general.utils';
import {
	CheckedLabel, FeatureInfo, Subtitle, Title,
} from '../../atoms';
import { Card } from '..';

interface SimpleCardCategoryProps {
    features?: CarFeature[];
    showOptionals?: boolean;
    optionalsSelected?: Optional[];
}

const SimpleCardCategory: React.FC<SimpleCardCategoryProps> = ({
	features, showOptionals, optionalsSelected,
}) => {
	const { colors } = useSettingsContext();
	const ShortTitle = styled('div')(() => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 600,
		color: colors.textDisabled,
	}));

	const featuresInfo = features?.filter((item) => item.type?.id === 1
        && item.highlight).slice(0, 4).map(
		(item) => (
			<FeatureInfo
				key={item.id}
				icon={getFeatureIcon(item.id)}
				label={item.name}
				value={item.isBoolean ? true : item.value ?? 0}
			/>
		),
	);

	const contractFeatures = features?.filter((item) => item.type?.id === 2
        && item.highlight).slice(0, 2).map(
		(item) => <CheckedLabel key={item.id} label={item.name} />,
	);

	const selectedOptionals = optionalsSelected?.map((item) => (
		<Stack direction="row" sx={{ p: 1 }} key={item.id}>
			<ShortTitle sx={{ fontSize: Dimens.FEATURE_TEXT }}>{item.name}</ShortTitle>
			<Box sx={{ flexGrow: 1 }} />
			<ShortTitle sx={{ fontSize: Dimens.FEATURE_TEXT, color: colors.disabled }}>X</ShortTitle>
		</Stack>
	));

	return (
		<Card
			content={(
				<Stack direction="column">
					<ShortTitle>O SEU VEÍCULO</ShortTitle>
					<Title>OPEL ASTRA OU SIMILAR</Title>
					<Subtitle>Ligeiro</Subtitle>

					<CardMedia
						component="img"
						alt="Car image"
						sx={{ borderRadius: '10px', mt: 2, height: { md: '140px', xs: '200px' } }}
						image="https://s2.glbimg.com/ahtR3y0dd19Li8dQvpMEnrqYKhI=/0x0:940x628/984x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_cf9d035bf26b4646b105bd958f32089d/internal_photos/bs/2020/1/1/kjld0oQxWpHXmcYZNEpg/2018-05-23-autoesporte-hotwheels-07.jpg"
					/>

					<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
						<Box sx={{ flexGrow: 1 }} />
						{contractFeatures}
						<Box sx={{ flexGrow: 1 }} />
					</Stack>

					<Grid
						item
						sx={{
							mt: 2, flexDirection: 'row', display: 'flex', gap: 1, justifyContent: 'center', flexWrap: 'wrap',
						}}
					>
						{featuresInfo}
					</Grid>

					{!!showOptionals && (
						<Grid item sx={{ mt: 2 }}>
							{selectedOptionals}
						</Grid>
					)}
				</Stack>
			)}
		/>
	);
};

export default SimpleCardCategory;
