import {
	Container, Grid, Skeleton, Stack, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	ReservationStatus, TicketCreateDialog, TicketDialog, TicketItem,
} from 'components/molecules';
import { Footer } from 'components/organisms';
import useEffectCustom from 'hooks/useEffectCustom';
import { DefaultButton } from '../../../../atoms';
import { AppLinks } from '../../../../routes';
import { reservationService, ticketService } from '../../../../../data/api';
import { Ticket } from '../../../../../data/models';
import { GenericErrorSreen } from '../../..';

const ReservationDocumentsScreen: React.FC = () => {
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState<Ticket>();
	const [formOpen, setFormOpen] = useState(false);
	const { id } = useParams<{ id?: string }>();
	const history = useHistory();
	const theme = useTheme();

	const handleClose = () => {
		setOpen(false);
	};

	const handleFormClose = () => {
		setFormOpen(false);
	};

	const {
		execute,
		info,
		loading,
		data,
	} = reservationService.useFetchReservationById();

	const useFetchTickets = ticketService.useFetchTickets();

	useEffectCustom(() => {
		execute(id).then((hasError) => {
			if (!hasError) {
				useFetchTickets.execute(id);
			}
		});
	});

	const handleDialogClick = (item: Ticket) => {
		setSelected(item);
		setOpen(true);
	};

	const result = useFetchTickets.data?.map((item) => (
		<Grid key={item.id} item xs={12} md={6}>
			<TicketItem ticket={item} onClick={() => handleDialogClick(item)} />
		</Grid>
	));

	const handleBackClick = () => {
		history.push(AppLinks.MY_RESERVATION_DETAIL.formatMap({ id }));
	};

	return (
		<Box>
			{!!info?.statusCode && info?.statusCode !== 200 ? (
				<GenericErrorSreen code={info?.statusCode} />
			) : (
				<Box sx={{
					background: theme.palette.bgSecondary?.firstColor,
					minHeight: '92.5vh',
					display: 'flex',
					flexDirection: 'column',
				}}
				>
					{loading || useFetchTickets.loading ? (
						<Container maxWidth="lg">
							<Grid container spacing={2} sx={{ mt: 4, pb: 4 }}>
								<Grid item xs={12} md={12}>
									<Skeleton variant="rectangular" height={40} width="100%" sx={{ borderRadius: '10px' }} />
								</Grid>

								<Grid item xs={12} md={6}>
									<Skeleton variant="rectangular" height={150} width="100%" sx={{ borderRadius: '10px' }} />
								</Grid>
								<Grid item xs={12} md={6}>
									<Skeleton variant="rectangular" height={150} width="100%" sx={{ borderRadius: '10px' }} />
								</Grid>
								<Grid item xs={12} md={6}>
									<Skeleton variant="rectangular" height={150} width="100%" sx={{ borderRadius: '10px' }} />
								</Grid>
								<Grid item xs={12} md={6}>
									<Skeleton variant="rectangular" height={150} width="100%" sx={{ borderRadius: '10px' }} />
								</Grid>
							</Grid>
						</Container>
					) : (
						<>
							<Container maxWidth="lg">
								<Grid container spacing={2} sx={{ mt: 2, pb: 4 }}>
									<Grid item xs={12} md={12}>
										<ReservationStatus onBackClick={handleBackClick} showBackIcon code={`#${data?.id}`} status={data?.status?.name} statusColor={data?.status?.color} />
									</Grid>

									<Grid item xs={12} md={12}>
										<Stack alignItems="flex-end">
											<DefaultButton
												onClick={() => setFormOpen(true)}
												text="Novo"
											/>
										</Stack>
									</Grid>

									{result}

								</Grid>
							</Container>
							<Box sx={{ flexGrow: 1 }} />
							<Footer />
						</>
					)}
					{selected && (
						<TicketDialog
							open={open}
							onClose={handleClose}
							ticket={selected}
						/>
					)}
					<TicketCreateDialog
						reservationID={parseInt(id || '0', 10)}
						open={formOpen}
						onClose={handleFormClose}
						onSaved={() => { useFetchTickets.execute(id); }}
					/>
				</Box>
			)}
		</Box>
	);
};

export default ReservationDocumentsScreen;
