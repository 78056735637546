/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import { ApiResponse, Card, CardPaymentIntent } from '../../models';
import Endpoint from '../endpoints.api';
import { reqDelete, reqGet, reqPost } from '../request.api';

const fetchPaymentMethods = async (): Promise<ApiResponse<Card[]>> => {
	const resp = await reqGet({
		url: Endpoint.CARDS,
	});
	const data = resp.data as ApiResponse<Card[]>;
	data.statusCode = resp.status;
	return data;
};
export const useFetchPaymentMethods = () => useRequest(() => fetchPaymentMethods());

const initiatePaymentIntent = async (): Promise<ApiResponse<{clientSecret: string}>> => {
	const resp = await reqPost({
		url: Endpoint.CARDS,
	});
	const data = resp.data as ApiResponse<{clientSecret: string}>;
	data.statusCode = resp.status;
	return data;
};
export const useInitiatePaymentIntent = () => useRequest(() => initiatePaymentIntent());

const deletePaymentMethod = async (cardId: number, clientId: number):
	Promise<ApiResponse<Card[]>> => {
	const resp = await reqDelete({
		url: Endpoint.CARD_DELETE.format(cardId),
		data: {
			clientType: 1,
			clientId,
		},
	});
	const data = resp.data as ApiResponse<Card[]>;
	data.statusCode = resp.status;
	return data;
};
export const useDeletePaymentMethod = () => useRequest(([cardId, clientId]) => (
	deletePaymentMethod(cardId, clientId)));

export const createPaymentIntent = async (): Promise<ApiResponse<CardPaymentIntent>> => {
	const resp = await reqPost({
		url: Endpoint.CARDS,
	});
	const data = resp.data as ApiResponse<CardPaymentIntent>;
	data.statusCode = resp.status;
	return data;
};
