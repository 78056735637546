import React from 'react';
import {
	Container, Grid, Stack, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Footer } from 'components/organisms';
import { CategorySkeleton } from 'components/molecules';
import { generateKey, getPriceWithVAT, log } from 'utils/general.utils';
import { useHistory } from 'react-router-dom';
import { reservationService } from 'data/api';
import useEffectCustom from 'hooks/useEffectCustom';
import ReservationCard from 'components/molecules/card/ReservationCard';
import { Title } from 'components/atoms';
import NoResults from 'components/organisms/no-results/NoResults';
import { Reservation } from '../../../../data/models';
import { AppLinks } from '../../../routes';

const MyReservationsScreen: React.FC = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const history = useHistory();

	const {
		execute: fetchReservations,
		data,
		loading,
	} = reservationService.useFetchReservations();

	useEffectCustom(() => {
		fetchReservations().then((status) => {
			log('MyReservation', status);
		});
	});

	const handleClick = (item: Reservation) => {
		history.push(AppLinks.MY_RESERVATION_DETAIL.formatMap({ id: item.id }));
	};

	const result = data?.map((item) => (
		<Grid key={generateKey(`reservation_${item.id}`)} item xs={12} sm={12} md={12}>
			<ReservationCard
				title={item.category?.cta}
				subtitle={item.category?.name}
				image={item.car?.image || item.category?.image}
				pricePerDayWithDiscount={getPriceWithVAT(item.dailyAmount)} // Confirm With MOBILE
				totalPriceWithDiscount={getPriceWithVAT(item.finalAmount)}
				features={item.car?.features}
				code={item.id}
				pickupLocation={item.pickupOrganization?.fantasyName}
				returnLocation={item.returnOrganization?.fantasyName}
				startDate={item.startDate}
				endDate={item.endDate}
				status={item.status}
				btnName={t('seeDetails').toString()}
				onClick={() => handleClick(item)}
			/>
		</Grid>
	));

	return (
		<>
			<Box sx={{ background: theme.palette.bgSecondary?.firstColor, minHeight: '100vh' }}>
				<Container maxWidth="lg" sx={{ pt: 4, pb: 2 }}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={12}>
							<Title>{t('myReservations')}</Title>
							{(!loading && data?.length === 0) && <NoResults subTitle={t('makeFirstReservationText')} />}
							{loading && (
								<Stack direction="column" spacing={2}>
									<CategorySkeleton quantity={6} mtItem={2} />
								</Stack>
							)}
							{!loading && (
								<Stack spacing={2}>
									{result}
								</Stack>
							)}
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Footer />
		</>
	);
};

export default MyReservationsScreen;
