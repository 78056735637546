import React, { useEffect } from 'react';
import {
	Button, SxProps, Stack, Typography, Popover, useTheme,
} from '@mui/material';
import { Dimens } from '../../../assets';

interface ListButtonProps<T> {
    data: T[];
    defaultValue?: T | undefined;
    onChange?: (item: T | undefined) => void;
    renderValue: (item: T| undefined) => React.ReactNode;
    renderOption: (item: T) => React.ReactNode;
    sx?:SxProps;
    variant?: 'text' | 'contained' | 'outlined';
}

const ListButton = <T, >(props: ListButtonProps<T>): React.ReactElement => {
	const {
		data, defaultValue, onChange, renderValue, renderOption, sx = {}, variant,
	} = props;
	const [selectedValue, setSelectedValue] = React.useState<T | undefined>();
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const theme = useTheme();

	const handleOnClose = () => {
		setAnchorEl(null);
	};

	const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleOnOptionClick = (item: T) => {
		setSelectedValue(item);
		setAnchorEl(null);
		onChange?.call(0, item);
	};

	const open = Boolean(anchorEl);

	const options = data.map((item, index) => (
		<Button
			onClick={
				() => handleOnOptionClick(item)
			}
			sx={{ background: selectedValue === item ? theme.palette.bgSecondary?.firstColor : theme.palette.listButton?.itemColor, boxShadow: 'none !important', borderRadius: '0px' }}
			variant="contained"
			key={index.toString()}
		>
			{renderOption(item)}

		</Button>
	));

	useEffect(() => {
		setSelectedValue(defaultValue);
	}, [defaultValue]);

	return (
		<span>
			<Button
				variant={variant || 'outlined'}
				sx={{
					border: `1px solid ${theme.disabled}`,
					...sx,
				}}
				onClick={handleOnClick}
			>
				<Typography component="div" sx={{ color: theme.disabled, fontSize: Dimens.TEXT_DEFAULT }}>
					{renderValue(selectedValue)}
				</Typography>
			</Button>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleOnClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>

				<Stack direction="column">
					{options}
				</Stack>
			</Popover>
		</span>
	);
};

export default ListButton;
