/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
	createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { log } from 'utils/general.utils';

export type CacheContextProps = {
    cache: Map<string, any> | undefined,
    addToCache: (key: string, value: any)=>void
    getFromCache: (key: string)=>any
};

const TAG = 'CacheContext';
export const CacheContext = createContext<CacheContextProps>(
    {} as CacheContextProps,
);

export const CacheProvider: React.FC = ({ children }) => {
	const [cache, setCache] = useState(new Map<string, any>());

	const addToCache = (key: string, value: any) => {
		setCache(
			new Map<string, any>(cache.set(key, value)), // avoid memory reference error
		);
	};

	const getFromCache = (key: string) => cache.get(key);

	useEffect(() => {
		log(TAG, cache);
	}, [cache]);

	const value = useMemo(
		() => ({
			cache,
			addToCache,
			getFromCache,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[cache],
	);

	return <CacheContext.Provider value={value}>{children}</CacheContext.Provider>;
};

export default function useCache() {
	return useContext(CacheContext);
}
