import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';
import { generateKey } from 'utils/general.utils';
import { Card } from '..';

interface CategorySkeletonProps {
    quantity?: number;
	mtItem?: number;
}

const CategorySkeleton: React.FC<CategorySkeletonProps> = ({ quantity = 1, mtItem = 0 }) => {
	const [list, setList] = React.useState<React.ReactElement[]>();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const skeleton = (index: number) => (
		<Card
			key={generateKey(`skeleton_${index}`)}
			shadows={false}
			contentSx={{ mt: mtItem }}
			content={(
				<Stack direction="row" spacing={0.2}>
					<Stack direction="column" spacing={1} sx={{ flexGrow: 1 }}>
						<Skeleton variant="rectangular" height={25} width="60%" sx={{ borderRadius: '10px' }} />
						<Skeleton variant="rectangular" height={20} width="40%" sx={{ borderRadius: '10px' }} />

						<Stack direction="row" spacing={1} sx={{ flexGrow: 1, pr: 2 }}>
							<Skeleton variant="rectangular" height="100%" sx={{ flexGrow: 1, borderRadius: '10px' }} />
							<Stack direction="column" spacing={1} sx={{ flexGrow: 1 }}>
								<Skeleton variant="rectangular" height={20} sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={20} sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={20} sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={20} sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={20} sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={20} sx={{ borderRadius: '10px' }} />
							</Stack>
						</Stack>
					</Stack>
					<Skeleton variant="rectangular" height={220} sx={{ flexGrow: 0.5, borderRadius: '10px' }} />
				</Stack>
			)}
		/>
	);

	React.useEffect(() => {
		if (quantity) {
			const arr: React.ReactElement[] = [];
			for (let i = 0; i < quantity; i += 1) {
				arr.push(skeleton(i));
			}
			setList(arr);
		}
	}, [quantity, skeleton]);

	return (
		<Box>
			{list}
		</Box>
	);
};

export default CategorySkeleton;
