import {
	Grid, GridSize, Card,
} from '@mui/material';
import React from 'react';
import { Dimens } from '../../../assets';

interface DefaultCardProps {
    highlighted?: boolean,
    xs?: boolean | GridSize | undefined
    md?: boolean | GridSize | undefined
    sm?: boolean | GridSize | undefined
    maxWidth?: string
}

const DefaultCard: React.FC<DefaultCardProps> = ({
	children,
	highlighted = false,
	xs = false,
	md = false,
	sm = false,
	maxWidth,
}) => (
	<Grid item xs={xs} md={md} sm={sm} maxWidth={maxWidth}>
		<Card sx={{

			padding: Dimens.DEFAULT_PADDING,
			borderRadius: highlighted ? Dimens.HIGHLIGHTED_RADIUS : Dimens.DEFAULT_RADIUS,
			boxShadow: highlighted ? '5px 5px 5px rgba(34, 57, 105, 0.1);' : 1,
		}}
		>
			{children}
		</Card>
	</Grid>
);

export default DefaultCard;
