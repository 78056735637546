import { Stack, Typography } from '@mui/material';
import { Dimens } from 'assets';
import { TicketMessage as Message } from 'data/models';
import React from 'react';
import { getFullDateFormated } from 'utils/general.utils';
import { Card } from '..';

interface TicketMessageProps {
    message: Message
}

const TicketMessage:React.FC<TicketMessageProps> = ({
	message,
}) => (
	<Card
		content={(
			<Stack direction="column" spacing={1}>
				<Typography
					sx={{
						fontSize: Dimens.TEXT_DEFAULT, color: (theme) => theme.disabled,
					}}
				>
					{getFullDateFormated(message.registration)}

				</Typography>
				<Stack direction="row" spacing={1} sx={{ mt: 1 }}>
					<Typography sx={{
						fontSize: Dimens.TEXT_DEFAULT,
					}}
					>
						{message.note}
					</Typography>
				</Stack>
			</Stack>
		)}
	/>
);

export default TicketMessage;
