import {
	CardMedia, Grid, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { CarFeature } from 'data/models';
import { Dimens } from 'assets';
import { getFeatureIcon } from 'utils/general.utils';
import { useTranslation } from 'react-i18next';
import {
	Title, Subtitle, PriceInfo, FeatureInfo, CheckedLabel, DefaultButton,
} from '../../atoms';
import Card from '../card/Card';

interface CarHighlightedProps {
    shortTitle: string
    title: string
    description: string,
    features?: CarFeature[]
    image: string,
    pricePerDay: number
}

const CarHighlighted: React.FC<CarHighlightedProps> = ({
	shortTitle, title, description, features = [], image, pricePerDay,
}) => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const ShortTitle = styled('div')(() => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 500,
		color: theme.disabled,
	}));

	const content = (
		<Box sx={{ mb: 2 }}>
			<ShortTitle>{shortTitle}</ShortTitle>
			<Title>
				{' '}
				{title}
				{' '}
			</Title>
			<Subtitle>{description}</Subtitle>

			<CardMedia
				component="img"
				alt="Car image"
				height="120"
				image={image}
			/>
		</Box>
	);

	const featuresInfo = features?.filter((item) => item.type?.id === 1).map((item) => (
		<FeatureInfo
			key={item.id}
			icon={getFeatureIcon(item.id)}
			label={item.name}
			value={item.isBoolean ? true : item.value ?? 0}
			width={40}
		/>
	));

	const contractFeatures = features?.filter((item) => item.type?.id === 2)
		.map((item) => (
			<CheckedLabel
				sx={{
					bgcolor: theme.palette.bgSecondary?.firstColor,
					borderRadius: 12,
					pl: '5px',
					pr: '5px',
					mt: '2px',
				}}
				key={item.id}
				label={item.name}
			/>
		));

	const footer = (
		<div>
			<Stack direction="row">
				<Stack direction="column">
					{contractFeatures}
				</Stack>
				<Box sx={{ flexGrow: 1 }} />
				<PriceInfo amount={pricePerDay} />
			</Stack>
			<Grid container spacing={2}>
				<Grid item md={6}>
					<Stack direction="row" sx={{ mt: 2 }} alignItems="center" justifyContent="space-between" flexWrap="wrap">
						{ featuresInfo }
					</Stack>
				</Grid>
				<Grid item md={6}>
					<Stack direction="column" sx={{ mt: 2, height: '100%' }} justifyContent="center">
						<DefaultButton text={t('reserve')} />
					</Stack>
				</Grid>
			</Grid>
		</div>
	);

	return (
		<Card
			minWidth={Dimens.CARD_MIN_WIDTH as string}
			content={content}
			footer={footer}
			shadows={false}
		/>
	);
};

export default CarHighlighted;
