import { Slider, Stack, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Dimens } from 'assets';
import { useSettingsContext } from 'providers';
import React, { useEffect } from 'react';
import { Subtitle } from '../../atoms';

interface RangeSliderProps {
    label: string;
    fontColor?: string;
    fontSize?: string;
    minDistance?: number;
    valueText: (value: number) => string;
    onChange?: (value: number[]) => void;
    defaultValues?: number[];
    min: number;
    max: number;
}

const RangeSlider: React.FC<RangeSliderProps> = (props) => {
	const {
		max, min, defaultValues = Dimens.TEXT_DEFAULT as string, onChange,
		valueText, minDistance = 0, fontSize, fontColor, label,
	} = props;
	const { colors } = useSettingsContext();
	const [sliderValue, setSliderValue] = React.useState<number[]>([20, 37]);

	const ShortTitle = styled('div')(() => ({
		fontSize,
		fontWeight: 600,
		color: fontColor || colors.textDisabled,
	}));

	useEffect(() => {
		setSliderValue([min, max]);
	}, [defaultValues, max, min]);

	const handleChange = (
		event: Event,
		newValue: number | number[],
		activeThumb: number,
	) => {
		if (!Array.isArray(newValue)) {
			return;
		}
		if (activeThumb === 0) {
			setSliderValue([Math.min(newValue[0], sliderValue[1] - minDistance), sliderValue[1]]);
		} else {
			setSliderValue([sliderValue[0], Math.max(newValue[1], sliderValue[0] + minDistance)]);
		}
	};

	const handleChangeCommitted = (v: number | number[]) => {
		if (!Array.isArray(v)) {
			return;
		}
		onChange?.call(0, v);
	};

	const useStyles = makeStyles({
		track: {
			backgroundColor: colors.highlight,
		},
		thumb: {
			backgroundColor: colors.disabled,
		},
	});

	const classes = useStyles();

	return (
		<Stack direction="column">
			<ShortTitle>{label}</ShortTitle>
			<Stack direction="row" spacing={1} sx={{ mt: 1 }}>
				<Subtitle>{valueText(sliderValue[0])}</Subtitle>
				<Box sx={{ flexGrow: 1 }} />
				<Subtitle>{valueText(sliderValue[1])}</Subtitle>
			</Stack>
			<Slider
				classes={{ track: classes.track, rail: classes.thumb }}
				value={sliderValue}
				min={min}
				max={max}
				onChange={handleChange}
				onChangeCommitted={(event, value) => handleChangeCommitted(value)}
				valueLabelDisplay="off"
				disableSwap
			/>
		</Stack>
	);
};
export default RangeSlider;
