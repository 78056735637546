import {
	Stack, styled, useTheme, Card as MuiCard, Skeleton,
} from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntent, SetupIntentResult, StripeError } from '@stripe/stripe-js';
import Dimens from 'assets/dimens';
import { DefaultButton, DefaultInput, Subtitle } from 'components/atoms';
import { CardItem } from 'components/molecules';
import { Card } from 'data/models';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generateKey } from 'utils/general.utils';

interface ProfileCardsProps {
	handleDelete?: (item: Card) => void;
	loading?: boolean;
	cards: Card[];
	handleLogout: () => void;
	clientSecret?: string;
	setStripeResponse: (parmas: SetupIntent | null) => void;
	setStripeError: (params: StripeError | null) => void;
	handleReservationClick: () => void
}

const ProfileCards: FC<ProfileCardsProps> = (props) => {
	const {
		handleDelete,
		loading,
		cards,
		handleLogout,
		clientSecret,
		setStripeResponse,
		setStripeError,
		handleReservationClick,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const stripe = useStripe();
	const stripeElements = useElements();

	const StyledCard = styled(MuiCard)(() => ({
		border: 'none',
		boxShadow: 'none',
		padding: theme.card.padding,
		borderRadius: theme.card.radius,
	}));

	const cardList = cards.map((cardItem) => (
		<CardItem
			key={generateKey(cardItem.name)}
			name={cardItem.name}
			brand={cardItem.brand}
			last4={cardItem.last4}
			fullExpiry={cardItem.fullExpiry}
			isUsable={cardItem.isUsable}
			country={cardItem.country}
			selected={false}
			deletable
			onClick={() => {
				handleDelete?.call(0, cardItem);
			}}
		/>
	));

	const handleAddNewCard = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!stripe || !stripeElements) {
			return;
		}
		if (clientSecret) {
			const cardElement = stripeElements.getElement(CardElement);
			if (cardElement) {
				stripe
					.confirmCardSetup(clientSecret, {
						payment_method:
						{ card: cardElement, billing_details: { name: 'udfashfui' } },
					})
					.then((res: SetupIntentResult) => {
						if (res.setupIntent) {
							setStripeError(null);
							setStripeResponse(res.setupIntent);
							cardElement.clear();
						} else if (res.error) {
							setStripeError(res.error);
							setStripeResponse(null);
						}
					})
					.catch(({ error }: { error: StripeError }) => {
						setStripeError(error);
					});
			}
		}
	};

	return (
		<>
			<StyledCard>
				<Subtitle
					fontWeight={700}
					sx={{ color: theme.lighter }}
					fontSize={`${Dimens.TEXT_MEDIUM}`}
				>
					{t('myCards')}
				</Subtitle>
				<Stack spacing={1} sx={{ pl: 1, mt: Dimens.LIST_ITEM_MARGIN }}>
					{loading && (
						<>
							<Skeleton variant="rectangular" height={50} width="100%" sx={{ borderRadius: '10px' }} />
							<Skeleton variant="rectangular" height={50} width="100%" sx={{ borderRadius: '10px' }} />
							<Skeleton variant="rectangular" height={50} width="100%" sx={{ borderRadius: '10px' }} />
						</>
					)}
					{(!loading && cards.length > 0) && <Stack spacing={2}>{cardList}</Stack>}
				</Stack>
				<Subtitle
					fontWeight={700}
					sx={{ color: theme.lighter, mt: Dimens.LIST_ITEM_MARGIN }}
					fontSize={`${Dimens.TEXT_MEDIUM}`}
				>
					{t('newcard')}
				</Subtitle>
				<form onSubmit={handleAddNewCard}>
					<Stack sx={{ p: 2 }}>
						<DefaultInput label="Name" hideUnderline />
						<CardElement id="card-element" />
					</Stack>
					<Stack direction="row" justifyContent="flex-end">
						<DefaultButton text={t('addCard')} type="submit" />
					</Stack>
				</form>
			</StyledCard>
			<Stack alignItems="center" sx={{ mt: Dimens.DEFAULT_PADDING }}>
				<DefaultButton
					text="Disconnect"
					onClick={handleLogout}
					variant="text"
					sx={{
						color: theme.palette.error.main,
						p: 0,
						textTransform: 'none',
					}}
				/>
				<DefaultButton
					text="Fazer reserva"
					sx={{
						mt: Dimens.LIST_ITEM_MARGIN,
						bgcolor: theme.palette.title?.color,
						textTransform: 'none',
					}}
					onClick={handleReservationClick}
				/>
			</Stack>
		</>
	);
};

export default ProfileCards;
