/* eslint-disable max-classes-per-file */
export enum UIMode {
    LIGHT = 'light',
    DARK = 'dark'
}

export interface ColorsProps {
    primary: string;
    primaryVariant: string;
    secondary: string;
    secondaryVariant: string;
    textPrimary:string;
    textSecondary:string;
    textDisabled:string;
    highlight:string;
    highlightHover:string;
    disabled:string
    secondBackground:string
    success:string;
    error:string;
    recomended:string;
}

export class Colors {
    static LIGHT = new class implements ColorsProps {
        primary = '#FFFFFF'

        primaryVariant = '#8D92A3'

        secondary = '#223969'

        secondaryVariant = '#223969'

        textPrimary = '#FFFFFF'

        textSecondary = '#223969'

        textDisabled = '#8D92A3'

        highlight = '#FBAD19'

        highlightHover = '#fcb01e'

        disabled = '#CDCDD7'

        secondBackground = '#F7F7F9'

        success = '#80CBC4'

        error = '#E02020'

        recomended = '#80CBC4'
    }()

    static DARK = new class implements ColorsProps {
        primary = '#000000'

        primaryVariant = '#F1F3F8'

        secondary = '#FFFFFF'

        secondaryVariant = '#FFFFFF'

        textPrimary:string = this.secondaryVariant

        textSecondary = '#223969'

        textDisabled = '#8D92A3'

        highlight = '#FBAD19'

        highlightHover = '#fcb01e'

        disabled = '#CDCDD7'

        secondBackground = '#F7F7F9'

        success = '#80CBC4'

        error = '#E02020'

        recomended = '#80CBC4'
    }()
}
