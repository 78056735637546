enum Dimens{

    DEFAULT_PADDING = '24px',
    PADDING_SMALL = '4px',

    // Border Radius
    ICON_BORDER_RADIUS = '3px',

    // Text
    APP_NAME = '18px',
    TEXT_SMALL = '8px',
    TEXT_MEDIUM = '10px',
    FEATURE_TEXT = '12px',
    TEXT_DEFAULT = '12px',
    TEXT_TITLE = '30px',
    TEXT_SUBTITLE = '14px',
    TEXT_NORMAL = '16px',

    // Menu
    LOGO = 40,
    MENU_MARGIN = 8,
    STEP = 34,

    DEFAULT_PANEL_WIDTH = 370,
    HIGHLIGHTED_RADIUS = '30px',
    DEFAULT_RADIUS = '10px',

    CARD_MIN_WIDTH = '260px',

    LIST_ITEM_MARGIN = '15px',
    LIST_ITEM_INNER_PADDING = '18px',

}

export default Dimens;
