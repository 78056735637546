import {
	Container, Divider, Grid, Stack, useTheme, Box, SxProps,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TodayIcon from '@mui/icons-material/Today';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { useTranslation } from 'react-i18next';
import SpecialOfferCard from 'components/molecules/special-offer-card/SpecialOfferCard';
import { Footer, DateRange, SelectCounter } from 'components/organisms';
import { Card, SimpleArticle } from 'components/molecules';
import { useParams } from 'react-router-dom';
import { EndLocation, StartLocation } from 'assets/icons';
import { useSettingsContext } from '../../../../providers';
import {
	Title, Subtitle, FilterButton, ReadonlyLabel, DefaultButton,
} from '../../../atoms';
import mockOffers from '../../../../data/mock/special_offers.json';
import GenericErrorSreen from '../../generic-error/GenericErrorScreen';

const SpecialOfferScreen: React.FC = (): JSX.Element => {
	const { t } = useTranslation('translations');

	const [type, setType] = useState<number>(1);
	// TODO Remove when implement to real request
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [offer, setOffer] = useState<any>();
	const [notFound, setNotFound] = useState<boolean>(false);
	const { id } = useParams<{ id?: string }>();
	const { colors } = useSettingsContext();
	const theme = useTheme();

	useEffect(() => {
		const value = mockOffers.find((item) => item.id === parseInt(id || '0', 10));
		setOffer(value);
		if (!value) {
			setNotFound(true);
		} else {
			setType(value.type);
		}
	}, [id]);

	const containerStyle = {
		background: theme.palette.bgSecondary?.firstColor,
		borderRadius: '10px',
		padding: '24px',
	} as SxProps;

	const articleTitle = {
		color: `${colors.primaryVariant} !important`,
		fontWeight: '700 !important',
	} as SxProps;

	return (
		<Box>
			{notFound ? (
				<GenericErrorSreen />
			) : (
				<Box sx={{ flexGrow: 1 }}>
					<Container maxWidth="lg" sx={containerStyle}>
						<Grid container spacing={3} sx={{ pb: 4 }}>
							<Grid item md={4}>
								<Title>{t('specialOffersForYou')}</Title>
								<Subtitle>{t('completeListOfOffers')}</Subtitle>

								<Stack flexDirection="column" justifyItems="start" alignItems="start">
									<FilterButton text="Ofertas exclusivas" sx={{ mt: { md: 8, xs: 4 } }} disabled={type !== 1} selected={type === 1} />
									<FilterButton text="Ofertas locais" sx={{ mt: 2 }} disabled={type !== 2} selected={type === 2} />
									<FilterButton text="Ganhe pontos e milhas" sx={{ mt: 2 }} disabled={type !== 3} selected={type === 3} />
								</Stack>
							</Grid>
							<Grid item md={4}>
								{offer && (
									<SpecialOfferCard
										image={offer.image}
										title={offer.title}
										subtitle={offer.subtitle}
										email={offer.email}
										phone={offer.phone}
										showDescription
										description={offer.description}
									/>
								)}
							</Grid>
							<Grid item md={4}>
								<Stack direction="column" spacing={2}>
									<Card
										shadows={false}
										content={(
											<>
												<Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
													<Title sx={{ flex: 1 }}>RECEBER OFERTA</Title>

													<Stack direction="column" spacing={2}>
														<ReadonlyLabel icon={TodayIcon} label="Reserva:" value="01 a 14 fev 2020" />
														<ReadonlyLabel icon={InsertInvitationIcon} label="Levantamento:" value="01 a 14 fev 2020" />
													</Stack>
												</Stack>

												<SelectCounter icon={StartLocation} disabled label="LEVANTAMENTO" mt={4} />
												<SelectCounter icon={EndLocation} label="RETORNO" mt={4} />

												<DateRange />

												<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
													<Box sx={{ flexGrow: 1 }} />
													<DefaultButton text="Enviar" minWidth="110px" />
													<Box sx={{ flexGrow: 1 }} />
												</Stack>
											</>
										)}
									/>

									<SimpleArticle title="TERMOS" titleSx={articleTitle}>
										<Subtitle>
											<p>
												{`- O aluguer inclui Quilómetros ilimitados, Cobertura para Danos de Colisão (CDW), Protecção contra Furto (TP),
												Suplemento de Circulação (VLF) e IVA.`}
											</p>
											<p>
												{`- O desconto de 20% incide sobre os custos principais do aluguer, excluindo qualquer suplemento e extra
												opcional - como Wi-Fi, GPS, Condutor Adicional, SuperCover, Seguro para Acidentes Pessoais (PAI), Protecção
												para Vidros e Pneus (G&T), entre outros que serão cobrados a preço de tabela.`}
											</p>
											<p>
												{`- Alugueres sujeitos a disponibilidade de frota e
												válidos em Portugal Continental e Madeira.`}
											</p>
											<p>
												{`- Oferta válida para todos os grupos de viaturas, excluindo viaturas premium dos grupos A6, B6, C6, D6, E6, F6,
												H6, I6, J6, K6, L6, M6, N6, P6 e O6.`}
											</p>
											<p>
												{`- Aplicam-se os Termos e Condições de Aluguer Hertz,
													incluindo as restrições de idade mínima.`}
											</p>
										</Subtitle>
									</SimpleArticle>
								</Stack>
							</Grid>
						</Grid>
					</Container>
					<Footer />
				</Box>
			)}
		</Box>
	);
};

export default SpecialOfferScreen;
