/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import { ApiResponse, Article, ArticleType } from '../../models';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

const fetchArticleTypes = async (): Promise<ApiResponse<ArticleType[]>> => {
	const resp = await reqGet({
		url: Endpoint.ARTICLE_TYPES,
	});
	const data = resp.data as ApiResponse<ArticleType[]>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchArticleTypes = () => useRequest(() => fetchArticleTypes());

const fetchArticle = async (typeID: number): Promise<ApiResponse<Article[]>> => {
	const resp = await reqGet({
		url: `${Endpoint.ARTICLE}?typeId=${typeID}`,
	});
	const data = resp.data as ApiResponse<Article[]>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchArticle = () => useRequest(([typeID]) => fetchArticle(typeID));
