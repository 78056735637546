import {
	Button, IconButton, Popover, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import { Box, SxProps } from '@mui/system';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
	DateRangePicker, LocalizationProvider,
} from '@mui/lab';
import { Dimens } from 'assets';
import useLanguage from 'providers/language.provider';
import { DateComp } from '../../atoms';

interface DateRangeProps {
   mt?: number;
   startTimeAvailable?: string[];
   endTimeAvailable?: string[];
   valueTime?: [string | undefined, string | undefined];
   valueDateRange?: [Date, Date];
   onDateChange?: (start: Date, end: Date) => void;
   onTimeChange?: (start?: string, end?: string) => void;
}

const DateRange: React.FC<DateRangeProps> = ({
	mt,
	startTimeAvailable,
	endTimeAvailable,
	valueTime,
	valueDateRange = [new Date(), new Date().nextDay()],
	onDateChange,
	onTimeChange,
}) => {
	const [open, setOpen] = React.useState(false);
	const [anchorStartEl, setAnchorStartEl] = React.useState<HTMLElement | null>(null);
	const [anchorEndEl, setAnchorEndEl] = React.useState<HTMLElement | null>(null);
	const theme = useTheme();
	const { localeFns } = useLanguage();

	const handleOnDateChange = (values: [Date, Date]) => {
		onDateChange?.call(0, values[0], values[1]);
	};

	const handleStartTimeClick = (time: string) => {
		setAnchorStartEl(null);
		onTimeChange?.call(0, time, valueTime ? valueTime[1] : undefined);
	};

	const handleEndTimeClick = (time: string) => {
		setAnchorEndEl(null);
		onTimeChange?.call(0, valueTime ? valueTime[0] : undefined, time);
	};

	const handleStartClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorStartEl(event.currentTarget);
	};

	const handleEndClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEndEl(event.currentTarget);
	};

	const handleOnStartClose = () => {
		setAnchorStartEl(null);
	};

	const handleOnEndClose = () => {
		setAnchorEndEl(null);
	};

	const styles = {
		paddingLeft: 2,
		paddingRight: 2,
		paddingTop: 1,
		paddingBottom: 1,
		border: 1,
		borderColor: '#F2F2F2',
		borderRadius: Dimens.DEFAULT_RADIUS,
	} as SxProps;

	const startOptions = startTimeAvailable?.map((item) => (
		<Button
			onClick={() => handleStartTimeClick(item)}
			sx={{
				background: theme.palette.listButton?.itemColor,
				color: theme.palette.title?.color,
				boxShadow: 'none !important',
				borderRadius: '0px',
			}}
			variant="contained"
			key={item}
		>
			{item}
		</Button>
	));

	const endOptions = endTimeAvailable?.map((item) => (
		<Button
			onClick={() => handleEndTimeClick(item)}
			sx={{
				background: theme.palette.listButton?.itemColor,
				color: theme.palette.title?.color,
				boxShadow: 'none !important',
				borderRadius: '0px',
			}}
			variant="contained"
			key={item}
		>
			{item}
		</Button>
	));

	const openStart = Boolean(anchorStartEl);
	const openEnd = Boolean(anchorEndEl);

	return (
		<Box sx={{
			mt, ...styles, justifyContent: 'center', display: 'flex',
		}}
		>
			<LocalizationProvider locale={localeFns} dateAdapter={AdapterDateFns}>
				<DateRangePicker
					startText="Check-in"
					endText="Check-out"
					value={valueDateRange}
					minDate={new Date()}
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => {
						setOpen(false);
					}}
					onChange={(newValue) => {
						if (newValue[0]?.isSameOrAfter(newValue[1])) {
							handleOnDateChange([newValue[0], newValue[0].nextDay()]);
						} else {
							handleOnDateChange([newValue[0] || new Date(), newValue[1] || new Date().nextDay()]);
						}
					}}
					renderInput={() => (
						<Stack direction="row" alignItems="center" justifyContent="center">
							<DateComp
								time={valueTime ? valueTime[0] : undefined}
								onDateClick={() => setOpen(true)}
								date={valueDateRange[0]}
								onTimeClick={handleStartClick}
							/>
							<Popover
								open={openStart}
								anchorEl={anchorStartEl}
								onClose={handleOnStartClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
							>
								<Stack direction="column">{startOptions}</Stack>
							</Popover>
							<Box sx={{ flexGrow: 1, ml: 2 }} />
							<Stack direction="column">
								<IconButton disabled sx={{ background: `${theme.palette.primary.main} !important`, width: '30px', height: '30px' }}>
									<DoubleArrowIcon sx={{ color: '#FFFFFF', width: '16px', height: '16px' }} />
								</IconButton>
							</Stack>
							<Box sx={{ flexGrow: 1, ml: 2 }} />
							<DateComp
								time={valueTime ? valueTime[1] : undefined}
								onDateClick={() => setOpen(true)}
								date={valueDateRange[1]}
								onTimeClick={handleEndClick}
							/>
							<Popover
								open={openEnd}
								anchorEl={anchorEndEl}
								onClose={handleOnEndClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
							>
								<Stack direction="column">{endOptions}</Stack>
							</Popover>
						</Stack>
					)}
				/>
			</LocalizationProvider>
		</Box>
	);
};

export default DateRange;
