import React from 'react';
import { styled } from '@mui/material/styles';
import { Lottie } from '@crello/react-lottie';
import { Container, Grid, Stack } from '@mui/material';
import { t } from 'i18next';
import { Footer } from 'components/organisms';
import animationData from '../../../assets/lotties/not-found.json';

interface NotFoundProps {
   message?: string;
   code?: number;
}

const GenericErrorSreen: React.FC<NotFoundProps> = ({
	message, code = 404,
}) => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	const Text = styled('div')(() => ({
		color: 'title.color',
		fontSize: 100,
	}));

	const Subtext = styled('div')(({ theme }) => ({
		color: theme.disabled,
		fontSize: 30,
	}));

	return (
		<div>
			<Container sx={{ mb: 4 }}>
				<Grid container>
					<Grid item sx={{ mt: 2 }} md={12} xs={12}>
						<Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
							<Text>{code}</Text>
							<Lottie style={{ margin: 0 }} config={defaultOptions} height="250px" width="300px" />
						</Stack>
					</Grid>
					<Grid item style={{ textAlign: 'center' }} md={12} xs={12}>
						<Subtext>{message ?? t('pageNotFoundMessage')}</Subtext>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</div>
	);
};

export default GenericErrorSreen;
