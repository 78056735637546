import {
	Box, CardMedia, Divider, Grid, GridSize, Stack, SvgIcon,
} from '@mui/material';
import React from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Dimens } from 'assets';
import { setOnErrorDefaultImage } from 'utils/general.utils';
import { useTranslation } from 'react-i18next';
import { Card } from '..';
import { Title, Subtitle, DefaultButton } from '../../atoms';

interface SpecialOfferCardProps {
    image: string;
    title: string;
    subtitle: string;
    showDescription?: boolean
    description?: string;
    phone?: string;
    email?: string;
    link?: string;
    xs?: boolean | GridSize | undefined
    md?: boolean | GridSize | undefined
    sm?: boolean | GridSize | undefined

}

const SpecialOfferCard: React.FC<SpecialOfferCardProps> = ({
	image, title, subtitle, showDescription, description, phone, email, link, xs, md, sm,
}) => {
	const { t } = useTranslation('translations');
	const content = (
		<div>
			<CardMedia
				component="img"
				alt={title}
				height="220"
				sx={{ zIndex: 1, borderRadius: Dimens.DEFAULT_RADIUS }}
				onError={setOnErrorDefaultImage}
				src={image}
			/>
		</div>
	);

	const footer = (
		<>
			<Stack flexDirection="row">
				<Title sx={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					lineClamp: 1,
					WebkitLineClamp: 1,
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
				}}
				>
					{title}
				</Title>
				<Box sx={{ flexGrow: 1 }} />
				{/* <DefaultButton
                    text='Saber mais'
                    variant='outlined'
                    sx={{color: 'title.color', padding: '8px 5px 8px 5px', borderRadius: '8px'}}
                 /> */}
			</Stack>
			<Subtitle sx={{
				fontSize: Dimens.TEXT_SMALL,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				lineClamp: 4,
				WebkitLineClamp: 4,
				display: '-webkit-box',
				WebkitBoxOrient: 'vertical',
			}}
			>
				{subtitle}

			</Subtitle>
			{link ? (
				<a href={link} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
					<DefaultButton text={t('readMore')} sx={{ mt: 2 }} />
				</a>
			) : <DefaultButton text={t('readMore')} sx={{ mt: 2 }} />}

			{showDescription ? (
				<>
					<Divider orientation="horizontal" sx={{ mt: 2, mb: 2, width: '100%' }} />

					<Subtitle>
						{description}
						<p>{email ? `Reservas: ${email}` : ''}</p>
						<p>
							{phone ? (<SvgIcon component={LocalPhoneIcon} sx={{ color: 'primary.main', width: 22 }} />) : null}
							{' '}
							{phone ? `Tel.: ${phone}` : ''}
						</p>
					</Subtitle>
				</>
			) : null}

		</>
	);

	const body = (
		<Card
			minWidth={Dimens.CARD_MIN_WIDTH as string}
			padding="0px"
			shadows={false}
			content={content}
			footer={footer}
		/>
	);

	return (
		<Grid item xs={xs} md={md} sm={sm}>
			{body}
		</Grid>
	);
};

export default SpecialOfferCard;
