import {
	Container, Divider, Grid, Stack, SvgIcon, Link as MUILink, Box, SxProps, useTheme, Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { styled } from '@mui/material/styles';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { LocationOn, Twitter } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSettingsContext } from 'providers';
import { organizationService, vechicleService } from 'data/api';
import { SocialMedia } from 'data/models/settings.model';
import Dimens from 'assets/dimens';
import { getStorageItem, StorageKeys } from 'utils/storage.utils';
import { fullLogo } from 'assets/images';
import { MuiSvgType } from 'utils/mui.utils';
import useEffectCustom from 'hooks/useEffectCustom';
import MdPhone from '@mui/icons-material/Phone';
import Email from '@mui/icons-material/Email';
import { DefaultButton, Subtitle, Title } from '../../atoms';

interface FooterProps {
	hideCounters?: boolean;
	handleMyReservationClick?: () => void;
	onlyContact?: boolean;
}

const Footer: FC<FooterProps> = (props): JSX.Element => {
	const { hideCounters, handleMyReservationClick, onlyContact } = props;
	const { t } = useTranslation('translations');
	const { colors } = useSettingsContext();
	const theme = useTheme();
	const {
		execute: fetchOrganization,
		data: organizationData,
	} = organizationService.useFetchOrganizations();

	const {
		execute: fetchVehicles,
		data: vehiclesData,
	} = vechicleService.useVehicleCategories();

	const initialSettings = getStorageItem(StorageKeys.INITIAL_SETTINGS);

	useEffectCustom(() => {
		fetchOrganization();
		fetchVehicles();
	});

	const iconStyle = {
		color: colors.textSecondary,
		width: 24,
	} as SxProps;

	const listIconStyle = {
		color: colors.textSecondary,
		width: 14,
	} as SxProps;

	const ShortTitle = styled('div')(() => ({
		color: colors.textDisabled,
		fontSize: Dimens.TEXT_SUBTITLE,
		fontWeight: 500,
	}));

	const counters = organizationData?.map((item) => (
		<Grid key={item.id} item md={4}>
			<Box>
				<Subtitle>{item.fantasyName}</Subtitle>
			</Box>
			{!!item.phone && (
				<ShortTitle sx={{ color: theme.disabled }}>
					(
					{item.phoneCountry?.phoneCode}
					)
					{' '}
					{item.phone}
				</ShortTitle>
			)}
		</Grid>
	));

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const categories = vehiclesData?.slice(0, 22).map((item) => (
		<Grid key={item.id} item md={6}>
			<MUILink href={'/reservation/?category={0}'.format(item.id)} color="inherit" underline="none">
				<Subtitle
					fontSize={Dimens.TEXT_DEFAULT as string}
				>
					{item.description?.toUpperCase()}

				</Subtitle>
			</MUILink>
		</Grid>
	));

	const resolveSocialIcon = (name: string)
	: MuiSvgType => {
		switch (name) {
		case 'facebook':
			return FacebookIcon;
		case 'instagram':
			return InstagramIcon;
		case 'twitter':
			return Twitter;
		case 'youtube':
			return YouTubeIcon;
		default:
			return LinkedInIcon;
		}
	};

	const handleAppLinkClick = () => {
		// Todo use initialSettings app link
		const { userAgent } = window.navigator;
		let platform = 'other';
		if (
			userAgent.toLowerCase().includes('ipad')
         || userAgent.toLowerCase().includes('iphone')
         || userAgent.toLowerCase().includes('ipod')
         || userAgent.toLowerCase().includes('ipad')
         || userAgent.toLowerCase().includes('ios')
		) {
			platform = 'apple';
		} else {
			platform = 'google';
		}

		let i = 0;

		while (i < initialSettings.appStoreLinks.length) { // Why not use "for" instead of "while"
			const el = initialSettings.appStoreLinks[i];
			if (el.name.toLowerCase() === platform) {
				window.open(el.url);
				break;
			}
			i += 1;
		}
	};

	return (
		<section id="contacts" style={{ backgroundColor: theme.palette.bgSecondary?.secondColor }}>
			{/* <Divider orientation="horizontal" sx={{ mb: 10 }} /> */}
			<Container maxWidth="lg" sx={{ position: 'relative', mt: 8 }}>
				{!hideCounters && (
					<Grid container spacing={4}>
						<Grid item md={2}>
							<Title>AJRent.pt</Title>
							<Stack direction="column" sx={{ mt: 2 }} spacing={2}>
								<MUILink href="/reservation/history" color="inherit" underline="none">
									<Subtitle fontSize={Dimens.TEXT_DEFAULT as string}>{t('reservations')}</Subtitle>
									{' '}
								</MUILink>
								<Box onClick={handleAppLinkClick}>
									<Subtitle fontSize={Dimens.TEXT_DEFAULT as string}>App</Subtitle>
								</Box>
								<MUILink href="/?#offers" color="inherit" underline="none">
									<Subtitle fontSize={Dimens.TEXT_DEFAULT as string}>{`${t('offers')} ${t('and')} ${t('promotions')}`}</Subtitle>
								</MUILink>
							</Stack>
						</Grid>

						<Grid item md={10}>
							<Title>{t('counters')}</Title>
							<Grid item md={12} sx={{ mt: 2 }}>
								<Grid container spacing={4}>
									{counters}
								</Grid>
							</Grid>
						</Grid>

						{/* <Grid item md={5}>
						<ShortTitle>{t('categories')}</ShortTitle>
						<Grid item md={12} sx={{ mt: 2 }}>
							<Grid container spacing={4}>
								{categories}
							</Grid>
						</Grid>
					</Grid> */}
					</Grid>
				)}
				{(hideCounters && !onlyContact) && (
					<>
						<Typography sx={{ color: theme.light }} fontWeight={500} fontSize={Dimens.TEXT_NORMAL} textAlign="center">
							{t('reservationCorrectionInfo')}
						</Typography>

						<Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ mt: Dimens.DEFAULT_PADDING }}>
							<DefaultButton sx={{ pl: 6, pr: 6, textTransform: 'none' }} onClick={() => handleMyReservationClick?.call(0)} text={t('manageReservations')} />
						</Stack>
					</>
				)}
				{!onlyContact && <Divider sx={{ mt: 5, width: '100%' }} />}
				<Grid container spacing={4} sx={{ mt: 1, mb: 2 }}>
					<Grid item md={3} sm={12} xs={12}>
						<Stack direction="row" justifyContent={{ sm: 'center', xs: 'center', md: 'flex-start' }}>
							<MUILink href="/" color="inherit" underline="none">
								<img src={fullLogo} alt="logo" width={100} />
							</MUILink>
						</Stack>
					</Grid>

					<Grid
						item
						md={6}
						sm={12}
						xs={12}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
							mb: 1,
						}}
					>
						{/* <ShortTitle>{t('contacts')}</ShortTitle> */}
						<Stack direction={{ md: 'row', sm: 'column', xs: 'column' }} alignItems="center" justifyContent="center">
							<Stack direction="row" alignItems="center">
								<SvgIcon component={MdPhone} sx={listIconStyle} />
								<Subtitle sx={{ ml: 1 }}>
									(
									{initialSettings?.mainOffice?.phoneCountry?.phoneCode}
									)
									{' '}
									{initialSettings?.mainOffice.phone}
									{' '}

								</Subtitle>
							</Stack>
							<Stack direction="row" alignItems="center" sx={{ ml: 2 }}>
								<SvgIcon component={Email} sx={listIconStyle} />
								<Subtitle sx={{ ml: 1 }}>
									{initialSettings?.mainOffice.email}
									{' '}
								</Subtitle>
							</Stack>
						</Stack>
						<Stack direction="row" alignItems="center" justifyContent="center">
							<SvgIcon component={LocationOn} sx={listIconStyle} />
							<Subtitle sx={{ ml: 1 }}>
								{initialSettings?.mainOffice.fullAddress}
							</Subtitle>
						</Stack>
					</Grid>

					{initialSettings?.socialMedia && (
						<Grid item md={3} sm={12} xs={12}>
							<Stack direction="row" spacing={1} alignItems="flex-end" justifyContent={{ sm: 'center', xs: 'center', md: 'flex-end' }} sx={{ mt: 1, mb: 1, height: '100%' }}>
								{initialSettings.socialMedia.map((item: SocialMedia) => (
									<Box key={`social-icon-${item.name}`} onClick={() => window.open(item.webURL)} sx={{ '&:hover': { cursor: 'pointer' } }}>
										<SvgIcon component={resolveSocialIcon(item.name)} sx={iconStyle} />
									</Box>
								))}
							</Stack>
						</Grid>
					)}
				</Grid>
			</Container>
			{/* <MapDialog open={isMapOpen} handleClose={() => setIsMapOpen(false)} /> */}
		</section>
	);
};

export default Footer;
