enum StorageKeys {
    TOKEN = '_AAYTML',
    AUTHENTICATION_TOKEN = '_AATLKS',
    REFRESH_TOKEN = '_AAQNAS',
    USER = '_AADFGO',
    UI_MODE = '_MMERT',
    INITIAL_SETTINGS = '_SS12GH',
    FILTER = '_FFIADJ',
    LANGUAGE = '_LDADDF',
    HASH = '_JHGSFRDFE'
}

export default StorageKeys;
