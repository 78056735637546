import {
	Checkbox, FormControlLabel, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import Dimens from '../../../assets/dimens';

interface CheckItemProps {
    label:string;
    onChange?: (checked:boolean)=>void;
    fontSize?: string;
}

const CheckItem: React.FC<CheckItemProps> = (
	{ label, onChange, fontSize = Dimens.TEXT_DEFAULT },
) => {
	const theme = useTheme();
	const onCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange?.call(0, event.target.checked);
	};

	return (
		<FormControlLabel
			control={(
				<Checkbox
					sx={{ color: theme.disabled }}
					color="success"
					defaultChecked={false}
					onChange={onCheckChange}
				/>
			)}
			label={<Typography sx={{ fontSize, color: theme.disabled }}>{label}</Typography>}
		/>
	);
};

export default CheckItem;
