/* eslint-disable no-nested-ternary */
import {
	Container, Grid, Stack, styled, Typography,
	Skeleton, LinearProgress, useTheme, Card as MUICard, SvgIcon, Divider,
	Box, SxProps,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { useTranslation } from 'react-i18next';
import {
	ReservationStatus, CardOptionalList, CardCarDetail, CounterLabel,
} from 'components/molecules';
import { Footer } from 'components/organisms';
import { useHistory, useParams } from 'react-router-dom';
import useEffectCustom from 'hooks/useEffectCustom';
import {
	BankNote, BankNoteOff, EndLocation, StartLocation,
} from 'assets/icons';
import { Attachment, Check } from '@mui/icons-material';
import { useAuth, useFeedbackContext } from '../../../../../providers';
import { Dimens } from '../../../../../assets';
import {
	LargeButton, PriceInfo, Subtitle, Tag,
} from '../../../../atoms';
import { reservationService } from '../../../../../data/api';
import { GenericErrorSreen } from '../../..';
import {
	convertAndFormatToCurrency,
	formatToCurrency,
	getFullDateFormated,
	openPdfFromBase64,
} from '../../../../../utils/general.utils';
import { AppLinks } from '../../../../routes';

const MyReservationDetail: React.FC = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const { user } = useAuth();
	const { setAlert } = useFeedbackContext();
	const { id } = useParams<{ id?: string }>();
	const history = useHistory();

	const [tab, setTab] = useState(1);
	const [documentsLoading, setDocumentsLoading] = useState<string[]>([]);

	const {
		execute, loading, data, info,
	} = reservationService.useFetchReservationById();

	const useFetchContract = reservationService.useFetchReservationContract();
	const useFetchProof = reservationService.useFetchReservationProof();
	const useFetchServices = reservationService.useFetchService();
	const useFetchInvoice = reservationService.useFetchInvoice();
	const useFetchTolls = reservationService.useFetchTolls();

	useEffectCustom(() => {
		execute(id).then((hasError) => {
			if (!hasError) {
				useFetchServices.execute(id);
				useFetchTolls.execute(id);
			}
		});
	});

	const handleBackClick = () => {
		history.push(AppLinks.MY_RESERVATIONS);
	};

	const handleContractClick = () => {
		if (data?.contract) {
			useFetchContract.execute(id);
		} else {
			useFetchProof.execute(id);
		}
	};

	const handleInvoiceClick = (invoiceID: number | undefined) => {
		if (invoiceID) {
			setDocumentsLoading((prev) => [...prev, `inv-${invoiceID}`]);
			useFetchInvoice.execute(invoiceID).then(() => {
				const arr = [...documentsLoading];
				arr.splice(arr.indexOf(`inv-${invoiceID}`) - 1, 1);
				setDocumentsLoading(arr);
			});
		}
	};

	const handleDocumentsClick = () => {
		history.push(AppLinks.MY_RESERVATION_DOCUMENTS.formatMap({ id }));
	};

	const openDoc = useCallback((base64: string | undefined) => {
		if (base64) {
			openPdfFromBase64(base64);
		} else {
			setAlert({
				message: t('somethingWentWithPDF'),
				type: 'error',
			});
		}
	}, [setAlert, t]);

	useEffect(() => {
		if (useFetchContract.data) {
			openDoc(useFetchContract.data.pdfFileBase64);
		}
	}, [openDoc, useFetchContract.data]);

	useEffect(() => {
		if (useFetchInvoice.data) {
			openDoc(useFetchInvoice.data.pdfFileBase64);
		}
	}, [openDoc, useFetchInvoice.data]);

	useEffect(() => {
		if (useFetchProof.data) {
			openDoc(useFetchProof.data);
		}
	}, [openDoc, useFetchProof.data]);

	const ShortTitle = styled('div')(() => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 600,
		color: theme.disabled,
	}));

	const cardStyle = {
		flex: 1,
		pl: Dimens.DEFAULT_PADDING,
		pr: Dimens.DEFAULT_PADDING,
		pt: 1.5,
		pb: 1.5,
		borderRadius: Dimens.DEFAULT_RADIUS,
		boxShadow: 'none',
	} as SxProps;

	const optionStyle = {
		background: theme.palette.bgSecondary?.firstColor,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: 2,
		p: 1,
	} as SxProps;

	const servicesList = useFetchServices.data?.transactions?.map((item) => (
		<Box
			key={item.fullIdentifier}
			onClick={() => handleInvoiceClick(item.invoiceId)}
			style={{ ...(item.invoiceId ? { cursor: 'pointer' } : {}) }}
		>
			<Box sx={{ ...optionStyle, flexDirection: 'column', mt: 2 }}>
				<Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
					<Stack>
						<Subtitle fontSize={Dimens.TEXT_DEFAULT as string} sx={{ ml: 0.5, fontWeight: '700 !important' }}>
							{item.fullIdentifier}
						</Subtitle>
						<Subtitle fontSize={Dimens.TEXT_DEFAULT as string} sx={{ ml: 0.5, whiteSpace: 'nowrap', color: theme.palette.title?.color }}>
							{' '}
							<strong>
								{formatToCurrency(item.amount)}
							</strong>
						</Subtitle>
					</Stack>
					<Box sx={{ flexGrow: 1 }} />
					<Stack alignItems="end" spacing={0.2}>
						<Typography color="title.color" fontSize={Dimens.TEXT_DEFAULT as string}>
							{item.paymentMethod.name}
						</Typography>
						<Tag sx={{ background: item.status.color }} label={item.status.name} fontSize="10px" />
					</Stack>
					<Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />
					<SvgIcon component={PictureAsPdfRoundedIcon} sx={{ mr: 2, color: item.invoiceId ? 'primary.main' : theme.disabled }} />
				</Stack>
				{documentsLoading.indexOf(`inv-${item.invoiceId}`) !== -1 && (
					<Box sx={{
						width: '95%', mt: 0.5, ml: 1, mr: 1,
					}}
					>
						<LinearProgress color="inherit" />
					</Box>
				)}
			</Box>
		</Box>
	));

	const noBilledList = useFetchServices.data?.noBilled?.map((item) => (
		<Box key={item.name} sx={{ ...optionStyle, mt: 2 }}>
			<Subtitle fontSize={Dimens.TEXT_DEFAULT as string} sx={{ ml: 0.5, fontWeight: '700 !important' }}>
				{item.name}
			</Subtitle>
			<Box sx={{ flexGrow: 1 }} />
			<Subtitle fontSize={Dimens.TEXT_DEFAULT as string} sx={{ ml: 0.5, whiteSpace: 'nowrap', color: 'primary.main' }}>
				{' '}
				<strong>
					€
					{' '}
					{item.itemValue}
				</strong>
			</Subtitle>
		</Box>
	));

	const tollsList = useFetchTolls.data?.map((item) => (
		<Stack
			justifyContent="space-between"
			direction="row"
			alignItems="stretch"
			key={item.id}
			sx={{
				background: theme.palette.bgSecondary?.firstColor,
				borderRadius: 2,
				p: 1,
			}}
		>
			<Stack>
				<Tag label={item.carPlate} color="secondary" fontSize="10px" />
				<Subtitle fontSize={Dimens.TEXT_DEFAULT as string} sx={{ fontWeight: '700 !important', mt: '5px' }}>
					{item.inTollName}
				</Subtitle>
				<Typography color="title.color" fontSize={Dimens.TEXT_DEFAULT as string}>
					{getFullDateFormated(item.inDate)}
					{' '}
				</Typography>
			</Stack>
			<Stack direction="column" justifyContent="space-between" alignItems="end">
				<Tag sx={{ background: item.statusColor }} label={item.status.description} fontSize="10px" />
				<Subtitle fontSize={Dimens.TEXT_SUBTITLE as string} sx={{ ml: 0.5, whiteSpace: 'nowrap', color: 'title.color' }}>
					{' '}
					<strong>
						{convertAndFormatToCurrency(item.fare)}
					</strong>
				</Subtitle>
			</Stack>
		</Stack>
	));

	return (
		<>
			{!!info?.statusCode && info?.statusCode !== 200 ? (
				<GenericErrorSreen code={info?.statusCode} />
			) : loading ? (
				<Container maxWidth="lg">
					<Grid container spacing={2} sx={{ mt: 2, pb: 4 }}>
						<Grid item xs={12} md={12}>
							<Skeleton variant="rectangular" height={40} width="100%" sx={{ borderRadius: '10px' }} />
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Stack spacing={2}>
								<Skeleton variant="rectangular" height={600} width="100%" sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={150} width="100%" sx={{ borderRadius: '10px' }} />
							</Stack>
						</Grid>
						<Grid item xs={12} md={4}>
							<Stack spacing={2}>
								<Skeleton variant="rectangular" height={150} width="100%" sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={400} width="100%" sx={{ borderRadius: '10px' }} />
							</Stack>
						</Grid>
						<Grid item xs={12} md={4}>
							<Stack spacing={2}>
								<Skeleton variant="rectangular" height={40} width="100%" sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={200} width="100%" sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={160} width="100%" sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={100} width="100%" sx={{ borderRadius: '10px' }} />
								<Skeleton variant="rectangular" height={40} width="100%" sx={{ borderRadius: '10px' }} />
							</Stack>
						</Grid>
					</Grid>
				</Container>
			) : (
				<Box sx={{ background: theme.palette.bgSecondary?.firstColor }}>
					<Container maxWidth="lg">
						<Grid container spacing={2} sx={{ mt: 2, pb: 4 }}>
							<Grid item xs={12} md={12}>
								<ReservationStatus
									onBackClick={handleBackClick}
									showBackIcon
									code={`#${data?.id}`}
									status={data?.status?.name}
									statusColor={data?.status?.color}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={4}>
								<Stack spacing={2}>
									<CardCarDetail
										image={data?.category?.image}
										pricePerDay={data?.dailyAmount}
										total={data?.finalAmount}
										shortTitle={t('yourVehicle').toUpperCase()}
										subtitle={data?.category?.name}
										title={data?.category?.cta}
										features={data?.car?.features}
									/>

									<CardOptionalList
										title={t('optionalsAddedByClient')}
										emptyMessage={t('noOptionsAreLinkedToReservation')}
										data={data?.billableItems || []}
									/>
								</Stack>
							</Grid>

							<Grid item xs={12} md={3.5}>
								<Stack spacing={2}>
									<MUICard sx={cardStyle}>
										<CounterLabel
											icon={StartLocation}
											name={data?.returnOrganization?.fantasyName}
											date={data?.startDate}
										/>
										<Divider />
										<CounterLabel
											icon={EndLocation}
											name={data?.returnOrganization?.fantasyName}
											date={data?.endDate}
										/>
									</MUICard>

									<MUICard sx={cardStyle}>
										<ShortTitle sx={{ fontSize: Dimens.TEXT_DEFAULT }}>{t('clientData')}</ShortTitle>

										<Stack sx={{ mt: 1 }}>
											<Typography sx={{ color: 'title.color', fontWeight: 700 }} fontSize={Dimens.TEXT_DEFAULT as string}>
												{t('lessee')}
											</Typography>
											<Typography sx={{ color: 'title.color', fontSize: Dimens.TEXT_DEFAULT }}>{user?.name}</Typography>
										</Stack>

										<Stack sx={{ mt: 1 }}>
											<Typography sx={{ color: 'title.color', fontWeight: 700 }} fontSize={Dimens.TEXT_DEFAULT as string}>
												{t('phone')}
											</Typography>
											<Typography sx={{ fontSize: Dimens.TEXT_DEFAULT, color: 'title.color' }}>
												{user?.phoneCountry?.phoneCode}
												{' '}
												{user?.phone}
											</Typography>
										</Stack>

										<Stack sx={{ mt: 1 }}>
											<Typography sx={{ color: 'title.color', fontWeight: 700 }} fontSize={Dimens.TEXT_DEFAULT as string}>
												{t('address')}
											</Typography>
											<Typography sx={{ fontSize: Dimens.TEXT_DEFAULT, color: 'title.color' }}>
												{user?.address}
												,
												{' '}
												{user?.neighborhood}
												{' '}
											</Typography>
											<Typography sx={{ fontSize: Dimens.TEXT_DEFAULT, color: 'title.color' }}>
												{user?.city?.name}
											</Typography>
										</Stack>
									</MUICard>

									{useFetchTolls.data && useFetchTolls.data?.length > 0 && (
										<MUICard sx={{ ...cardStyle, minHeight: '300px' }}>
											<ShortTitle sx={{ fontSize: Dimens.TEXT_DEFAULT }}>{t('tolls')}</ShortTitle>

											{tollsList}
											{useFetchTolls.loading && (
												<Skeleton variant="rectangular" height={300} width="100%" sx={{ mt: 2, borderRadius: '10px' }} />
											)}
										</MUICard>
									)}
								</Stack>
							</Grid>

							<Grid item xs={12} md={4.5}>
								<Stack spacing={2}>
									<MUICard sx={cardStyle}>
										<Stack direction="row" spacing={1} alignItems="center">
											<LargeButton
												label={data?.contract ? t('contract') : t('proof')}
												icon={PictureAsPdfRoundedIcon}
												loading={useFetchContract.loading || useFetchProof.loading}
												onClick={handleContractClick}
											/>

											<LargeButton label={t('tickets')} icon={Attachment} onClick={handleDocumentsClick} />
										</Stack>
									</MUICard>

									<MUICard sx={{ ...cardStyle, minHeight: '400px' }}>
										<ShortTitle sx={{ fontSize: Dimens.TEXT_DEFAULT }}>{t('serviceCharges')}</ShortTitle>

										<Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
											<LargeButton label={t('billed')} icon={BankNote} onClick={() => setTab(1)} selected={tab === 1} />

											<LargeButton label={t('notBilled')} icon={BankNoteOff} onClick={() => setTab(2)} selected={tab === 2} />
										</Stack>

										{tab === 1 && servicesList}
										{tab === 2 && noBilledList}
										{useFetchServices.loading && (
											<Skeleton variant="rectangular" height={300} width="100%" sx={{ mt: 2, borderRadius: '10px' }} />
										)}
									</MUICard>

									<MUICard sx={cardStyle}>
										<ShortTitle sx={{ fontSize: Dimens.TEXT_DEFAULT }}>{t('offerForYou')}</ShortTitle>

										<Stack direction="row" sx={{ mt: 2 }} alignItems="center" spacing={1}>
											<Check
												fontSize="small"
												sx={{
													color: theme.palette.title?.color,
													bgcolor: theme.palette.bgSecondary?.firstColor,
													borderRadius: Dimens.ICON_BORDER_RADIUS,
													p: Dimens.PADDING_SMALL,
												}}
											/>
											<Typography sx={{ fontSize: Dimens.TEXT_SUBTITLE, color: theme.disabled }}>{t('cancellation')}</Typography>
										</Stack>

										<Stack direction="row" sx={{ mt: 1 }} alignItems="center" spacing={1}>
											<Check
												fontSize="small"
												sx={{
													color: theme.palette.title?.color,
													bgcolor: theme.palette.bgSecondary?.firstColor,
													borderRadius: Dimens.ICON_BORDER_RADIUS,
													p: Dimens.PADDING_SMALL,
												}}
											/>
											<Typography sx={{ fontSize: Dimens.TEXT_SUBTITLE, color: theme.disabled }}>{t('changes')}</Typography>
										</Stack>

										<Stack direction="row" sx={{ mt: 1 }} alignItems="center" spacing={1}>
											<Check
												fontSize="small"
												sx={{
													color: theme.palette.title?.color,
													bgcolor: theme.palette.bgSecondary?.firstColor,
													borderRadius: Dimens.ICON_BORDER_RADIUS,
													p: Dimens.PADDING_SMALL,
												}}
											/>
											<Typography sx={{ fontSize: Dimens.TEXT_SUBTITLE, color: theme.disabled }}>{t('theftProtection')}</Typography>
										</Stack>
									</MUICard>

									<MUICard sx={cardStyle}>
										<Stack direction="row" alignItems="center">
											<ShortTitle sx={{ fontSize: Dimens.TEXT_DEFAULT }}>{t('totalValueOfReservation')}</ShortTitle>

											<Box sx={{ flexGrow: 1 }} />
											<PriceInfo amount={data?.finalAmount} />
										</Stack>
									</MUICard>
								</Stack>
							</Grid>
						</Grid>
					</Container>
				</Box>
			)}
			<Footer />
		</>
	);
};

export default MyReservationDetail;
