import {
	Typography, Stack, useTheme, SxProps, Theme, Box,
} from '@mui/material';
import React from 'react';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
	getDayFromDate,
	getDayName,
	getMonthFormated,
	getTimeFormated,
	getReservationQtdDays,
} from 'utils/general.utils';
import Dimens from 'assets/dimens';
import { Subtitle } from '../../atoms';
import { Card } from '..';

interface CardItineraryInfoProps {
	pickupLocation: string | undefined;
	returnLocation: string | undefined;
	pickupDate: moment.MomentInput;
	returnDate: moment.MomentInput;
	hideModifyBtn?: boolean;
}

const CardItineraryInfo: React.FC<CardItineraryInfoProps> = ({
	pickupLocation,
	returnLocation,
	pickupDate,
	returnDate,
	hideModifyBtn,
}) => {
	const [duration, setDuration] = React.useState<number>();

	const { t } = useTranslation('translations');
	const { disabled, palette } = useTheme();

	React.useEffect(() => {
		const days = getReservationQtdDays(pickupDate, returnDate);
		setDuration(days);
	}, [pickupDate, returnDate]);

	const textStyle = {
		color: disabled,
		fontWeight: 700,
		fontSize: Dimens.TEXT_DEFAULT,
	} as SxProps<Theme>;

	const dayStyle = {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '45px',
		height: '45px',
		background: palette.primary.main,
		borderRadius: 6,
	} as SxProps<Theme>;

	const traceStyle = {
		height: 20,
		width: 2,
		border: `1px dashed ${palette.primary.main}`,
		alignSelf: 'start',
		ml: '10px !important',
	} as SxProps<Theme>;

	return (
		<Card
			shadows={false}
			content={(
				<>
					<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
						<Typography sx={textStyle}>{t('pickupLocation')}</Typography>
						<Box sx={{ flexGrow: 1 }} />
						{!hideModifyBtn && <Typography sx={textStyle}>{t('modify')}</Typography>}
					</Box>

					<Stack direction="row" spacing={2} sx={{ mt: 2, mb: 2 }}>
						<RoomOutlinedIcon sx={{ color: disabled }} />
						<Subtitle>{pickupLocation}</Subtitle>
					</Stack>

					<Stack direction="row" spacing={2} sx={{ mt: 2, mb: 2, ml: 2 }}>
						<Box sx={dayStyle}>
							<Typography fontSize="12px" fontWeight="700" color="#ffffff">
								{getDayFromDate(pickupDate)}
							</Typography>
							<Typography fontSize="10px" fontWeight="700" color="#ffffff">
								{getMonthFormated(pickupDate).toUpperCase()}
							</Typography>
						</Box>
						<Stack direction="column">
							<Subtitle
								fontSize={Dimens.TEXT_DEFAULT as string}
							>
								{getTimeFormated(pickupDate)}

							</Subtitle>
							<Subtitle>{getDayName(pickupDate)}</Subtitle>
						</Stack>
					</Stack>

					<Stack direction="column" sx={{ alignItems: 'start', ml: 3.5 }} spacing={1}>
						<Box sx={{ ...traceStyle }} />
						<Stack direction="row" spacing={4} alignItems="center">
							{/* {carIcon} */}
							<Typography sx={{ ...textStyle, fontSize: Dimens.TEXT_SUBTITLE, fontWeight: 500 }}>
								{duration}
								{' '}
								{!!duration && duration > 1 ? t('days') : t('day')}
							</Typography>
						</Stack>
						<Box sx={{ ...traceStyle }} />
					</Stack>

					<Stack direction="row" spacing={2} sx={{ mt: 2, mb: 2, ml: 2 }}>
						<Box sx={dayStyle}>
							<Typography fontSize="12px" fontWeight="700" color="#ffffff">
								{getDayFromDate(returnDate)}
							</Typography>
							<Typography fontSize="10px" fontWeight="700" color="#ffffff">
								{getMonthFormated(returnDate).toUpperCase()}
							</Typography>
						</Box>
						<Stack direction="column">
							<Subtitle
								fontSize={Dimens.TEXT_DEFAULT as string}
							>
								{getTimeFormated(returnDate)}

							</Subtitle>
							<Subtitle>{getDayName(returnDate)}</Subtitle>
						</Stack>
					</Stack>

					<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
						<Typography sx={textStyle}>{t('returnLocation')}</Typography>
					</Box>

					<Stack direction="row" spacing={2} sx={{ mt: 2, mb: 2 }}>
						<RoomOutlinedIcon sx={{ color: disabled }} />
						<Subtitle>{returnLocation}</Subtitle>
					</Stack>
				</>
			)}
		/>
	);
};
export default CardItineraryInfo;
