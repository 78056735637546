import { Close } from '@mui/icons-material';
import {
	Box, Button, Dialog, DialogContent, DialogTitle, Stack, Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { SuccessCar } from 'assets/images';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ReservationSuccessProps {
	open: boolean;
	onClose: () => void
}

const ReservationSuccessDialog:FC<ReservationSuccessProps> = (props) => {
	const { open, onClose } = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="reservation-success-dialog-title"
			aria-describedby="reservation-success-dialog-description"
		>
			<DialogTitle sx={{ pr: 0, pt: 3, pb: 0 }}>
				<Stack direction="row" justifyContent="flex-end">
					<Box sx={{ flexGrow: 1 }} />
					<Button onClick={onClose} autoFocus color="inherit" disableRipple sx={{ p: 0 }}>
						<Stack alignItems="center">
							<Close />
							<Typography sx={{ fontSize: Dimens.TEXT_SMALL }}>
								Esc
							</Typography>
						</Stack>
					</Button>
				</Stack>
			</DialogTitle>
			<DialogContent sx={{ pl: '64px', pr: '64px', pb: '47px' }}>
				<Box sx={{ width: '300px' }}>
					<SuccessCar />
					<Typography sx={{
						color: 'primary.main',
						fontSize: Dimens.TEXT_SUBTITLE,
						fontWeight: 'bold',
						textAlign: 'center',
						pt: 4,
					}}
					>
						{t('reservationSuccessTitle')}
					</Typography>
					<Typography sx={{
						color: 'title.color',
						fontSize: Dimens.TEXT_DEFAULT,
						fontWeight: '500',
						textAlign: 'center',
						pt: 1,
					}}
					>
						{t('reservationSuccessMessage')}
					</Typography>
					<Typography sx={{
						color: theme.light,
						fontSize: Dimens.TEXT_MEDIUM,
						fontWeight: '500',
						textAlign: 'center',
						pt: 1,
					}}
					>
						{t('reservationSuccessSubtitle')}
					</Typography>
				</Box>
			</DialogContent>

		</Dialog>
	);
};

export default ReservationSuccessDialog;
