import { BrowserRouter as Router } from 'react-router-dom';
import {
	Box, PaletteMode, Theme, useMediaQuery,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {} from './utils/extensions/string.extensions';
import {} from './utils/extensions/date.extensions';
import 'moment/locale/pt';
import React, { useEffect } from 'react';
import moment from 'moment';
import useLanguage, { LanguageProvider } from 'providers/language.provider';
import {
	AuthProvider, CacheProvider, FeedbackProvider, SettingsProvider,
} from './providers';
import './index.css';
import {} from './assets/theme/theme-ui';
import { getDesignTokens } from './utils/theme.utils';
import { LoadingMessage } from './components/hocs/splashscreen/withSplashScreen';
import { getStorageItem, setStorageItem, StorageKeys } from './data/storage';
import Main from './Main';
import './assets/localization';
import { ScrollToTopButton } from './components/atoms';

const App = (): React.ReactElement => {
	const [theme, setTheme] = React.useState<Theme>();
	const [mode, setMode] = React.useState<PaletteMode>();
	const { locale } = useLanguage();

	const toggleColorMode = () => {
		setMode((prevMode) => {
			const nMode = prevMode === 'light' ? 'dark' : 'light';
			setStorageItem(StorageKeys.UI_MODE, nMode);
			return nMode;
		});
	};

	const isPrefsDark = useMediaQuery('(prefers-color-scheme: dark)');

	useEffect(() => {
		moment().locale('pt');
	}, []);

	useEffect(() => {
		let initialMode = getStorageItem(StorageKeys.UI_MODE);
		if (!initialMode) {
			// if is not set
			initialMode = isPrefsDark ? 'dark' : 'light';
		}
		setMode(initialMode);
	}, [isPrefsDark]);

	useEffect(() => {
		if (mode) {
			getDesignTokens(mode).then((th) => setTheme(
				createTheme({
					...th,
					locale,
				}),
			));
		}
	}, [mode, locale]);

	return (
		<Box>
			{!theme ? (
				<LoadingMessage />
			) : (
				<Router>
					<FeedbackProvider>
						<CacheProvider>
							<SettingsProvider updateUIMode={toggleColorMode}>
								<AuthProvider>
									<ThemeProvider theme={theme}>
										<LanguageProvider>
											<Main />
											<ScrollToTopButton />
										</LanguageProvider>
									</ThemeProvider>
								</AuthProvider>
							</SettingsProvider>
						</CacheProvider>
					</FeedbackProvider>
				</Router>
			)}
		</Box>
	);
};

export default App;
