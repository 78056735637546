/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useEffectCustom from 'hooks/useEffectCustom';
import React, {
	createContext, FC, useContext, useMemo, useState,
} from 'react';
import { getFingerprint } from 'utils/general.utils';
import { Colors, ColorsProps } from '../assets';
import { settingsService } from '../data/api';
import { Currency, InitialSettings } from '../data/models';
import { getStorageItem, setStorageItem, StorageKeys } from '../data/storage';

export type SettingsContextProps = {
    colors: ColorsProps;
    updateUIMode: () => void;
    getInitialRequest: () => Promise<boolean>;
    currency: Currency | undefined;
    setCurrency: (currency: Currency | undefined) => void;
	uniqueHash: string
};

export const SettingsContext = createContext<SettingsContextProps>({} as SettingsContextProps);

const setIntitalSettings = (settings?: InitialSettings) => {
	setStorageItem(StorageKeys.TOKEN, settings?.sessionToken);
	const initialSettings = { ...settings };
	delete initialSettings.sessionToken;
	setStorageItem(StorageKeys.INITIAL_SETTINGS, initialSettings);
};

interface SettingsProviderProps {
   updateUIMode: () => void;
}

export const SettingsProvider: FC<SettingsProviderProps> = ({ children, updateUIMode }) => {
	const [currency, setSystemCurrency] = useState<Currency>();
	const [uniqueHash, setUniqueHash] = useState<string>('');

	const getHash = () => {
		const mHash: string|null = getStorageItem(StorageKeys.HASH);
		if (mHash) {
			setUniqueHash(mHash);
			return mHash;
		}
		const genHash = getFingerprint();
		setUniqueHash(genHash.toString());
		setStorageItem(StorageKeys.HASH, genHash);
		return genHash;
	};

	useEffectCustom(() => {
		getHash();
	}, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getInitialRequest = async () => {
		try {
			const hash = getHash();
			const settings = await settingsService.initialSettings(hash);
			setIntitalSettings(settings.data);
			return true;
		} catch (err) {
			return false;
		}
	};

	const setCurrency = (c: Currency | undefined) => {
		setSystemCurrency(c);
	};

	const value = useMemo(
		() => ({
			colors: Colors.LIGHT,
			updateUIMode,
			getInitialRequest,
			currency,
			setCurrency,
			uniqueHash,
		}),
		[currency, updateUIMode, uniqueHash, getInitialRequest],
	);

	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export default function useSettingsContext() {
	return useContext(SettingsContext);
}
