import {
	Divider, Grid, Stack, Theme, Typography, Box, SxProps,
} from '@mui/material';
import { Dimens } from 'assets';
import { AppLinks } from 'components/routes';
import { useAuth } from 'providers';
import { Filter, User } from 'data/models';
import { getStorageItem, StorageKeys, setStorageItem } from 'data/storage';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LoginForm, AccountForm, ItineraryForm } from '..';
import { DefaultCard } from '../../atoms';
import { LoginAction } from '../login-form/LoginForm';

const MainPanel = (): JSX.Element => {
	const { t } = useTranslation('translations');
	const [tab, setTab] = useState(1);

	const { login, createAccount } = useAuth();
	const history = useHistory();

	const titleStyle = {
		textAlign: 'center|justify',
		fontWeight: 700,
		fontSize: Dimens.APP_NAME,
		cursor: 'pointer',
	} as SxProps;

	const handleMyReservationClick = () => {
		const user = getStorageItem(StorageKeys.USER);
		if (user) {
			history.push(AppLinks.MY_RESERVATIONS);
			return;
		}
		setTab(2);
	};

	const handleLoginClick = (action: LoginAction, email?: string, password?: string) => {
		switch (action) {
		case 'create-account':
			setTab(3);
			break;
		case 'login':
			login(email || '', password || '').then((error) => {
				if (!error) {
					// if is not null, undefined or false
					history.push(AppLinks.MY_RESERVATIONS);
				}
			});
			break;
		default:
			break;
		}
	};

	const handleItineraryClick = (filter: Filter) => {
		setTab(2);
		setStorageItem(StorageKeys.FILTER, filter);
		history.push('/reservation');
	};

	const handleCreateAccountClick = (user: User, password: string, code: string) => {
		createAccount(user, password, code).then((isSuccess) => {
			if (!isSuccess) {
				// if is not null, undefined or false
				history.push(AppLinks.MY_RESERVATIONS);
			}
		});
	};

	const getTabColor = (theme: Theme, n1: number, n2?: number): string => {
		if (tab === n2) return theme.palette.title?.color || '';

		if (tab === n1) {
			return theme.palette.title?.color || '';
		}
		return theme.palette.text.disabled;
	};

	const getContentTab = () => {
		switch (tab) {
		case 2:
			return <LoginForm onClick={handleLoginClick} />;
		case 3:
			return <AccountForm onClick={handleCreateAccountClick} />;
		default:
			return <ItineraryForm onClick={handleItineraryClick} />;
		}
	};

	return (
		<DefaultCard xs={12} sm={6} md={12} highlighted>
			<Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
				<Box
					onClick={() => {
						setTab(1);
					}}
				>
					<Typography
						sx={{
							...titleStyle,
							color: (theme) => getTabColor(theme, 1),
							width: 130,
						}}
					>
						{t('rentACar')}
					</Typography>
				</Box>

				<Box
					sx={{ width: 130 }}
					onClick={() => {
						handleMyReservationClick();
					}}
				>
					<Typography
						sx={{
							...titleStyle,
							color: (theme) => getTabColor(theme, 2, 3),
							textAlign: 'right',
						}}
					>
						{t('myReservations')}
					</Typography>
				</Box>
			</Stack>
			<Grid flexDirection="column" justifyItems="center" sx={{ padding: 1 }}>
				{getContentTab()}
			</Grid>
		</DefaultCard>
	);
};

export default MainPanel;
