import React, { useEffect } from 'react';

import {
	ListItemText, Stack, Box, SxProps,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { CurrencyData, Currency } from 'data/models';
import { ListButton } from '../../atoms';

interface CurrencySelectProps {
    data?: CurrencyData,
    onChange?: (value: Currency | undefined) => void
}

const CurrencySelect: React.FC<CurrencySelectProps> = ({
	data,
	onChange,
}) => {
	const [selectedValue, setSelectedValue] = React.useState<Currency>();
	const itemStyle = {
		fontSize: Dimens.TEXT_DEFAULT,
	} as SxProps;

	useEffect(() => {
		if (data && !selectedValue) {
			setSelectedValue(data.base);
		}
	}, [data, selectedValue]);

	const handleChange = (item?: Currency) => {
		setSelectedValue(item);
		onChange?.call(0, item);
	};

	return (
		<Box>

			<ListButton
				data={[...(data?.base ? [data?.base] : []), ...(data?.rates || [])]}
				defaultValue={selectedValue}
				onChange={handleChange}
				renderValue={(item) => (selectedValue ? `${item?.symbol} ${item?.iso}` : '- --')}
				sx={{ minWidth: '80px' }}
				renderOption={(item) => (
					<Stack direction="row" spacing={2}>
						<ListItemText primaryTypographyProps={{ sx: itemStyle }} primary={`${item.iso} (${item.symbol})`} />
					</Stack>
				)}
			/>
		</Box>
	);
};

export default CurrencySelect;
