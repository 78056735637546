import { Stack, SvgIcon, SvgIconTypeMap } from '@mui/material';
import React from 'react';
import { styled, SxProps } from '@mui/material/styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CheckCircle } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Dimens } from '../../../assets';
import { useSettingsContext } from '../../../providers';

interface CheckedLabelProps {
    label?: string;
    fontSize?: string;
    checked?: boolean;
	sx?: SxProps;
	icon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {muiName: string;}
}

const CheckedLabel: React.FC<CheckedLabelProps> = ({
	label,
	fontSize = Dimens.FEATURE_TEXT,
	checked = true,
	sx,
	icon,
}) => {
	const { colors } = useSettingsContext();
	const Label = styled('div')(() => ({
		fontSize,
		fontWeight: 500,
		color: colors.primaryVariant,
	}));

	return (
		<Stack direction="row" spacing={0.5} alignItems="center" justifyContent="center" sx={{ ...sx }}>
			<SvgIcon color={checked ? 'success' : 'error'} component={checked ? icon ?? CheckCircle : HighlightOffIcon} sx={{ width: 15 }} />
			<Label>{label}</Label>
		</Stack>
	);
};

export default CheckedLabel;
