import {
	Box, Container, Grid, IconButton, Stack, Typography, useTheme,
} from '@mui/material';
import { SetupIntent, StripeError } from '@stripe/stripe-js';
import Dimens from 'assets/dimens';
import {
	Title,
} from 'components/atoms';
import { ConfirmDialog } from 'components/molecules';
import { Footer, ProfileCards, UserDetails } from 'components/organisms';
import { AppLinks } from 'components/routes';
import { cardService, userService } from 'data/api';
import { ApiResponseBase, Card } from 'data/models';
import useEffectCustom from 'hooks/useEffectCustom';
import { useAuth, useFeedbackContext } from 'providers';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const ProfileScreen = (): React.ReactElement => {
	const { user, logout } = useAuth();
	const { setAlert } = useFeedbackContext();
	const history = useHistory();
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const useFetchCards = cardService.useFetchPaymentMethods();
	const useDeleteCard = cardService.useDeletePaymentMethod();
	const useInitiatePaymentIntent = cardService.useInitiatePaymentIntent();
	const useTerminateAccount = userService.useTerminateAccount();
	const useChangePassword = userService.useChangePassword();

	const [stripeError, setStripeError] = useState<StripeError | null>(null);
	const [stripeResponse, setStripeResponse] = useState<SetupIntent | null>(null);
	const [cardToDelete, setCardToDelete] = useState<string | null>(null);
	const [confirmDialog, setConfirmDialog] = useState<boolean>(false);

	useEffectCustom(() => {
		useFetchCards.execute();
	}, []);

	const handleError = useCallback((info: ApiResponseBase) => {
		if (info.statusCode === 200) {
			if (cardToDelete) {
				useFetchCards.setData((prev) => {
					const mPrev = prev;
					const index = useFetchCards.data?.findIndex((el) => el.id === cardToDelete);
					if (index !== undefined) {
						mPrev?.splice(index, 1);
					}
					return mPrev;
				});
			}
		} else {
			setAlert({
				message: info.message,
				type: info.statusCode !== 200 ? 'error' : 'success',
				duration: 3000,
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setAlert]);

	const checkResponse = () => {
		if (stripeError) {
			setAlert({ message: stripeError.message || '', type: 'error', duration: 3000 });
		} else if (stripeResponse) {
			useFetchCards.execute();
			setAlert({ message: stripeResponse.status, type: 'success', duration: 3000 });
		}
	};

	useEffectCustom(() => {
		checkResponse();
	}, [stripeResponse, stripeError]);

	useEffectCustom(() => {
		useInitiatePaymentIntent.execute();
	}, []);

	useEffectCustom(() => {
		if (useDeleteCard.info) {
			handleError(useDeleteCard.info);
		}
	}, [useDeleteCard.info]);

	const handleCardDelete = (card: Card) => {
		setCardToDelete(card.id);
		useDeleteCard.execute(card.id, user?.userId);
	};

	const handleLogoutClick = () => {
		logout();
	};

	const handleReservationClick = () => {
		history.push(AppLinks.HOME);
	};

	const handleDeleteClick = () => {
		setConfirmDialog(true);
	};

	const closeConfirm = () => {
		setConfirmDialog(false);
	};

	const handleConfirmClick = () => {
		useTerminateAccount.execute();
	};

	const handleChangePassword = (currentPassword: string, newPassword: string) => {
		useChangePassword.execute(currentPassword, newPassword);
	};

	return (
		<Box sx={{ bgcolor: 'bgSecondary.firstColor' }}>
			<Container maxWidth="lg" sx={{ pt: 8, pb: 8, minHeight: '60vh' }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Title>{t('myProfile')}</Title>
					<IconButton onClick={handleDeleteClick} disableRipple sx={{ p: 0 }}>
						<Typography
							fontWeight={500}
							sx={{
								color: theme.palette.error.main,
							}}
							fontSize={`${Dimens.TEXT_MEDIUM}`}
						>
							Delete Account
						</Typography>
					</IconButton>
				</Stack>
				<Grid container spacing={3}>
					<Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
						<UserDetails confirmPasswordChange={handleChangePassword} />
					</Grid>
					<Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
						<ProfileCards
							loading={useFetchCards.loading}
							cards={useFetchCards.data || []}
							handleDelete={handleCardDelete}
							handleLogout={handleLogoutClick}
							clientSecret={useInitiatePaymentIntent.data?.clientSecret}
							setStripeError={setStripeError}
							setStripeResponse={setStripeResponse}
							handleReservationClick={handleReservationClick}
						/>
					</Grid>
				</Grid>
			</Container>
			<ConfirmDialog
				open={confirmDialog}
				title={t('wantToLeave')}
				message={t('clickToDelete')}
				confirmBtnText={t('confirm')}
				onConfirm={handleConfirmClick}
				onClose={closeConfirm}
			/>
			<Footer />
		</Box>
	);
};

export default ProfileScreen;
