/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import GroupIcon from '@mui/icons-material/Group';
import DangerousIcon from '@mui/icons-material/Dangerous';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import moment from 'moment';
import Endpoint from 'data/api/endpoints.api';
import { SyntheticEvent } from 'react';
import { imagePlaceholder } from 'assets/images';
import { Task } from '@mui/icons-material';
import {
	BillableItem, CampaignDiscount, CategoryData, Currency, QuantityDiscount,
} from '../data/models';

export const getHashCode = (src: string) => {
	let hash = 0;
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < src.length; i++) {
		const char = src.charCodeAt(i);
		// eslint-disable-next-line no-bitwise
		hash = ((hash << 5) - hash) + char;
		// eslint-disable-next-line no-bitwise
		hash &= hash;
	}
	return hash;
};

export const getFingerprint = (): number => {
	const canvas = document.getElementById('myCanvas') as HTMLCanvasElement;
	if (!canvas) return getHashCode(`_${Math.random().toString(36).slice(2, 9)}`);
	const ctx = canvas.getContext('2d');
	if (!ctx) return getHashCode(`_${Math.random().toString(36).slice(2, 9)}`);

	ctx.fillStyle = 'rgb(255,0,255)';
	ctx.beginPath();
	ctx.rect(20, 20, 150, 100);
	ctx.fill();
	ctx.stroke();
	ctx.closePath();
	ctx.beginPath();
	ctx.fillStyle = 'rgb(0,255,255)';
	ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
	ctx.fill();
	ctx.stroke();
	ctx.closePath();

	const txt = 'abz190#$%^@£éú';
	ctx.textBaseline = 'top';
	ctx.font = '17px "Arial 17"';
	ctx.textBaseline = 'alphabetic';
	ctx.fillStyle = 'rgb(255,5,5)';
	ctx.rotate(0.03);
	ctx.fillText(txt, 4, 17);
	ctx.fillStyle = 'rgb(155,255,5)';
	ctx.shadowBlur = 8;
	ctx.shadowColor = 'red';
	ctx.fillRect(20, 12, 100, 5);

	// hashing function
	const src = canvas.toDataURL();
	return getHashCode(src);
};

// eslint-disable-next-line no-promise-executor-return
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getCountryFlagURL = (shortName: string) => Endpoint.COUNTRY_ICONS_URL
	.format(shortName);

export const getFeatureIcon = (id: number) => {
	switch (id) {
	case 1:
		return AirlineSeatReclineExtraIcon;
	case 14:
		return DangerousIcon;
	case 26:
		return ElectricCarIcon;
	case 2:
		return HdrAutoIcon;
	default:
		return Task;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (val: any) => {
	if (val === null) { return false; }
	return ((typeof val === 'function') || (typeof val === 'object'));
};

// DATE UTILS====================================================

export const diffDate = (date1: moment.MomentInput, date2: moment.MomentInput) => {
	const val1 = moment(date1);
	const val2 = moment(date2);
	const duration = moment.duration(val2.diff(val1));
	return duration.asDays();
};

export const getDateFormated = (date: moment.MomentInput) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');

export const getDayName = (date: moment.MomentInput) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('dddd');

export const getDayShortName = (date: moment.MomentInput) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('ddd');

export const getDayFromDate = (date: moment.MomentInput) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD');

export const getTimeFormated = (date: moment.MomentInput) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH[h]mm[m]');

export const getMonthFormated = (date: moment.MomentInput) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('MMM');

export const getFullDateFormated = (date: moment.MomentInput) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('ddd[,] DD[/]MMM [-] HH:mm[h]');
export const getTimeFromDate = (date: moment.MomentInput) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm[h]');

export const dateToString = (date: moment.MomentInput, time?: string | null) => {
	const mm = moment(date, 'YYYY-MM-DD HH:mm:ss');
	if (time) {
		return `${mm.format('YYYY-MM-DD')} ${time}`;
	}
	return mm.format('YYYY-MM-DD HH:mm:ss');
};

/**
 * @param date Date
 * @param hours string[] pattern 'HH:mm'
 * @returns string[] pattern 'HH:mm'
 */
export const filterHoursAfter = (date: Date, hours: string[]): string[] => {
	const today = new Date();
	if (getDateFormated(date) !== getDateFormated(today)) {
		return hours;
	}

	// regex to check if the hour is correct
	// pattern HH:mm
	const hourRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;

	return hours.filter((item) => {
		if (hourRegex.test(item)) {
			const momentDate = moment(today);
			const cHour = momentDate.hour();
			const cMinute = momentDate.minute();
			const [hh, mm] = item.split(':');
			return parseInt(hh, 10) > cHour || (parseInt(hh, 10) >= cHour && parseInt(mm, 10) > cMinute);
		}
		return false;
	});
};

//= ===============================================================

export const getRandomArbitrary = (min: number, max: number) => Math.random() * (max - min) + min;

export const isDev = process.env.NODE_ENV === 'development';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const log = (tag: string, ...args: any) => {
	if (isDev) {
		// eslint-disable-next-line no-console
		console.log('[INN]', `[${tag}]`, ...args);
	}
};

// Validations >======================
export const validateEmail = (email: string): boolean => String(email)
	.toLowerCase()
	.match(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	) !== null;

export type PasswordValidation = 'length' | 'letter' | 'number' | 'special-character';
export const validatePassword = (password: string) => {
	const tips: PasswordValidation[] = [];
	if (password.length < 8) {
		tips.push('length');
	}

	if (!(/[a-zA-Z]/.test(password))) {
		tips.push('letter');
	}

	if (!(/[0-9]/.test(password))) {
		tips.push('number');
	}

	if (!(/[^a-zA-Z0-9]/.test(password))) {
		tips.push('special-character');
	}
	return tips;
};

// Open new Tab with contect >========================
export const openPdfFromBase64 = (base64: string | undefined) => {
	if (base64) {
		const pdfWindow = window?.open('');
		pdfWindow?.document.write(
			`<iframe width='100%' height='100%' style='' src='data:application/pdf;base64, ${
				encodeURI(base64)}'></iframe>`,
		);
		const body = pdfWindow?.document.body;
		if (body) body.style.margin = '0px';
	}
};

// (Category / Reservation / Currency) Utils >====================================

export const getReservationQtdDays = (date1: moment.MomentInput, date2: moment.MomentInput) => {
	const diffDays = diffDate(date1, date2);
	const initVal = parseInt(diffDays.toString(), 10);
	const rest = parseFloat((diffDays % 1).toFixed(2));
	if (rest >= 0.4) {
		return initVal + 1;
	}
	return initVal;
};

export const getPriceWithVAT = (price: number | undefined) => {
	const value = (price || 0) * 1.23;
	return value < 0 ? 0.0 : value;
};

export const convertAndFormatToCurrency = (amount: number, currency?: Currency) => {
	const money = new Intl.NumberFormat(currency ? currency.countryShortName : 'pt-PT', {
		style: 'currency',
		currency: currency ? currency.iso : 'EUR',
	});
	if (currency) {
		const nVal = amount * (currency.rate || 1);
		return money.format(nVal);
	}
	return money.format(amount);
};

export const getPriceFormatWithVAT = (
	price: number | undefined,
	currency?: Currency,
) => convertAndFormatToCurrency(getPriceWithVAT(price), currency);

export const formatToCurrency = (amount: number, currency?: Currency) => {
	const money = new Intl.NumberFormat(currency ? currency.countryShortName : 'pt-PT', {
		style: 'currency',
		currency: currency ? currency.iso : 'EUR',
	});
	return money.format(amount);
};

export const setBillableItemDiscounts = (
	numberOfDays: number,
	categoryID: number,
	items: BillableItem[],
	itemsDiscount: QuantityDiscount[],
	campaigns: CampaignDiscount[],
) => {
	const discounts = itemsDiscount.filter(
		(item) => (item.minAmount <= numberOfDays || item.minAmount === 1)
			&& item.categoryId === categoryID,
	);
	discounts.sort((a, b) => b.minAmount - a.minAmount);
	const mCampaign = campaigns.find(Boolean); // first or null
	items.forEach((mElem) => {
		const elem = mElem;
		elem.itemDiscount = discounts.find((e) => e.itemId === elem.id);
		elem.campaignDiscount = undefined;
		if (!!mCampaign && mCampaign.itemId === elem.id
			&& mCampaign.categories.indexOf(categoryID) !== -1) {
			elem.campaignDiscount = mCampaign;
		}
	});
};

export const getOptionalValueWithDiscount = (billableItem: BillableItem, currency?: Currency) => {
	const item = { ...billableItem }; // clone to avoid modify the main obj
	let { value } = item;

	if (item.campaignDiscount) {
		const campaign = item.campaignDiscount;
		value -= campaign.isPercentage ? value * (campaign.discount / 100) : campaign.discount;
	}

	if (item.itemDiscount) {
		const { itemDiscount } = item;
		value -= itemDiscount.isPercentage
			? value * (itemDiscount.discount / 100)
			: itemDiscount.discount;
	}

	value -= billableItem.discount || 0;
	value -= billableItem.quantityDiscount || 0;

	if (currency) {
		value *= currency.rate;
	}
	return getPriceWithVAT(value);
};

export const getPricePerDayWithDiscount = (
	numberOfDays: number,
	pricePerDay?: number,
	categoryID?:number,
	itemsDiscount?: QuantityDiscount[],
	campaigns?: CampaignDiscount[],
	withVAT?:boolean,
): number => {
	if (!pricePerDay || !categoryID) {
		return 0;
	}
	let value = pricePerDay;
	const campaingsList = campaigns || [];
	const itemsDiscountList = itemsDiscount || [];
	const DAILY = 8;
	const mCampaign = campaingsList.find(Boolean); // first or null
	const discounts = itemsDiscountList.filter(
		(item) => item.minAmount <= numberOfDays
					&& item.itemId === DAILY && item.categoryId === categoryID,
	);
	discounts.sort((a, b) => b.minAmount - a.minAmount);
	const discount = discounts.find(Boolean);

	if (mCampaign && mCampaign.itemId === DAILY && mCampaign.categories.indexOf(categoryID) !== -1) {
		value -= mCampaign.isPercentage ? value * (mCampaign.discount / 100) : mCampaign.discount;
	}

	if (!discount) {
		return withVAT ? getPriceWithVAT(value) : value;
	}

	value -= discount.isPercentage ? value * (discount.discount / 100) : discount.discount;
	return withVAT ? getPriceWithVAT(value) : value;
};

export const getReservationFinalValue = (
	numberOfDays: number,
	pricePerDay: number,
	billableItems: BillableItem[],
	currency?: Currency,
) => {
	let finalValue = getPriceWithVAT(pricePerDay) * numberOfDays;
	billableItems.forEach((elem) => {
		finalValue += getOptionalValueWithDiscount(elem, currency);
	});
	return finalValue;
};

export const generateKey = (pre: unknown) => `${pre}_${Math.random().toString(36).slice(2, 9)}`;

export const getImagePlaceHolder = () => imagePlaceholder;

export const setOnErrorDefaultImage = (event: SyntheticEvent<HTMLImageElement>) => {
	const target = event.currentTarget;
	target.src = imagePlaceholder;
};

export const setCategoriesByPrice = (
	setData: (value: React.SetStateAction<CategoryData | undefined>) => void,
	orderBy: number,
) => {
	setData((prev) => {
		const mPrev = prev;
		if (mPrev?.categories) {
			const sorted = prev?.categories.sort((a, b) => {
				let order = 0;
				if (a.pricePerDay && b.pricePerDay) {
					if (orderBy === 1) {
						order = a.pricePerDay - b.pricePerDay;
					} else {
						order = b.pricePerDay - a.pricePerDay;
					}
				}
				return order;
			});
			if (sorted) {
				mPrev.categories = sorted;
			}
		}
		return mPrev;
	});
};
