import {
	Divider, Stack, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import { Box, SxProps } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Dimens } from 'assets';
import { getDayFromDate, getDayShortName, getMonthFormated } from '../../../utils/general.utils';

interface DateCompProps {
    mt?: number;
    date?: Date | null;
    time?: string | null;
    onDateClick?: ()=>void;
    onTimeClick?: (event: React.MouseEvent<HTMLDivElement>)=>void;
}

const DateComp: React.FC<DateCompProps> = ({
	mt = 0, date, time, onDateClick, onTimeClick,
}) => {
	const theme = useTheme();
	const DayLabel = styled('div')(() => ({
		fontSize: Dimens.TEXT_TITLE,
		fontWeight: 700,
		color: theme.palette.title?.color,
	}));

	const styles = {
		mt,
	} as SxProps;

	const handleDateClick = () => {
		onDateClick?.call(0);
	};

	const handleTimeClick = (event: React.MouseEvent<HTMLDivElement>) => {
		onTimeClick?.call(0, event);
	};

	return (
		<Box sx={{ ...styles }}>
			<Stack direction="row" alignItems="center" flexDirection="column" justifyItems="center">
				<Box style={{ textAlign: 'center', cursor: 'pointer' }} onClick={handleDateClick}>
					<DayLabel>{getDayFromDate(date || new Date())}</DayLabel>

					<Typography sx={{ color: theme.palette.title?.color }}>
						{getDayShortName(date || new Date())}
						{' '}
						|
						{' '}
						{getMonthFormated(date || new Date())}
						{' '}
					</Typography>
				</Box>

				<Divider orientation="horizontal" flexItem />
				<Box style={{ textAlign: 'center', cursor: 'pointer' }} onClick={handleTimeClick}>
					<Typography sx={{ color: theme.palette.title?.color }}>{time || '--:--'}</Typography>
				</Box>
			</Stack>
		</Box>
	);
};

export default DateComp;
