import {
	Stack, SvgIcon, useTheme, SxProps,
} from '@mui/material';
import React from 'react';
import { MuiSvgType } from 'utils/mui.utils';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import { Dimens } from '../../../assets';
import { useSettingsContext } from '../../../providers';

interface FeatureInfoProps {
    icon: MuiSvgType,
    label?: string,
    value: number|boolean,
    showLabel?:boolean,
	sx?: SxProps;
	width?: number
}

const FeatureInfo: React.FC<FeatureInfoProps> = ({
	icon,
	label,
	value,
	showLabel,
	sx,
	width = 150,
}) => {
	const { colors } = useSettingsContext();
	const theme = useTheme();
	const Icon = icon;
	const Label = styled('div')(() => ({
		fontSize: Dimens.FEATURE_TEXT,
		fontWeight: 500,
		color: colors.primaryVariant,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		lineClamp: 1,
		WebkitLineClamp: 1,
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
	}));

	return (
		<Stack direction="row" spacing={0.5} sx={sx} alignItems="center" justifyContent="flex-start" width={width}>
			<SvgIcon component={Icon} sx={{ width: 20, color: theme.palette.title }} />
			<Label>{showLabel && label}</Label>
			<Box sx={{ flexGrow: 1 }} />
			<Box
				sx={{
					background: theme.palette.bgSecondary?.firstColor, borderRadius: '3px', width: '20px', height: '14px', fontSize: '10px', textAlign: 'center',
				}}
				alignItems="center"
				justifyContent="center"
			>
				{value === true && <CheckIcon sx={{ width: 12, marginTop: '-5px' }} />}
				{value !== true && value}
			</Box>
		</Stack>
	);
};

export default FeatureInfo;
