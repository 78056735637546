/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import { ApiResponse, Ticket, TicketSubject } from '../../models';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

const fetchTickets = async (reservationID: number): Promise<ApiResponse<Ticket[]>> => {
	const resp = await reqGet({
		url: Endpoint.RESERVATION_TICKETS.format(reservationID),
	});
	const data = resp.data as ApiResponse<Ticket[]>;
	data.statusCode = resp.status;
	return data;
};

export const useFetchTickets = () => useRequest(([reservationID]) => fetchTickets(reservationID));

const addMessage = async (ticketID: number, message: string): Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.TICKETS_MESSAGES.format(ticketID),
		data: {
			note: message,
		},
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};

export const useAddMessage = () => {
	return useRequest(([ticketID, message]) => addMessage(ticketID, message));
};

const fetchSubjects = async (): Promise<ApiResponse<TicketSubject[]>> => {
	const resp = await reqGet({
		url: Endpoint.COMMUNICATION_SUBJECTS,
	});
	const data = resp.data as ApiResponse<TicketSubject[]>;
	data.statusCode = resp.status;
	return data;
};

export const useFetchSubjects = () => useRequest(() => fetchSubjects(), {
	cache: true,
	cacheKey: 'subjects',
});

const saveTicket = async (subjectTypeId: number, reservationId: number, note: string)
    : Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.TICKETS,
		data: {
			subjectTypeId,
			reservationId,
			note,
		},
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};
export const useSaveTicket = () => {
	return useRequest(([
		subjectTypeId, reservationId, note,
	]) => saveTicket(subjectTypeId, reservationId, note));
};
