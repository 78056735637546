import {
	Box, IconButton, Link as MUILink, Menu, MenuItem, useTheme,
} from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { getStorageItem, StorageKeys } from 'data/storage';
import { Dimens } from 'assets';
import { AppLinks } from 'components/routes';
import {
	Link as RouterLink, useLocation,
	// useHistory,
	// useLocation
} from 'react-router-dom';

const AppMenu: React.FC = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const initialSettings = getStorageItem(StorageKeys.INITIAL_SETTINGS);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
	const location = useLocation();

	const selectedRoute = (currentItem: string): boolean => {
		const { pathname, hash } = location;
		if (pathname.includes(currentItem) || hash.includes(currentItem)) {
			return true;
		}
		return false;
	};

	const OptionMenu = styled('div')(() => ({
		fontSize: Dimens.TEXT_DEFAULT,
		color: theme.lighter,
		fontWeight: 700,
		margin: Dimens.MENU_MARGIN,
		cursor: 'pointer',
	}));

	const HighlightBar = styled('div')(() => ({
		height: '4px',
		background: theme.palette.primary.main,
		width: '30px',
		marginLeft: '8px',
		borderRadius: '1.5px',
		marginTop: '-4px',
	}));

	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const handleAppLinkClick = () => {
		// Todo use initialSettings app link
		const { userAgent } = window.navigator;
		let platform = 'other';
		if (
			userAgent.toLowerCase().includes('ipad')
         || userAgent.toLowerCase().includes('iphone')
         || userAgent.toLowerCase().includes('ipod')
         || userAgent.toLowerCase().includes('ipad')
         || userAgent.toLowerCase().includes('ios')
		) {
			platform = 'apple';
		} else {
			platform = 'google';
		}

		let i = 0;
		if (initialSettings) {
			const { appStoreLinks } = initialSettings;
			while (i < appStoreLinks.length) {
				const el = appStoreLinks[i];
				if (el.name.toLowerCase() === platform) {
					window.open(el.url);
					break;
				}
				i += 1;
			}
		}
	};

	// const changeLink = () => {
	// 	const sections = document.querySelectorAll('section');
	// 	const options = {};

	// 	const observer = new IntersectionObserver(((entries) => {
	// 		entries.forEach((entry) => {
	// 			console.log(entry);
	// 		});
	// 	}), options);

	// 	// loop
	// 	sections.forEach((section) => {
	// 		observer.observe(section);
	// 	});
	// };

	// window.addEventListener('scroll', changeLink);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<MUILink
					component={RouterLink}
					to="/#counters"
					color={theme.disabled}
					underline="none"
				>
					{t('counters').toUpperCase()}
				</MUILink>
			</MenuItem>
			<MenuItem>
				<MUILink component={RouterLink} to="#offers" color="inherit" underline="none">
					{`${t('offers').toUpperCase()} ${t('and').toUpperCase()} ${t('promotions').toUpperCase()}`}
				</MUILink>
			</MenuItem>
			<MenuItem>
				<MUILink component={RouterLink} to="#contacts" color="inherit" underline="none">
					{t('contact').toUpperCase()}
				</MUILink>
			</MenuItem>
			<MenuItem>
				<MUILink component={RouterLink} to={AppLinks.FAQ} color="inherit" underline="none">
					FAQ
				</MUILink>
			</MenuItem>
			<MenuItem>
				<Box sx={{ cursor: 'pointer' }} onClick={handleAppLinkClick}>APP</Box>
			</MenuItem>
		</Menu>
	);

	return (
		<>
			<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
				<MUILink component={RouterLink} to="/#counters" color={theme.lighter} underline="none">
					<OptionMenu>{t('counters').toUpperCase()}</OptionMenu>
					{selectedRoute('counters') && (
						<HighlightBar />
					)}
				</MUILink>
				<MUILink component={RouterLink} to="/#offers" color={theme.lighter} underline="none">
					<OptionMenu>{`${t('offers').toUpperCase()} ${t('and').toUpperCase()} ${t('promotions').toUpperCase()}`}</OptionMenu>
					{selectedRoute('offers') && (
						<HighlightBar />
					)}
				</MUILink>
				<MUILink component={RouterLink} to="/#contacts" color={theme.lighter} underline="none">
					<OptionMenu>{t('contact').toUpperCase()}</OptionMenu>
					{selectedRoute('contacts') && (
						<HighlightBar />
					)}
				</MUILink>
				<MUILink component={RouterLink} to={AppLinks.FAQ} color={theme.lighter} underline="none">
					<OptionMenu>FAQ</OptionMenu>
					{selectedRoute('faq') && (
						<HighlightBar />
					)}
				</MUILink>

				<OptionMenu onClick={handleAppLinkClick}>APP</OptionMenu>
			</Box>

			<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
				<IconButton
					size="large"
					aria-label="show more"
					aria-controls={mobileMenuId}
					aria-haspopup="true"
					onClick={handleMobileMenuOpen}
					color="inherit"
				>
					<MoreIcon />
				</IconButton>
			</Box>
			{renderMobileMenu}
		</>
	);
};

export default AppMenu;
