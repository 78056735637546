import {
	Card, styled, Stack, Grid, Box, useTheme,
} from '@mui/material';
import React from 'react';
import {
	CheckedLabel,
	DefaultButton,
	FeatureInfo,
	PriceInfo,
	Tag,
} from 'components/atoms';
import { generateKey, getFeatureIcon } from 'utils/general.utils';
import { Dimens } from 'assets';
import { useTranslation } from 'react-i18next';
import { CarFeature, Status } from 'data/models';
import { FeatureTypes } from 'data/models/car-feature-type.model';
import { CheckCircleOutline } from '@mui/icons-material';
import VehicleCard from './VehicleCard';

export interface CategoryCardProps {
	title?:string;
	subtitle?:string;
	image?:string;
	features?: CarFeature[];
	pricePerDay?: number;
	totalPrice?: number;
	pricePerDayWithDiscount?: number;
	totalPriceWithDiscount?: number;
	status?: Status;
	btnName?:string;
	renderOtherSection?: () => React.ReactNode;
	onClick?: ()=> void;
}

const CategoryCard: React.FC<CategoryCardProps> = ({
	title,
	subtitle,
	image,
	features = [],
	pricePerDay,
	totalPrice,
	pricePerDayWithDiscount,
	totalPriceWithDiscount,
	status,
	btnName,
	renderOtherSection,
	onClick,
}) => {
	const { t } = useTranslation('translations');
	const { card } = useTheme();

	const StyledCard = styled(Card)(() => ({
		border: 'none',
		boxShadow: 'none',
		padding: card.padding,
		borderRadius: card.radius,
	}));

	const featuresList = features.filter(
		(item) => item.type?.id === FeatureTypes.VEHICLE && item.highlight,
	).slice(0, 5).map((elem) => (
		<FeatureInfo
			key={generateKey(`feature_${elem.id}`)}
			icon={getFeatureIcon(elem.id)}
			showLabel
			label={elem.name}
			value={elem.value}
		/>
	));

	const otherFeatures = features
		.filter((item) => !item.highlight)
		.slice(0, 3)
		.map((item) => (
			<FeatureInfo
				key={generateKey(`other_features_${item.id}`)}
				icon={getFeatureIcon(item.id)}
				showLabel
				label={item.name}
				value={item.isBoolean ? true : item.value ?? 0}
			/>
		));

	const contractFeatures = features
		.filter((item) => item.type?.id === FeatureTypes.CONTRACT && item.highlight)
		.map((item) => <CheckedLabel key={generateKey(`contract_${item.id}`)} label={item.name} />);

	return (
		<Stack display="flex" direction={{ xs: 'column', sm: 'row', md: 'row' }} columnGap={0.5}>
			<StyledCard variant="outlined" sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={5}>
						<VehicleCard
							title={title?.toUpperCase()}
							subtitle={subtitle?.toUpperCase()}
							image={image}
						/>
						<Stack direction="row" spacing={1} mt={1}>
							{contractFeatures}
						</Stack>
					</Grid>
					<Grid item xs={12} sm={12} md={7} display="flex" flexDirection="row">
						<Grid
							container
							alignSelf="center"
							spacing={2}
							direction="row"
							justifyContent="center"
						>
							<Grid item sx={{ rowGap: 2 }} display="flex" flexDirection="column">
								{featuresList}
							</Grid>
							<Box sx={{ flexGrow: 0.5 }} />
							<Grid item sx={{ rowGap: 2 }} display="flex" flexDirection="column">
								{!renderOtherSection ? otherFeatures : renderOtherSection()}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</StyledCard>

			<StyledCard
				variant="outlined"
				sx={{
					minWidth: 200,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Stack
					direction={{ xs: 'row', sm: 'column', md: 'column' }}
					alignItems="center"
					spacing={2}
				>
					{status && (
						<Tag
							sx={{ background: status.color }}
							label={status.name}
							fontSize={Dimens.TEXT_DEFAULT as string}
							iconSize={16}
						/>
					)}
					<PriceInfo
						label={t('day')}
						amount={totalPriceWithDiscount}
						pricePerDay={pricePerDayWithDiscount}
						oldAmount={totalPrice}
						oldPricePerDay={pricePerDay}
						sx={{ alignItems: 'end' }}
					/>
					<Stack>
						<CheckedLabel icon={CheckCircleOutline} fontSize={Dimens.TEXT_DEFAULT as string} label={t('freeCancelation')} />
						<DefaultButton text={btnName || t('reserve')} onClick={onClick} />
					</Stack>
				</Stack>
			</StyledCard>
		</Stack>
	);
};

export default CategoryCard;
