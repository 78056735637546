import React from 'react';
import { FeedbackSnackbar } from 'components/molecules';
import ScrollToTop from './components/atoms/ScrollToTop';
import { withSplashScreen } from './components/hocs';
import { AppRouter } from './components/routes';

const Main = () => (
	<>
		<ScrollToTop />
		<FeedbackSnackbar />
		<AppRouter />
	</>
);

export default withSplashScreen(Main);
