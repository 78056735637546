/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';
import { ApiResponse, Car } from '../../models';

const getCarsByCategoryID = async (categoryID: number): Promise<ApiResponse<Car[]>> => {
	const resp = await reqGet({
		url: `${Endpoint.CARS_BY_CATEGORY_ID}?categoryId=${categoryID}`,
	});
	const data = resp.data as ApiResponse<Car[]>;
	data.statusCode = resp?.status;
	return data;
};
export const useGetCarsByCategoryID = () => useRequest(([id]) => getCarsByCategoryID(id));
