import { Footer } from 'components/organisms';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FifthSection from './FifthSection';
import FirstSection from './FirstSection';
import FourthSection from './FourthSection';
// import SecondSection from './SecondSection';
import SixthSection from './SixthSection';
// import ThirdSection from './ThirdSection';

const HomeScreen: React.FC = () => {
	const location = useLocation();

	useEffect(() => {
		if (location.hash) {
			const elem = document.getElementById(location.hash.slice(1));
			if (elem) {
				elem.scrollIntoView({ behavior: 'smooth' });
			}
		} else {
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		}
	}, [location]);

	return (
		<div>
			<FirstSection />
			{/* <SecondSection /> */}
			{/* <ThirdSection /> */}
			<FourthSection />
			<FifthSection />
			<SixthSection />
			<Footer />

		</div>
	);
};

export default HomeScreen;
