/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import { ApiResponse, CurrencyData } from '../../models';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

const fetchCurrencyRates = async (): Promise<ApiResponse<CurrencyData>> => {
	const resp = await reqGet({
		url: Endpoint.CURRENCY_RATES,
	});
	const data = resp.data as ApiResponse<CurrencyData>;
	data.statusCode = resp.status;
	return data;
};

export const useFetchCurrencyRates = () => useRequest(() => fetchCurrencyRates(), {
	cache: true,
	cacheKey: 'currency-rate',
});
