/* eslint-disable react/no-unused-prop-types */
import {
	Card, Grid, Stack, styled, Typography, useTheme,
} from '@mui/material';
import { FeatureInfo } from 'components/atoms';
import { CarFeature } from 'data/models';
import { FeatureTypes } from 'data/models/car-feature-type.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertAndFormatToCurrency, generateKey, getFeatureIcon } from 'utils/general.utils';
import VehicleCard from './VehicleCard';

interface CategoryShortCardProps {
    title?:string;
    subtitle?:string;
    image?:string;
    features?: CarFeature[];
	pricePerDay?: number;
	totalPrice?: number;
	pricePerDayWithDiscount?: number;
	totalPriceWithDiscount?: number;
}

const CategoryShortCard: React.FC<CategoryShortCardProps> = ({
	title,
	subtitle,
	image,
	pricePerDay,
	features = [],
}) => {
	const { t } = useTranslation('translations');
	const { card } = useTheme();
	const StyledCard = styled(Card)(() => ({
		border: 'none',
		boxShadow: 'none',
		padding: card.padding,
		borderRadius: card.radius,
	}));

	const featuresList = features.filter(
		(item) => item.type?.id === FeatureTypes.VEHICLE && item.highlight,
	).slice(0, 4).map((elem) => (
		<FeatureInfo
			key={generateKey(`feature_${elem.id}`)}
			icon={getFeatureIcon(elem.id)}
			showLabel
			label={elem.name}
			value={elem.value}
			sx={{ alignSelf: 'end' }}
		/>
	));

	const Price = styled('div')(({ theme }) => ({
		fontSize: '24px',
		fontWeight: 700,
		color: theme.palette.primary.main,
		whiteSpace: 'nowrap',
	}));

	const price = convertAndFormatToCurrency(pricePerDay ?? 0);

	return (
		<StyledCard>
			<Grid container spacing={2}>
				<Grid item md={6}>
					<VehicleCard
						title={title}
						subtitle={subtitle}
						image={image}
						showSimilar
					/>
				</Grid>
				<Grid item md={6} alignItems="end">
					<Stack spacing={1}>
						<Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
							<Price>{price}</Price>
							<Typography>
								{' '}
								/
								{t('day')}
							</Typography>
						</Stack>
						{featuresList}
					</Stack>
				</Grid>
			</Grid>
		</StyledCard>
	);
};

export default CategoryShortCard;
