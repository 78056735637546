import { Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { QuestionCategory } from 'data/models';
import React, { useEffect, useState } from 'react';
import { generateKey } from 'utils/general.utils';
import FaqGroup from './FaqGroup';

interface FaqListProps {
   categories: QuestionCategory[];
}

const FaqList : React.FC<FaqListProps> = ({ categories }) => {
	const [qtdPerRow, setQtdPerRow] = useState<number>(3);

	const theme = useTheme();
	const isSM = useMediaQuery(theme.breakpoints.up('sm'));
	const isMD = useMediaQuery(theme.breakpoints.up('md'));

	const result = categories.reduce<QuestionCategory[][]>((prev, current, index) => {
		const mPrev = prev;
		const inx = index % Math.ceil(categories.length / qtdPerRow);
		mPrev[inx] = prev[inx] || [];
		mPrev[inx].push(current);
		return mPrev;
	}, [[]]);

	useEffect(() => {
		if (isMD) {
			setQtdPerRow(3);
		} else if (isSM) {
			setQtdPerRow(2);
		} else {
			setQtdPerRow(1);
		}
	}, [isSM, isMD]);

	const listComponent = result.map((item) => (
		<FaqGroup
			sx={{ mt: 2 }}
			key={generateKey('faq')}
			categories={item}
		/>
	));

	return (
		<Stack>
			{listComponent}
		</Stack>
	);
};

export default FaqList;
