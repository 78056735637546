import {
	GridSize, Stack, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Box, SxProps } from '@mui/system';
import { Card } from '../../molecules';
import Subtitle from '../subtitle/SubTitle';

interface CommentCardProps {
    comment: string;
    image: string;
    identification: string;
    sxBox?: SxProps;
	city: string;
    xs?: boolean | GridSize | undefined
    md?: boolean | GridSize | undefined
    sm?: boolean | GridSize | undefined
}

const CommentCard: React.FC<CommentCardProps> = ({
	sxBox, comment, image, city, identification, xs, md, sm,
}) => {
	const theme = useTheme();

	const content = (
		<Box sx={{ position: 'relative', ...sxBox }}>
			<Typography sx={{ fontSize: '50px', pb: -10, color: 'primary.main' }}>“</Typography>
			<Subtitle sx={{ mt: -4 }}>{comment}</Subtitle>
			<Stack
				direction="row"
				sx={{
					verticalAlign: 'middle', mt: 2, position: 'absolute', bottom: 0,
				}}
				spacing={2}
				alignItems="center"
				alignSelf="end"
			>
				<Avatar alt={identification} sx={{ mt: 1 }} src={image} />
				<Box sx={{ alignSelf: 'flex-end' }}>
					<Subtitle fontWeight={400} sx={{ verticalAlign: 'middle', height: '100%', color: theme.lighter }}>{identification}</Subtitle>
					{/* Change text color */}
					<Subtitle fontWeight={400} sx={{ verticalAlign: 'middle', height: '100%', color: theme.lighter }}>{city}</Subtitle>
				</Box>
			</Stack>
		</Box>
	);

	return (
		<Card md={md} xs={xs} sm={sm} content={content} shadows={false} />
	);
};
export default CommentCard;
