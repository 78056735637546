import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Dimens } from 'assets';
import { useAuth, useFeedbackContext } from 'providers';
import { AlertProps } from 'providers/feedback-context.providers';
import { User } from 'data/models';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordValidation, validateEmail, validatePassword } from 'utils/general.utils';
import { VerificationNumberDialog } from '..';
import {
	CheckedLabel, CheckItem, DefaultButton, DefaultInput,
} from '../../atoms';
import { CountryInput } from '../../molecules';

const countries = [
	{
		id: 1,
		isoCode: 'PT',
		phoneCode: '351',
		name: 'Portugal',
		shortName: 'PT',
	},
];

interface AccountFormProps {
   onClick: (user: User, password: string, code: string) => void;
}

const AccountForm: React.FC<AccountFormProps> = ({ onClick }) => {
	const { t } = useTranslation('translations');
	const [user, setUser] = useState<User>({ phoneCountry: countries[0] } as User);
	const [password, setPassword] = useState<string>('');
	const [retypePassword, setRetypePassword] = useState<string>('');
	const [error, setError] = useState<string | undefined>();
	const [open, setOpen] = useState(false);
	const [emailChecked, setEmailChecked] = useState(false);
	const [passwordValidation, setPasswordValidation] = useState<PasswordValidation[]>();
	const [termsChecked, setTermChecked] = useState(false);

	const { createReqInfo } = useAuth();

	const { setAlert } = useFeedbackContext();

	useEffect(() => {
		if (createReqInfo.error) {
			setError(createReqInfo.info?.message);
			const options: AlertProps = {
				type: 'error',
				message: createReqInfo.info?.message ?? '',
			};
			setAlert(options);
		} else {
			setError(undefined);
		}
	}, [createReqInfo, setAlert]);

	useEffect(() => {
		setEmailChecked(validateEmail(user.email || ''));
		setPasswordValidation(validatePassword(password));
	}, [user, password, retypePassword]);

	const handleClick = () => {
		setOpen(true);
	};

	const handleTryCreate = (code: string) => {
		onClick(user, password, code);
		setOpen(false);
	};

	const isPasswordTipValid = (tip: PasswordValidation) => {
		if (!passwordValidation) return false;
		return passwordValidation.indexOf(tip) === -1;
	};

	const enableCreate = () => (
		(user.name?.length || 0) >= 3
         && (user.phone?.length || 0) === 9
         && passwordValidation?.length === 0
         && password === retypePassword
         && emailChecked
         && termsChecked
	);

	return (
		<Stack direction="column" spacing={2} sx={{ mt: 4 }} component="form">
			<Typography component="span" sx={{ color: 'red', fontSize: Dimens.TEXT_DEFAULT, textAlign: 'center' }}>
				{error}
			</Typography>
			<DefaultInput
				label={t('name').toUpperCase()}
				value={user.name}
				checked={(user.name?.length || 0) >= 3}
				onChange={(val) => setUser({ ...user, name: val })}
			/>

			<DefaultInput
				label={t('email').toUpperCase()}
				value={user.email}
				checked={emailChecked}
				onChange={(val) => setUser({ ...user, email: val })}
			/>

			<CountryInput
				sx={{ pt: 1.5 }}
				type="number"
				inputValue={user.phone}
				countryValue={user.phoneCountry}
				checked={(user.phone?.length || 0) === 9}
				onChange={(country, phone) => {
					let mUser = { ...user, phone };
					if (country) {
						mUser = { ...mUser, phoneCountry: country };
					}
					setUser(mUser);
				}}
				countries={countries}
			/>

			<DefaultInput
				label={t('password').toUpperCase()}
				value={password}
				type="password"
				autoComplete="new-password"
				onChange={(val) => setPassword(val)}
				checked={passwordValidation?.length === 0}
			/>

			<Stack direction="column" alignItems="start">
				<CheckedLabel checked={isPasswordTipValid('length')} label={t('eightCharactersHint')} />
				<CheckedLabel checked={isPasswordTipValid('letter')} label={t('oneLetterHint')} />
				<CheckedLabel checked={isPasswordTipValid('number')} label={t('oneNumberHint')} />
				<CheckedLabel checked={isPasswordTipValid('special-character')} label={t('oneSpecialCharacterHint')} />
			</Stack>

			<DefaultInput
				label={`${t('confirm').toUpperCase()} ${t('password').toUpperCase()}`}
				value={retypePassword}
				type="password"
				autoComplete="new-password"
				checked={password === retypePassword && passwordValidation?.length === 0}
				onChange={(val) => setRetypePassword(val)}
			/>
			<Box sx={{ flexGrow: 1 }} />

			<CheckItem onChange={(e) => setTermChecked(e)} label={t('privacyPolicyInfo')} />
			<CheckItem label={t('offersAuthorizationInfo').format(process.env.REACT_APP_NAME)} />

			<Box sx={{ flexGrow: 1 }} />
			<Stack direction="row" spacing={2} sx={{ mt: 10 }}>
				<Box sx={{ flexGrow: 1 }} />
				<DefaultButton
					// type="submit"
					loading={createReqInfo.loading || open}
					disabled={!enableCreate()}
					text={`${t('create')} ${t('account')}`}
					minWidth="110px"
					onClick={handleClick}
				/>
				<Box sx={{ flexGrow: 1 }} />
			</Stack>

			{!!user.phoneCountry && (
				<VerificationNumberDialog
					open={open}
					country={user.phoneCountry}
					phone={user.phone}
					onCancel={() => setOpen(false)}
					onEnteredCode={(code) => handleTryCreate(code)}
				/>
			)}
		</Stack>
	);
};

export default AccountForm;
