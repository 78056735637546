/* eslint-disable no-param-reassign */
import {
	Box, Dialog, DialogContent,
	Divider, Stack, styled, Typography,
} from '@mui/material';
import { Dimens } from 'assets';
import { ticketService } from 'data/api';
import { useFeedbackContext } from 'providers';
import { Ticket, TicketMessage as Message } from 'data/models';
import React, { useEffect, useState } from 'react';
import { dateToString, getFullDateFormated } from 'utils/general.utils';
import {
	DefaultButton, DefaultInput, Subtitle, Tag,
} from '../../atoms';
import TicketMessage from './TicketMessage';

interface TicketDialogProps {
    open: boolean;
    ticket: Ticket;
    onClose: () => void;
    // onSave: (subjectID: number, note: string) => void
}

const TicketDialog: React.FC<TicketDialogProps> = ({
	open,
	ticket,
	onClose,
	// onSave,
}) => {
	const [message, setMessage] = useState<string>();
	const { setAlert } = useFeedbackContext();
	const useAddMessage = ticketService.useAddMessage();

	const ShortTitle = styled('div')(({ theme }) => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 600,
		color: theme.disabled,
	}));

	const handleSaveClick = () => {
		useAddMessage.execute(ticket.id, message).then((hasError) => {
			if (!hasError) {
				ticket.messages = [
					{ registration: dateToString(new Date()), note: message } as Message,
					...ticket.messages,
				];
				setMessage(''); // why?
			}
		});
	};

	useEffect(() => {
		const { info } = useAddMessage;
		if (info) {
			setAlert({
				message: info.message,
				type: info.statusCode !== 200 ? 'error' : 'success',
			});
		}
	}, [setAlert, useAddMessage]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth
			maxWidth="sm"
		>
			<DialogContent>
				<Stack direction="column" spacing={1}>
					<Stack direction="row" spacing={1}>
						<Subtitle sx={{ color: 'primary.main' }}>
							#
							{ticket.id}
						</Subtitle>
						<Subtitle>
							{' '}
							{ticket.subject.name}
						</Subtitle>
					</Stack>
					<Stack direction="row" spacing={1}>
						<Tag sx={{ background: ticket.status.color }} label={ticket.status.name} fontSize="9px" />
						<Box sx={{ flexGrow: 1 }} />
						<Typography sx={{
							fontSize: Dimens.TEXT_DEFAULT,
							color: (theme) => theme.disabled,
						}}
						>
							{getFullDateFormated(ticket.registration)}
						</Typography>
					</Stack>

					<Typography sx={{
						fontSize: Dimens.TEXT_DEFAULT,
						mb: 2,
					}}
					>
						{ticket.note}
					</Typography>

					<Divider orientation="horizontal" sx={{ mt: 4, mb: 6, width: '100%' }} />

					<Stack spacing={2}>
						<DefaultInput
							label="Nova mensagem"
							value={message}
							fullWidth
							onChange={(value) => setMessage(value)}
						/>

						<DefaultButton loading={useAddMessage.loading} onClick={handleSaveClick} sx={{ alignSelf: 'end' }} text="Enviar" minWidth="100px" />
					</Stack>

					{ticket.messages.length > 0 && <ShortTitle>Mensagens</ShortTitle>}
					{ticket.messages.map((item) => <TicketMessage key={item.id} message={item} />)}
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

export default TicketDialog;
