import {
	CardMedia, Grid, Stack, useMediaQuery,
} from '@mui/material';
import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, useTheme } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Dimens } from 'assets';
import { Subtitle } from 'components/atoms';
import { isObject } from 'utils/general.utils';

export type GalleryImage = {
    name: string;
    image: string;
}

type MaxBreakpoints = {
    md?: number;
    sm?: number;
    xs?: number;
};

type Max = number | MaxBreakpoints;

interface GalleryCarouselProps {
    data: GalleryImage[],
    title: string,
    max?: Max
}

const GalleryCarousel: React.FC<GalleryCarouselProps> = ({
	data, title, max = 4 as Max,
}) => {
	const [selected, setSelected] = React.useState<number>();
	const [maxImages, setMaxImages] = React.useState<number>(4);
	const teem = useTheme();
	const isSM = useMediaQuery(teem.breakpoints.up('sm'));
	const isMD = useMediaQuery(teem.breakpoints.up('md'));
	const isXS = useMediaQuery(teem.breakpoints.up('xs'));

	React.useEffect(() => {
		if (data.length !== 0) {
			setSelected(0);
		}
	}, [data]);

	const ShortTitle = styled('div')(({ theme }) => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 600,
		color: theme.disabled,
	}));

	const handleClick = (index: number) => {
		setSelected(index);
	};

	const getSelected = () => {
		if (selected !== undefined) {
			return data[selected];
		}
		return undefined;
	};

	React.useEffect(() => {
		const getMax = () => {
			if (isObject(max)) {
				const maxL = max as MaxBreakpoints;
				if (isMD && maxL.md !== undefined) {
					return maxL.md;
				} if (isSM && maxL.sm !== undefined) {
					return maxL.sm;
				} if (isXS && maxL.xs !== undefined) {
					return maxL.xs;
				}
				return maxL.md || maxL.sm || maxL.xs || maxImages;
			}
			return max as number;
		};

		setMaxImages(getMax());
	}, [isXS, isSM, isMD, max, maxImages]);

	const showRange = (index: number): string => {
		if (selected === undefined) return 'none';
		const maxL = maxImages;
		const pair = maxL % 2 === 0;
		let half = Math.floor(maxL / 2);
		if (index > selected) {
			const val = selected - half;
			if (pair) {
				half -= 1;
			}
			if ((val >= 0 && selected + half < index) || (val < 0 && index > maxL - 1)) {
				return 'none';
			}
		} else {
			const diff = data.length - (selected + half) - 1;
			if ((diff > 0 && selected - half > index)
				|| ((selected - (half - (pair && diff < 0 ? 1 : 0) + Math.abs(diff)) > index))) {
				return 'none';
			}
		}
		return 'block';
	};

	const thumbnailList = data.map((item, index) => (
		<Box key={item.name} onClick={() => handleClick(index)} style={{ cursor: 'pointer', display: showRange(index) }}>
			<Stack direction="column" alignItems="center">
				<CardMedia
					component="img"
					alt="Car image"
					sx={{
						borderRadius: '8px',
						height: (index === selected) ? '60px' : '50px',
						width: (index === selected) ? '60px' : '50px',
					}}
					image={item.image}
				/>
				<Subtitle
					sx={{
						mt: 0.6,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						width: '60px',
						textAlign: 'center',
					}}
					fontSize={Dimens.TEXT_SMALL as string}
				>
					{item.name}

				</Subtitle>
			</Stack>
		</Box>
	));

	const nextImage = () => {
		if (selected === undefined) return;
		if (selected + 1 < data.length) {
			setSelected(selected + 1);
		}
	};

	const previousImage = () => {
		if (selected === undefined) return;
		if (selected - 1 >= 0) {
			setSelected(selected - 1);
		}
	};

	return (
		<Grid item xs={12}>
			<Grid spacing={1} container item xs={12} md={12} sx={{ m: 0 }}>
				{getSelected() && (
					<CardMedia
						component="img"
						alt={getSelected()?.name}
						sx={{
							borderRadius: '8px',
							height: '250px',
						}}
						image={getSelected()?.image}
					/>
				)}

				<Grid item xs={12} md={12} sx={{ mt: 2 }}>
					<ShortTitle>{title}</ShortTitle>
				</Grid>

				<Grid item xs={12} md={12}>
					<Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 0.5 }}>
						<Box onClick={previousImage} style={{ cursor: 'pointer' }}>
							<ArrowBackIosIcon />
						</Box>
						<Box sx={{ flexGrow: 1 }} />
						{thumbnailList}
						<Box sx={{ flexGrow: 1 }} />
						<Box onClick={nextImage} style={{ cursor: 'pointer' }}>
							<ArrowForwardIosIcon />
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default GalleryCarousel;
