import { Stack, styled } from '@mui/material';
import { Status } from 'data/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFullDateFormated } from 'utils/general.utils';
import CategoryCard, { CategoryCardProps } from './CategoryCard';

export interface ReservationCardProps extends CategoryCardProps {
    code: number;
    pickupLocation?:string;
    startDate:string;
    returnLocation?: string;
    endDate: string;
    status?: Status;
}

const ReservationCard : React.FC<ReservationCardProps> = ({
	title,
	subtitle,
	image,
	features = [],
	pricePerDay,
	totalPrice,
	pricePerDayWithDiscount,
	totalPriceWithDiscount,
	code,
	pickupLocation,
	startDate,
	returnLocation,
	endDate,
	status,
	btnName,
	onClick,
}) => {
	const { t } = useTranslation('translations');

	const ShortTitle = styled('div')(({ theme }) => ({
		fontSize: '12px',
		fontWeight: 600,
		color: theme.palette.subtitle2?.color,
	}));

	const Code = styled('div')(({ theme }) => ({
		fontSize: '12px',
		fontWeight: 700,
		color: theme.palette.title?.color,
	}));

	const DateLabel = styled('div')(({ theme }) => ({
		color: theme.palette.title?.color,
		fontSize: '12px',
	}));

	const renderOtherSection = () => (
		<>
			<Stack>
				<ShortTitle>
					{t('code')}
                    &nbsp;
				</ShortTitle>
				<Code>
					#
					{code}
				</Code>
			</Stack>
			<Stack direction="column">
				<ShortTitle>
					{t('pickupFrom')}
				</ShortTitle>
				<Code>
					{pickupLocation}
				</Code>
				<DateLabel>
					{getFullDateFormated(startDate)}
				</DateLabel>
			</Stack>
			<Stack direction="column">
				<ShortTitle>
					{t('returnTo')}
				</ShortTitle>
				<Code>
					{returnLocation}
				</Code>
				<DateLabel>
					{getFullDateFormated(endDate)}
				</DateLabel>
			</Stack>
		</>
	);
	return (
		<CategoryCard
			title={title}
			subtitle={subtitle}
			image={image}
			features={features}
			pricePerDay={pricePerDay}
			totalPrice={totalPrice}
			pricePerDayWithDiscount={pricePerDayWithDiscount}
			totalPriceWithDiscount={totalPriceWithDiscount}
			status={status}
			renderOtherSection={renderOtherSection}
			btnName={btnName}
			onClick={onClick}
		/>
	);
};

export default ReservationCard;
