import {
	Container, Skeleton, Stack, Typography, Box, useTheme,
} from '@mui/material';
import { YellowBaggage } from 'assets/images';
import React from 'react';
import useEffectCustom from 'hooks/useEffectCustom';
import { FaqList, Footer } from 'components/organisms';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// import { getStorageItem, StorageKeys } from 'data/storage';
import { AppLinks } from 'components/routes';
import { useAuth } from 'providers';
import { Dimens } from '../../../assets';
import { questionService } from '../../../data/api';

const FaqScreen: React.FC = () => {
	const { t } = useTranslation('translations');
	const useFetchCategories = questionService.useFetchQuestionCategories();
	const history = useHistory();
	const theme = useTheme();
	const { user } = useAuth();
	useEffectCustom(() => {
		useFetchCategories.execute();
	}, []);

	// const user = getStorageItem(StorageKeys.USER);

	const handleMyReservationClick = () => {
		if (user) {
			history.push(AppLinks.MY_RESERVATIONS);
		}
	};

	return (
		<>
			<Box sx={{ bgcolor: 'bgSecondary.firstColor' }}>
				<Container maxWidth="lg" sx={{ pt: 8, pb: 8, minHeight: '60vh' }}>
					{useFetchCategories.loading ? (
						<>
							<Skeleton variant="rectangular" height={200} width="100%" sx={{ borderRadius: '10px' }} />
							<Skeleton variant="rectangular" height={300} width="100%" sx={{ borderRadius: '10px', mt: 2 }} />
						</>
					) : (
						<>
							<Stack direction="column" justifyContent="center" alignItems="center">
								<Stack direction="row" spacing={1} sx={{ mb: 2 }}>
									<Typography sx={{ fontSize: Dimens.TEXT_NORMAL, fontWeight: 700, color: theme.lighter }}>{t('helpAndSupport')}</Typography>
								</Stack>
								<YellowBaggage />
							</Stack>
							<FaqList categories={useFetchCategories.data || []} />
						</>
					)}
				</Container>
			</Box>
			<Footer
				hideCounters
				handleMyReservationClick={handleMyReservationClick}
				onlyContact={!user}
			/>
		</>
	);
};

export default FaqScreen;
