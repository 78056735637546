/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import { reqGet } from '../request.api';
import Endpoint from '../endpoints.api';
import { ApiResponse, Question, QuestionCategory } from '../../models';

const fetchQuestionCategories = async (): Promise<ApiResponse<QuestionCategory[]>> => {
	const resp = await reqGet({
		url: Endpoint.QUESTION_CATEGORIES,
	});
	const data = resp.data as ApiResponse<QuestionCategory[]>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchQuestionCategories = () => useRequest(() => fetchQuestionCategories(), {
	cache: true,
	cacheKey: 'question-categories',
});

const fetchQuestions = async (categoryID?:number, search?: string):
	Promise<ApiResponse<Question[]>> => {
	const resp = await reqGet({
		url: `${Endpoint.QUESTIONS}?categoryId=${categoryID ?? ''}&Search=${search ?? ''}`,
	});
	const data = resp.data as ApiResponse<Question[]>;
	data.statusCode = resp?.status;
	return data;
};
export const useFetchQuestions = () => useRequest(([categoryID, search]) => (
	fetchQuestions(categoryID, search)));
