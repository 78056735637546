import React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import * as Screens from '../pages';
import GenericErrorSreen from '../pages/generic-error/GenericErrorScreen';
import AppLinks from './app-links.routes';

const MainRoute = (): JSX.Element => (
	<Switch>
		<Route path={AppLinks.HOME} component={Screens.HomeScreen} exact />
		<Route path={AppLinks.SPECIAL_OFFER_DETAIL} component={Screens.SpecialOfferScreen} />
		{/* Reservation */}
		<Route exact path={AppLinks.RESERVATION_SEARCH} component={Screens.ReservationSearchScreen} />
		<Route path={AppLinks.RESERVATION_EXTRAS} component={Screens.ReservationExtrasScreen} />
		<Route path={AppLinks.RESERVATION_COMPLETING} component={Screens.CompleteReservationScreen} />
		<Route exact path={AppLinks.MY_RESERVATIONS} component={Screens.MyReservationsScreen} />
		<Route exact path={AppLinks.MY_RESERVATION_DETAIL} component={Screens.MyReservationDetail} />
		<Route exact path={AppLinks.PROFILE} component={Screens.ProfileScreen} />
		<Route
			exact
			path={AppLinks.MY_RESERVATION_DOCUMENTS}
			component={Screens.ReservationTicketsScreen}
		/>
		<Route exact path={AppLinks.FAQ} component={Screens.FaqScreen} />
		{/* ================================================================== */}
		<Route
			exact
			path={AppLinks.COMMERCIALS_VEHICLES}
			component={Screens.CommercialVehiclesScreen}
		/>

		<Route component={GenericErrorSreen} />
	</Switch>
);

export default MainRoute;
