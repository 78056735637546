/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PaletteMode } from '@mui/material';

export const getDesignTokens = async (mode: PaletteMode) => {
	const { default: getTokens } = await import(`assets/theme/theme.${process.env.REACT_APP_RES}`);

	return ({
		...getTokens(mode),
	});
};
