import { LoadingButton } from '@mui/lab';
import {
	Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';
import { userService } from 'data/api';
import { useFeedbackContext } from 'providers';
import { validateEmail } from 'utils/general.utils';
import { DefaultInput } from '../../atoms';

interface ForgotPasswordDialogProps {
   open: boolean;
   onClose: () => void;
}

const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = ({ open, onClose }) => {
	const { t } = useTranslation('translations');

	const [email, setEmail] = useState<string>('');
	const [emailChecked, setEmailChecked] = useState<boolean>(false);
	const { setAlert } = useFeedbackContext();

	const {
		execute, info, loading, error,
	} = userService.useForgotPassword();

	useEffect(() => {
		setEmailChecked(validateEmail(email || ''));
	}, [email]);

	const handleClose = () => {
		onClose();
		setEmail('');
	};

	const handleClick = () => {
		execute(email).then(() => {
			handleClose();
		});
	};

	useEffect(() => {
		if (info) {
			setAlert({
				message: info.message,
				type: error ? 'error' : 'success',
			});
		}
	}, [info, error, setAlert]);

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{t('forgotPassword')}</DialogTitle>
			<DialogContent>
				<DialogContentText>{t('forgotPasswordInfo2')}</DialogContentText>
				<Stack sx={{ mt: 2 }}>
					<DefaultInput label={t('email').toUpperCase()} value={email} icon={EmailIcon} onChange={(value) => setEmail(value)} />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleClose}>
					{t('cancel')}
				</Button>
				<LoadingButton disabled={!emailChecked} loading={loading} variant="text" color="secondary" onClick={handleClick}>
					{t('send')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default ForgotPasswordDialog;
