/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import i18next from 'i18next';
import {
	getStorageItem,
	removeStorageItem,
	setStorageItem,
	StorageKeys,
} from '../storage';
import Endpoint from './endpoints.api';

const baseURL = process.env.REACT_APP_BASE_URL ?? '';
const UNAUTHORIZED = 401;
const STATUS_SUCCESS = 200;
const axiosConfig = {
	baseURL,
};
const $axios = axios.create(axiosConfig);

const request = async (config: AxiosRequestConfig) => {
	const mConfig = config;
	const token = getStorageItem(StorageKeys.TOKEN);
	const bearerToken = getStorageItem(StorageKeys.AUTHENTICATION_TOKEN);
	const refreshToken = getStorageItem(StorageKeys.REFRESH_TOKEN);
	mConfig.headers = {
		...config.headers,
		'Access-Control-Allow-Origin': baseURL,
		Lang: i18next.language.substring(0, 2),
		Accept: 'application/json',
		...token ? {
			SessionToken: `${token || ''}`,
		} : {},
		...bearerToken ? {
			Authorization: `Bearer ${bearerToken}`,
		} : {},
	};

	/** CHECK IF THE USER IS UNAUTHORIZED AND REFRESHE THE TOKEN */
	/** AXIOS RESPONSE INTERCEPTOR */
	$axios.interceptors.response.use(
		(response) => response,
		async (error: AxiosError) => {
			const originalRequest = error.config;
			if (error?.response?.status === UNAUTHORIZED) {
				try {
					const resp = await $axios({
						url: Endpoint.REFRESH_TOKEN,
						method: 'POST',
						data: {
							refreshToken,
						},
						headers: {
							...config.headers,
							'Access-Control-Allow-Origin': baseURL,
							Lang: i18next.language.substring(0, 2),
							Accept: 'application/json',
							...token ? {
								SessionToken: `${token || ''}`,
							} : {},
							...bearerToken ? {
								Authorization: `Bearer ${bearerToken}`,
							} : {},
						},
					});
					if (resp.status !== STATUS_SUCCESS) {
						removeStorageItem(StorageKeys.USER);
						removeStorageItem(StorageKeys.REFRESH_TOKEN);
						window.location.reload();
						return null;
					}
					/**
						If the token is refreshed successfully then retry the original request with the new
						authentication token
				 	*/
					if (resp.status === 200) {
						setStorageItem(StorageKeys.USER, resp.data.data);
						const returnValue = await $axios({
							...originalRequest,
							headers: {
								...originalRequest.headers,
								Authorization: `Bearer ${resp.data.data.authenticationToken}`,
							},
						});
						return returnValue;
					}
				} catch (err) {
					removeStorageItem(StorageKeys.USER);
					removeStorageItem(StorageKeys.REFRESH_TOKEN);
					window.location.reload();
				}
			}

			throw error;
		},
	);

	const r = await $axios({
		...config,
	});
	return r;
};

export enum ErrorType {
    INVALID_SESSION = 'INVALID_SEESION_TOKEN',
    GENERAL_ERROR = 'GENERAL_ERROR'
}

export const reqGet = async (config: AxiosRequestConfig) => request({
	method: 'GET',
	...config,
});

export const reqPost = async (config: AxiosRequestConfig) => request({
	method: 'POST',
	...config,
});

export const reqPut = async (config: AxiosRequestConfig) => request({
	method: 'PUT',
	...config,
});
export const reqDelete = async (config: AxiosRequestConfig) => request({
	method: 'DELETE',
	...config,
});

export default request;
