import {
	Grid, GridSize, Card as MUICard, useTheme, Box, SxProps,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { Dimens } from '../../../assets';

interface CardProps {
    content: ReactNode;
    footer?: ReactNode;
    shadows?: boolean;
    padding?: string;
    xs?: boolean | GridSize | undefined;
    md?: boolean | GridSize | undefined;
    sm?: boolean | GridSize | undefined;
    mb?:number;
    maxWidth?: string;
    minWidth?: string;
    contentSx?: SxProps;
    footerSx?: SxProps;
	footerColor?: string

}

const Card: React.FC<CardProps> = ({
	content, footer, shadows = true, padding = Dimens.DEFAULT_PADDING as string,
	xs = false, md = false, sm = false, mb, maxWidth, minWidth, contentSx, footerSx = {},
	footerColor,
}) => {
	const theme = useTheme();
	const container = {
		bgcolor: 'bgSecondary.default',
		padding,
		borderRadius: Dimens.DEFAULT_RADIUS,
		boxShadow: shadows ? '5px 5px 30px rgba(34, 57, 105, 0.05)' : 'none',
		m: 0,
		zIndex: -1,
		...contentSx,
	} as SxProps;

	const footerContent = {
		position: 'inherit',
		bgcolor: footerColor || 'background.default',
		padding: Dimens.DEFAULT_PADDING,
		borderRadius: Dimens.DEFAULT_RADIUS,
		boxShadow: shadows ? theme.palette.card?.shadow : 'none',
		marginTop: -6,
		width: '100%',
		zIndex: 100,
		...footerSx,
	} as SxProps;

	return (
		<Grid item xs={xs} md={md} sm={sm} maxWidth={maxWidth} minWidth={minWidth} sx={{ position: 'relative', height: '100%', mb }}>
			<MUICard sx={container}>
				{content}
				{!!footer && <Box sx={{ mt: 4 }} />}
			</MUICard>
			{footer !== undefined ? (
				<MUICard sx={footerContent}>
					{footer}
				</MUICard>
			) : null}
		</Grid>
	);
};

export default Card;
