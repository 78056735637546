/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
	createContext, FC, useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import useEffectCustom from 'hooks/useEffectCustom';
import { userService } from '../data/api';
import { ErrorType } from '../data/api/request.api';
import {
	User, ApiResponseBase,
} from '../data/models';
import {
	getStorageItem, removeStorageItem, setStorageItem, StorageKeys,
} from '../data/storage';
import { useSettingsContext } from '.';
import { AppLinks } from '../components/routes';

export type ReqInfo = {
    info?: ApiResponseBase;
    error?:boolean;
    loading: boolean;
}

export type AuthContextProps = {
    user?: User;
    login: (email: string, password: string) => Promise<boolean>;
    loginReqInfo: ReqInfo;
    logout: () => Promise<boolean>;
    logoutReqInfo: ReqInfo;
    createAccount: (user: User, password:string, activationCode:string) => Promise<boolean>;
    createReqInfo: ReqInfo;
    requestNewToken: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: FC = ({ children }) => {
	const [user, setUser] = useState<User>();
	const useCreateAccount = userService.useCreateAccount();
	const useLogin = userService.useLogin();
	const useLogout = userService.useLogout();
	const { getInitialRequest } = useSettingsContext();
	const history = useHistory();

	const setUserData = (data: User) => {
		setUser(data);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const mUser = { ...data } as any;
		delete mUser.refreshToken;
		delete mUser.refreshTokenExpiration;
		delete mUser.authenticationToken;

		setStorageItem(StorageKeys.USER, mUser);
		setStorageItem(StorageKeys.REFRESH_TOKEN, data.refreshToken);
		setStorageItem(StorageKeys.AUTHENTICATION_TOKEN, data.authenticationToken);
	};

	const clearUserData = () => {
		removeStorageItem(StorageKeys.USER);
		removeStorageItem(StorageKeys.REFRESH_TOKEN);
		removeStorageItem(StorageKeys.AUTHENTICATION_TOKEN);
		// removeStorageItem(StorageKeys.TOKEN);
		setUser(undefined);
	};

	useEffect(() => {
		// This block will trigger when the auth context start
		const u = getStorageItem(StorageKeys.USER);
		if (u) {
			setUser(u);
		}
	}, []);

	useEffectCustom(() => {
		const { data } = useLogin;
		if (data) {
			setUserData(data);
		}
	}, [useLogin.data]);

	useEffectCustom(() => {
		const { data } = useCreateAccount;
		if (data) {
			setUserData(data);
		}
	}, [useCreateAccount.data]);

	useEffect(() => {
		if (useLogin.info?.errorType === ErrorType.INVALID_SESSION) {
			clearUserData();
		}
	}, [useLogin.info]);

	const createAccount = (mUser: User, password: string, activationCode: string)
		: Promise<boolean> => useCreateAccount.execute(mUser, password, activationCode);

	const login = (email: string, password: string)
		: Promise<boolean> => useLogin.execute(email, password);

	const logout = async (): Promise<boolean> => {
		try {
			await useLogout.execute();
			clearUserData(); // clear the data even if the request fails
			const resp = await getInitialRequest();
			history.push(AppLinks.HOME);
			if (!resp) {
				window.location.reload();
			}
			return resp;
		} catch (error) {
			return false;
		}
	};

	const requestNewToken = async () => {
		// TODO Define flow to request a new token
	};

	const createReqInfo = useMemo<ReqInfo>(
		() => ({
			info: useCreateAccount.info,
			loading: useCreateAccount.loading,
			error: useCreateAccount.error,
		}),
		[useCreateAccount.info, useCreateAccount.error, useCreateAccount.loading],
	);

	const loginReqInfo = useMemo<ReqInfo>(
		() => ({
			info: useLogin.info,
			loading: useLogin.loading,
			error: useLogin.error,
		}),
		[useLogin.info, useLogin.error, useLogin.loading],
	);

	const logoutReqInfo = useMemo<ReqInfo>(
		() => ({
			info: useLogout.info,
			loading: useLogout.loading,
			error: useLogout.error,
		}),
		[useLogout.info, useLogout.error, useLogout.loading],
	);

	const value = useMemo(
		() => ({
			user,
			login,
			loginReqInfo,
			logout,
			logoutReqInfo,
			createAccount,
			createReqInfo,
			requestNewToken,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[user, loginReqInfo, logoutReqInfo, createReqInfo],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
	return useContext(AuthContext);
}
