/* eslint-disable react/no-unescaped-entities */
import {
	Container, Grid, Stack, TextField, Typography, styled, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { makeStyles } from '@mui/styles';
import {
	SimpleCardCategory, CardItineraryInfo, PersonalDataConfirm, Card,
} from 'components/molecules';
import { Footer } from 'components/organisms';
import Optional from '../../../../data/models/optional.model';
import mockOptionals from '../../../../data/mock/optionals.json';
import { Dimens } from '../../../../assets';
import { DefaultButton, Subtitle, Title } from '../../../atoms';

const CompleteReservationScreen = (): JSX.Element => {
	const [optionals, setOptionals] = React.useState<Optional[]>();

	const theme = useTheme();

	React.useEffect(() => {
		const arr = mockOptionals.slice(0, 4).map((item) => item as Optional);
		setOptionals(arr);
	}, []);

	const TextHighlighted = styled('span')(() => ({
		fontSize: Dimens.TEXT_DEFAULT as string,
		fontWeight: 600,
	}));

	const useStyles = makeStyles(() => ({
		noBorder: {
			border: 'none',
		},
	}));

	const classes = useStyles();

	return (
		<>
			<Box sx={{ background: theme.palette.bgSecondary?.firstColor, pb: 10 }}>
				<Container maxWidth="lg">
					<Grid container sx={{ pt: 2 }} spacing={2}>
						<Grid item xs={12} sm={6} md={4}>
							<Stack>
								<SimpleCardCategory showOptionals optionalsSelected={optionals} features={[]} />
								<CardItineraryInfo pickupLocation="TESTE A" returnLocation="TESTE B" pickupDate="2021-01-10 16:35:54" returnDate="2021-02-10 16:35:54" />
							</Stack>
						</Grid>

						<Grid item xs={12} sm={6} md={8}>
							<Stack spacing={2}>
								<PersonalDataConfirm />

								<Box sx={{ pl: Dimens.DEFAULT_PADDING, pr: Dimens.DEFAULT_PADDING }}>
									<Typography sx={{ mb: 2 }} color={theme.disabled} fontSize={Dimens.TEXT_DEFAULT} fontWeight="700">INFORMAÇÕES IMPORTANTES</Typography>
									<Subtitle fontSize={Dimens.TEXT_DEFAULT as string}>
										<p>
											•
											{' '}
											No momento de levantamento, deverá apresentar uma carta de condução actual e
										</p>
										<p>
											•
											{' '}
											{`o cartão de crédito ou débito que foi utilizado para efectuar o pagamento online.
										 Este TEM de ser apresentado pela pessoa em nome da qual a viatura foi reservada.`}

										</p>
										<p>
											•
											{' '}
											Poderão ser solicitados dois cartões de crédito para alguns grupos de viaturas
											.Mais detalhes em Formas de Pagamento.
										</p>
										<p>
											• É necessário um cartão de crédito para todos os alugueres,
											incluindo os que foram reservardos com um cartão de débito online.

										</p>
										<p>
											• Reservamos crédito no seu cartão de crédito no momento de levantamento
											da viatura,
											de modo a cobrir os custos estimados do seu aluguer,
											incluindo um tanque de combustível
											completo. O montante reservado será libertado com o
											pagamento dos custos do seu aluguer
											no momento de devolução.

										</p>
									</Subtitle>
								</Box>

								<Grid container spacing={2}>
									<Grid item xs={12} md={4}>
										<Card
											shadows={false}
											contentSx={{ height: '100%' }}
											content={(
												<>
													<Title fontSize={Dimens.TEXT_DEFAULT as string}>OBSERVAÇÕES</Title>

													<TextField
														label=""
														multiline
														rows={5}
														sx={{ border: 'none !important' }}
														variant="outlined"
														InputProps={{
															classes: { notchedOutline: classes.noBorder },
														}}
													/>
												</>
											)}
										/>
									</Grid>

									<Grid item xs={12} md={8}>
										<Card
											shadows={false}
											contentSx={{ height: '100%' }}
											content={(
												<Stack>
													<Typography
														sx={{ mb: 2 }}
														color={theme.disabled}
														fontSize={Dimens.TEXT_DEFAULT}
														fontWeight="700"
													>
														O QUE VOCÊ PAGA NO BALCÃO:

													</Typography>
													<Stack direction="row">
														<Stack justifyContent="start" alignItems="start">
															<Title>€ 756,38</Title>
															<Subtitle>252€ / dia</Subtitle>
														</Stack>
														<Box sx={{ flexGrow: 1 }} />
														<DefaultButton sx={{ alignSelf: 'center' }} text="Reservar" minWidth="150px" />
													</Stack>

													<Typography sx={{ mt: 2 }} color={theme.disabled} fontSize={Dimens.TEXT_DEFAULT} fontWeight="700">
														Ao clicar no botão
														<TextHighlighted>"Reservar"</TextHighlighted>
														, está a confirmar que compreende e aceita as Termos, Qualificações e
														Requisitos de Aluguer, Termos Condições e está a par das restrições
														de idade
													</Typography>
												</Stack>
											)}
										/>
									</Grid>
								</Grid>
							</Stack>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Footer />
		</>
	);
};

export default CompleteReservationScreen;
