/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import { ApiResponse, CategoryData } from '../../models';
import Endpoint from '../endpoints.api';
import { reqPost } from '../request.api';

export type FilterOptions = {
    startDate: string;
    endDate: string;
    pickupOrganizationId: number;
    returnOrganizationId: number;
    minPrice: number;
    maxPrice: number;
    categories?: number[];
    features?: number[];
}

const fetchCategories = async (options: FilterOptions): Promise<ApiResponse<CategoryData>> => {
	const startDate = new Date(options.startDate).toISOString();
	const endDate = new Date(options.endDate).toISOString();

	const filter = {
		...{
			categories: [],
			features: [],
		},
		...options,
	};
	const resp = await reqPost({
		url: Endpoint.CATEGORIES_BY_FILTER,
		data: { ...filter, startDate, endDate },
	});
	const data = resp.data as ApiResponse<CategoryData>;
	data.statusCode = resp.status;
	return data;
};

// eslint-disable-next-line arrow-body-style
export const useFetchCategories = () => {
	return useRequest(([options]) => fetchCategories(options as FilterOptions));
};
