import {
	Box, Stack, SvgIcon, SxProps, Theme, Typography, useTheme,
} from '@mui/material';
import { Dimens } from 'assets';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	getDayFromDate, getDayName, getMonthFormated, getReservationQtdDays, getTimeFormated,
} from 'utils/general.utils';
import { carIcon, RouteEnd, RouteStart } from 'assets/icons';
import { Subtitle } from 'components/atoms';
import { Card } from '..';

interface ReservationItineraryProps {
	pickupDate: string | undefined;
	returnDate: string | undefined;
	pickupLocation: string| undefined;
	returnLocation: string| undefined
}

const ReservationItineraryInfo: FC<ReservationItineraryProps> = (props) => {
	const {
		pickupDate, returnDate, pickupLocation, returnLocation,
	} = props;
	const { disabled, palette } = useTheme();
	const [duration, setDuration] = React.useState<number>();

	React.useEffect(() => {
		const days = getReservationQtdDays(pickupDate, returnDate);
		setDuration(days);
	}, [pickupDate, returnDate]);

	const textStyle = {
		color: disabled,
		fontWeight: 700,
		fontSize: Dimens.TEXT_DEFAULT,
	} as SxProps<Theme>;

	const dayStyle = {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '45px',
		height: '45px',
		background: palette.primary.main,
		borderRadius: 6,
	} as SxProps<Theme>;

	const traceStyle = {
		height: 2,
		width: 40,
		border: `1px dashed ${disabled}`,
		alignSelf: 'center',
	} as SxProps<Theme>;

	const { t } = useTranslation('translations');
	return (
		<Card
			shadows={false}
			content={(
				<>
					<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
						<Typography sx={textStyle}>{t('pickupAndReturn')}</Typography>
					</Box>
					<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ mt: 2, mb: 2 }}>
						<Box sx={dayStyle}>
							<Typography fontSize="12px" fontWeight="700" color="#ffffff">
								{getDayFromDate(pickupDate)}
							</Typography>
							<Typography fontSize="10px" fontWeight="700" color="#ffffff">
								{getMonthFormated(pickupDate).toUpperCase()}
							</Typography>
						</Box>
						<Box sx={{ ...traceStyle, mr: '10px !important' }} />
						<Stack direction="row" alignItems="center" justifyContent="center">
							<Stack direction="column" alignItems="center">
								<Typography sx={{ ...textStyle, fontSize: Dimens.TEXT_SUBTITLE, fontWeight: 500 }}>
									{duration}
									{' '}
									{!!duration && duration > 1 ? t('days') : t('day')}
								</Typography>
								<SvgIcon component={carIcon} />
							</Stack>
						</Stack>
						<Box sx={{ ...traceStyle, ml: '10px !important' }} />
						<Box sx={dayStyle}>
							<Typography fontSize="12px" fontWeight="700" color="#ffffff">
								{getDayFromDate(returnDate)}
							</Typography>
							<Typography fontSize="10px" fontWeight="700" color="#ffffff">
								{getMonthFormated(returnDate).toUpperCase()}
							</Typography>
						</Box>
					</Stack>
					<Stack direction="row" justifyContent="space-between">
						<Stack direction="row">
							<SvgIcon
								component={RouteStart}
								sx={{
									mr: 1,
								}}
								color="error"
								fontSize="medium"
								viewBox="0 0 30 30"
							/>
							<Box>
								<Subtitle>{pickupLocation}</Subtitle>
								<Typography fontSize="12px" fontWeight="400" color={palette.title?.color}>
									{getDayName(pickupDate)}
								</Typography>
								<Typography fontSize="12px" fontWeight="300" color={palette.title?.color}>
									{getTimeFormated(pickupDate)}
								</Typography>
							</Box>
						</Stack>
						<Stack direction="row">
							<Box>
								<Subtitle>{returnLocation}</Subtitle>
								<Typography fontSize="12px" fontWeight="400" sx={{ textAlign: 'right' }} color={palette.title?.color}>
									{getDayName(returnDate)}
								</Typography>
								<Typography fontSize="12px" fontWeight="300" sx={{ textAlign: 'right' }} color={palette.title?.color}>
									{getTimeFormated(returnDate)}
								</Typography>
							</Box>
							<SvgIcon
								component={RouteEnd}
								sx={{
									ml: 1,
								}}
								fontSize="medium"
								viewBox="0 0 30 30"
							/>
						</Stack>
					</Stack>
				</>
			)}
		/>
	);
};

export default ReservationItineraryInfo;
