import {
	Container,
	Grid,
	Stack,
	Box,
} from '@mui/material';
import React from 'react';
import { useInViewRef } from 'rooks';
import { useTranslation } from 'react-i18next';
import { CounterCard, CounterSkeletonCard } from 'components/molecules';
import { buildsBackgroundSvg } from 'assets/images';
import useEffectCustom from 'hooks/useEffectCustom';
import { Title, Subtitle } from '../../atoms';
import { organizationService } from '../../../data/api';

const FifthSection = (): JSX.Element => {
	const { execute, data, loading } = organizationService.useFetchOrganizations();
	const [myRef, inView] = useInViewRef();
	const { t } = useTranslation('translations');

	const counters = data?.map((item) => (
		<CounterCard
			key={item.id}
			md={3}
			sm={12}
			xs={12}
			latitude={item.latitude}
			longitude={item.longitude}
			name={item.fantasyName}
			address={`${item.fullAddress}, ${item.neighborhood}`}
			postalCode={item.postalCode}
			phone={item.phone}
			email={item.email}
			city={item.city.name}
		/>
	));

	useEffectCustom(() => {
		if (inView) {
			execute();
		}
	}, [inView]);

	return (
		<section id="counters" ref={myRef}>
			<Box
				sx={{
					background: {
						md: `url(${buildsBackgroundSvg}) repeat-x bottom`,
						xs: 'none',
					},
					position: 'relative',
					minHeight: '60vh',
				}}
			>
				<Container maxWidth="lg" sx={{ pt: 5, pb: { md: 45, xs: 5 }, position: 'relative' }}>
					<Title>{t('rentalStationsNearYou')}</Title>
					<Subtitle>{t('completeStationsList')}</Subtitle>

					<Grid container className="no-scroll" sx={{ mt: 1, overflowX: 'scroll', overflowY: 'initial' }}>
						<Grid item xs={12}>
							{!loading && (
								<Grid container spacing={3} sx={{ mt: 4, mb: 4 }}>
									{counters}
								</Grid>
							)}
						</Grid>

						{loading && (
							<Stack direction="row" spacing={4} sx={{ mt: 4, mb: 4 }}>
								<>
									<CounterSkeletonCard md={4} sm={6} xs={12} />
									<CounterSkeletonCard md={4} sm={6} xs={12} />
									<CounterSkeletonCard md={4} sm={6} xs={12} />
									<CounterSkeletonCard md={4} sm={6} xs={12} />
									<CounterSkeletonCard md={4} sm={6} xs={12} />
									<CounterSkeletonCard md={4} sm={6} xs={12} />
								</>
							</Stack>
						)}
					</Grid>
				</Container>
			</Box>
		</section>
	);
};

export default FifthSection;
