import {
	Stack, styled, Card, useTheme, Box, SxProps,
} from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { BillableItem } from 'data/models';
import Dimens from 'assets/dimens';
import { useSettingsContext } from 'providers';
import { convertAndFormatToCurrency } from 'utils/general.utils';
import { LocalPolice } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Subtitle } from '../../atoms';

interface CardOptionalsListProps {
	title: string;
   data: BillableItem[];
   sx?: SxProps;
   emptyMessage?: string;
}

const CardOptionalList: React.FC<CardOptionalsListProps> = ({
	title, data, sx, emptyMessage,
}) => {
	const { currency, colors } = useSettingsContext();
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const ShortTitle = styled('div')(() => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 600,
		color: theme.disabled,
	}));

	const optionStyle = {
		background: theme.palette.bgSecondary?.firstColor,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: 2,
		p: 1,
	} as SxProps;

	const optionalButtons = data.map((item) => (
		<Box key={item.id} sx={optionStyle}>
			<CheckIcon sx={{ width: 20, color: theme.palette.subtitle?.color }} />
			<Subtitle fontSize={Dimens.TEXT_SUBTITLE as string} sx={{ ml: 0.5, fontWeight: '600 !important' }}>
				{item.name}
			</Subtitle>
			<Box sx={{ flexGrow: 1 }} />
			<Subtitle fontSize={Dimens.TEXT_SUBTITLE as string} sx={{ ml: 0.5, whiteSpace: 'nowrap', color: colors.textDisabled }}>
				<strong>
					{item.value ? convertAndFormatToCurrency(item.value * (1 + ((item.taxAmount || 0) / 100)), currency) : '-'}
				</strong>
				<span style={{ fontSize: '10px' }}>
					/
					{' '}
					{t('day')}
				</span>
			</Subtitle>
		</Box>
	));

	return (
		<Card
			sx={{
				mt: 2,
				flex: 1,
				padding: Dimens.DEFAULT_PADDING,
				boxShadow: 'none',
				borderRadius: Dimens.DEFAULT_RADIUS,
				...sx,
			}}
		>
			<Stack direction="row" alignItems="center">
				<ShortTitle>{title}</ShortTitle>
				<Box sx={{ flexGrow: 1 }} />
				<LocalPolice sx={{ color: theme.palette.primary.main, width: 20 }} />
			</Stack>

			<Stack direction="column" spacing={1} sx={{ mt: 2 }}>
				{optionalButtons.length > 0 ? optionalButtons : emptyMessage}
			</Stack>
		</Card>
	);
};

export default CardOptionalList;
