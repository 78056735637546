import {
	Divider,
	FormControl, Input, InputAdornment, InputLabel, SvgIcon, SxProps, useTheme,
} from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { MuiSvgType } from 'utils/mui.utils';
import { Dimens } from '../../../assets';

interface DefaultInputProps {
    label?: string;
    icon?: MuiSvgType;
    iconColor?: string;
    checked?: boolean | undefined;
    onChange?: (value: string) => void;
    type?: string;
    value?: string;
    autoComplete?: string;
    fullWidth?:boolean;
    readOnly?:boolean;
	hideUnderline?: boolean;
	sx?: SxProps
}

const DefaultInput: React.FC<DefaultInputProps> = ({
	label,
	icon,
	iconColor,
	checked,
	onChange,
	type,
	value,
	autoComplete,
	fullWidth,
	readOnly,
	hideUnderline,
	sx,
}) => {
	const theme = useTheme();
	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		onChange?.call(0, event.target.value);
	};

	const setIcon = () => {
		if (icon) {
			return {
				startAdornment: (
					<InputAdornment position="start">
						<SvgIcon color="primary" component={icon} sx={{ ...iconColor ? { color: iconColor } : {}, width: 20 }} />
					</InputAdornment>
				),
			};
		}
		return {};
	};

	const checkedIcon = () => {
		if (checked) {
			return {
				endAdornment: (
					<InputAdornment position="end">
						<SvgIcon color="primary" component={CheckIcon} sx={{ width: 20 }} />
					</InputAdornment>
				),
			};
		}

		return {};
	};

	return (
		<FormControl variant="standard">
			{label && (
				<InputLabel
					sx={{ fontSize: Dimens.TEXT_DEFAULT, color: theme.lighter }}
				>
					{label}
				</InputLabel>
			)}

			<Input
				fullWidth={fullWidth}
				onChange={handleChange}
				type={type}
				sx={{ ...sx, color: theme.palette.title?.color }}
				value={value || ''}
				autoComplete={autoComplete}
				readOnly={readOnly}
				disableUnderline
				{...setIcon()}
				{...checkedIcon()}
			/>
			{!hideUnderline && <Divider />}
		</FormControl>
	);
};

export default DefaultInput;
