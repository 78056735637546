import {
	LinearProgress, SxProps, Stack, SvgIcon, Typography, useTheme, Box,
} from '@mui/material';
import React, { FunctionComponent, SVGProps } from 'react';
import { MuiSvgType } from 'utils/mui.utils';
import { Dimens } from '../../../assets';

interface LargeButtonProps {
    icon: MuiSvgType | FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
    label: string;
    onClick?: () => void;
    loading?:boolean;
    selected?:boolean;
}

const LargeButton: React.FC<LargeButtonProps> = ({
	label,
	icon,
	onClick,
	loading,
	selected,
}) => {
	const theme = useTheme();
	const tabStyle = {
		background: selected ? theme.palette.selected?.bgColor : theme.palette.bgSecondary?.firstColor,
		flex: 1,
		height: '50px',
		pl: Dimens.DEFAULT_PADDING,
		pr: Dimens.DEFAULT_PADDING,
		pt: 1,
		pb: 1,
		borderRadius: Dimens.DEFAULT_RADIUS,
		display: 'flex',
		flexDirection: 'column',
		gap: 1,
		alignItems: 'center',
		justifyContent: 'center',
	} as SxProps;

	const handleClick = () => {
		onClick?.call(0);
	};

	return (
		<Box onClick={handleClick} style={{ cursor: 'pointer', flex: 1 }}>
			<Box sx={tabStyle}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<SvgIcon
						component={icon}
						sx={{
							width: 16,
							...(selected ? { color: theme.palette.selected?.color }
								: { color: theme.palette.title?.color }),
						}}
					/>
					<Typography
						sx={{
							fontSize: Dimens.TEXT_DEFAULT,
							...(selected ? { color: theme.palette.selected?.color }
								: { color: theme.palette.title?.color }),
						}}
					>
						{label}
					</Typography>
				</Stack>
				{loading && (
					<Box sx={{ width: '100%' }}>
						<LinearProgress color="inherit" />
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default LargeButton;
