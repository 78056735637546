import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Typography, SxProps } from '@mui/material';
import { useSettingsContext } from '../../../providers';
import { convertAndFormatToCurrency } from '../../../utils/general.utils';

interface PriceInfoProps {
	amount: number | undefined;
	oldAmount?: number | undefined;
	pricePerDay?: number;
	oldPricePerDay?: number;
	label?: string;
	notConvert?: boolean;
	sx?: SxProps;
}

const PriceInfo: React.FC<PriceInfoProps> = ({
	amount,
	pricePerDay,
	notConvert,
	oldAmount,
	oldPricePerDay,
	sx,
	label,
}) => {
	const { currency } = useSettingsContext();

	const OldValue = styled(Typography)(({ theme }) => ({
		textDecoration: 'line-through',
		color: theme.palette.subtitle2?.color,
	}));

	const Price = styled('div')(({ theme }) => ({
		fontSize: '24px',
		fontWeight: 700,
		color: theme.palette.primary.main,
		whiteSpace: 'nowrap',
	}));

	const mOldAmount = oldAmount ? convertAndFormatToCurrency(oldAmount, !notConvert ? currency : undefined) : '-';
	const mAmount = amount ? convertAndFormatToCurrency(amount, !notConvert ? currency : undefined) : '-';
	const mOldPricePerDay = oldPricePerDay ? convertAndFormatToCurrency(oldPricePerDay, !notConvert ? currency : undefined) : '-';
	const mPricePerDay = pricePerDay ? convertAndFormatToCurrency(pricePerDay, !notConvert ? currency : undefined) : '-';

	const isPriceDiff = mOldAmount !== mAmount || mOldPricePerDay !== mPricePerDay;

	return (
		<Stack direction="column" sx={sx}>
			{isPriceDiff && oldAmount && (
				<OldValue>
					{mOldAmount}
				</OldValue>
			)}
			<Price>{mAmount}</Price>
			{(!!label && !!pricePerDay) && (
				<Stack direction="row" spacing={1}>
					{isPriceDiff && oldPricePerDay && (
						<OldValue fontSize={12}>
							{mOldPricePerDay}
						</OldValue>
					)}
					<Typography alignSelf="end" sx={{ whiteSpace: 'nowrap' }}>
						{mPricePerDay}
						{' '}
						/
						{label}
					</Typography>
				</Stack>
			)}
		</Stack>
	);
};

export default PriceInfo;
