import { Box } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';

import React from 'react';
import { AppBar } from 'components/molecules';
import { useTheme } from '@mui/material';
import MainRoute from './main-route.routes';

const AppRouter = (): JSX.Element => {
	const theme = useTheme();
	return (
		<div>
			<CssBaseline />
			<AppBar />
			<Box sx={{ mt: `${parseInt(String(theme.mixins.toolbar.minHeight), 10) + 52}px` }}>
				<MainRoute />
			</Box>
		</div>
	);
};

export default AppRouter;
