/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRequest } from '..';
import { ApiResponse, User } from '../../models';
import Endpoint from '../endpoints.api';
import { reqPost } from '../request.api';

const login = async (email: string, password: string): Promise<ApiResponse<User>> => {
	const resp = await reqPost({
		url: Endpoint.AUTHENTICATE,
		data: { email, password },
	});
	const data = resp.data as ApiResponse<User>;
	data.statusCode = resp?.status;
	return data;
};
export const useLogin = () => useRequest(([email, password]) => login(email, password));

const logout = async (): Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.LOGOUT,
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp?.status;
	return data;
};
export const useLogout = () => useRequest<void>(() => logout());

const forgotPassword = async (email: string): Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.FORGOT_PASSWORD,
		data: {
			email,
		},
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp?.status;
	return data;
};
export const useForgotPassword = () => useRequest(([email]) => forgotPassword(email));

const sendNumberToVerification = async (countryID: number, phone: string, context: string)
    : Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.SEND_NUMBER_TO_VERIFICATION,
		data: {
			countryID,
			phone,
			context,
		},
	});

	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp?.status;
	return data;
};
export const useSendNumberToVerification = () => useRequest((
	[countryID, phone, context],
) => sendNumberToVerification(countryID, phone, context));

const createAccount = async (user: User, password: string, activationCode: string)
    : Promise<ApiResponse<User>> => {
	const resp = await reqPost({
		url: Endpoint.CREATE_ACCOUNT,
		data: {
			name: user.name,
			email: user.email,
			password,
			phone: user.phone,
			activationCode,
			country: user.phoneCountry.id,
		},
	});
	const data = resp.data as ApiResponse<User>;
	data.statusCode = resp?.status;
	return data;
};
export const useCreateAccount = () => useRequest(([
	user, password, activationCode,
]) => createAccount(user, password, activationCode));

const changePassword = async (currentPassword: string, newPassword: string)
    : Promise<ApiResponse<null>> => {
	const resp = await reqPost({
		url: Endpoint.TERMINATE_ACCOUNT,
		data: { currentPassword, newPassword },
	});
	const data = resp.data as ApiResponse<null>;
	data.statusCode = resp?.status;
	return data;
};
export const useChangePassword = () => useRequest(([
	currentPassword, newPassword,
]) => changePassword(currentPassword, newPassword));

const terminateAccount = async ()
    : Promise<ApiResponse<null>> => {
	const resp = await reqPost({
		url: Endpoint.TERMINATE_ACCOUNT,
	});
	const data = resp.data as ApiResponse<null>;
	data.statusCode = resp?.status;
	return data;
};
export const useTerminateAccount = () => useRequest(() => terminateAccount());
