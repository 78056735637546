/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
	createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as locales from '@mui/material/locale';
import Locale, { enUS, pt } from 'date-fns/locale';
import { Country } from '../data/models';

import mockCountries from '../assets/localization/supported-languages.json';
import { getStorageItem, setStorageItem, StorageKeys } from '../data/storage';

type SupportedLocales = keyof typeof locales;

export interface LanguageContextProps {
   locale: locales.Localization;
   changeLocale: (item: Country) => void;
   localeFns: Locale;
}

const localeMap = {
	enUS,
	ptPT: pt,
};

export const LanguageContext = createContext<LanguageContextProps>({} as LanguageContextProps);

export const LanguageProvider: React.FC = ({ children }) => {
	const savedLocaleJson: keyof typeof localeMap = getStorageItem(StorageKeys.LANGUAGE)
		?? mockCountries[0].phoneCode;
	const savedLocale = locales[savedLocaleJson as SupportedLocales];
	const { i18n } = useTranslation();
	const [locale, setLocale] = useState<locales.Localization>(savedLocale);
	const [localeFns, setLocaleFns] = useState<Locale>(localeMap[savedLocaleJson]);

	const changeLocale = useCallback((lang: Country) => {
		// change translations
		i18n.changeLanguage(lang.isoCode);

		// update language
		const mLocale = locales[lang.phoneCode as SupportedLocales];
		setLocale(mLocale);
		setLocaleFns(localeMap[lang.phoneCode as keyof typeof localeMap]);

		// save new language
		setStorageItem(StorageKeys.LANGUAGE, lang);
	}, [i18n]);

	const value = useMemo(
		() => ({
			locale,
			changeLocale,
			localeFns,
		}),
		[changeLocale, locale, localeFns],
	);

	return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export default function useLanguage() {
	return useContext(LanguageContext);
}
