import { Skeleton, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Dimens } from 'assets';
import { BillableItem } from 'data/models';
import { getPriceWithVAT, getOptionalValueWithDiscount, generateKey } from 'utils/general.utils';
import { OptionalItemButton } from '../../atoms';
import { Card } from '..';

interface OptionalItemsSelectorProps {
    title: string
    optionals: BillableItem[];
    loading?: boolean;
    minHeight?: number;
    onSelect?: (values: BillableItem[])=>void

}

const OptionalItemsSelector: React.FC<OptionalItemsSelectorProps> = ({
	title, optionals, loading, minHeight, onSelect,
}) => {
	const [value, setValue] = React.useState<BillableItem[] | undefined>(undefined);
	const theme = useTheme();

	const isSelected = (item: BillableItem) => {
		if (value === undefined) return false;
		return value.indexOf(item) !== -1;
	};

	const addValue = (item:BillableItem) => {
		setValue((state) => {
			if (state) {
				const arr = state;
				const index = arr.indexOf(item);
				let nArr = [];
				if (index === -1) {
					nArr = [...arr, item];
				} else {
					arr.splice(index, 1);
					nArr = [...arr];
				}
				onSelect?.call(0, nArr);
				return nArr;
			}
			onSelect?.call(0, [item]);
			return [item];
		});
	};

	const optionalButtons = optionals.map(
		(item) => (
			<OptionalItemButton
				key={generateKey(`optional_${item.id}`)}
				selected={isSelected(item)}
				text={item.name}
				price={getPriceWithVAT(item.value)}
				priceWithDiscount={getOptionalValueWithDiscount(item)}
				onClick={() => addValue(item)}
			/>
		),
	);

	return (
		<Card
			shadows={false}
			contentSx={{ minHeight }}
			content={(
				<>
					<Typography sx={{ mb: 2 }} color={theme.disabled} fontSize={Dimens.TEXT_DEFAULT} fontWeight="700">{title}</Typography>
					<Box sx={{
						display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, justifyContent: 'start',
					}}
					>
						{!!loading && (
							<>

								<Skeleton variant="rectangular" height={35} width={150} />
								<Skeleton variant="rectangular" height={35} width={180} />
								<Skeleton variant="rectangular" height={35} width={100} />
								<Skeleton variant="rectangular" height={35} width={160} />

								<Skeleton variant="rectangular" height={35} width={180} />
								<Skeleton variant="rectangular" height={35} width={180} />
								<Skeleton variant="rectangular" height={35} width={100} />
								<Skeleton variant="rectangular" height={35} width={160} />

								<Skeleton variant="rectangular" height={35} width={100} />
								<Skeleton variant="rectangular" height={35} width={180} />
								<Skeleton variant="rectangular" height={35} width={100} />
								<Skeleton variant="rectangular" height={35} width={160} />

							</>
						)}

						{!loading && optionalButtons }
					</Box>
				</>
			)}
		/>
	);
};

export default OptionalItemsSelector;
