import { Key } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack,
} from '@mui/material';
import { DefaultInput } from 'components/atoms';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordValidation, validatePassword } from 'utils/general.utils';

interface ChangePasswordProps {
	open: boolean;
	handleClose: () => void;
	handleChangePassword: (currentPassword: string, newPassword: string) => void
}

const ChangePasswordDialog: FC<ChangePasswordProps> = (props) => {
	const { open, handleClose, handleChangePassword } = props;
	const { t } = useTranslation('translations');
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordValidation, setPasswordValidation] = useState<PasswordValidation[]>();

	useEffect(() => {
		setPasswordValidation(validatePassword(newPassword));
	}, [newPassword, confirmPassword]);

	const enableCreate = () => (
		(oldPassword.length > 0
		&& passwordValidation?.length === 0
         && newPassword === confirmPassword)
	);

	const handleClick = () => {
		handleChangePassword.call(0, oldPassword, newPassword);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{t('changePassword')}</DialogTitle>
			<DialogContent>
				<DialogContentText>{t('changePasswordDesc')}</DialogContentText>
				<Stack spacing={2} sx={{ mt: 2 }}>
					<DefaultInput
						type="password"
						autoComplete="new-password"
						label={`${t('old').toUpperCase()} ${t('password').toUpperCase()}`}
						value={oldPassword}
						onChange={(value) => setOldPassword(value)}
						icon={Key}
					/>
					<DefaultInput
						type="password"
						autoComplete="new-password"
						label={`${t('new').toUpperCase()} ${t('password').toUpperCase()}`}
						value={newPassword}
						onChange={(value) => setNewPassword(value)}
						icon={Key}
					/>
					<DefaultInput
						type="password"
						autoComplete="new-password"
						label={`${t('confirm').toUpperCase()} ${t('password').toUpperCase()}`}
						checked={newPassword === confirmPassword && passwordValidation?.length === 0}
						value={confirmPassword}
						onChange={(value) => setConfirmPassword(value)}
						icon={Key}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleClose}>
					{t('cancel')}
				</Button>
				<LoadingButton
					disabled={!enableCreate}
					// loading={loading}
					variant="text"
					color="secondary"
					onClick={handleClick}
				>
					{t('send')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default ChangePasswordDialog;
