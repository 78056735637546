/* eslint-disable @typescript-eslint/no-explicit-any */
// import { DatePicker } from '@mui/lab';
import { ArrowForwardIos } from '@mui/icons-material';
import {
	Card, Grid, Stack, styled, useTheme,
} from '@mui/material';
import { StartLocation } from 'assets/icons';
import { DatePicker, DefaultButton } from 'components/atoms';
import { SelectCounter } from 'components/organisms';
import { organizationService } from 'data/api';
import { Filter, FilterMinimal, Organization } from 'data/models';
import useEffectCustom from 'hooks/useEffectCustom';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CardItineraryEditProps{
	filter?: Filter;
	handleItineraryClick: (filter: FilterMinimal) => void
}

const CardItineraryEdit: FC<CardItineraryEditProps> = ({ filter, handleItineraryClick }) => {
	const { card, palette } = useTheme();
	const { t } = useTranslation('translations');

	const [mFilter, setFilter] = useState({
		pickupLocation: {} as Organization,
		returnLocation: {} as Organization,
		startDate: {} as Date,
		endDate: {} as Date,
	});

	useEffect(() => {
		if (filter) {
			setFilter({
				pickupLocation: filter.pickupLocation as Organization,
				returnLocation: filter.returnLocation as Organization,
				startDate: filter.startDate as Date,
				endDate: filter.endDate as Date,
			});
		}
	}, [filter]);

	const organizations = organizationService.useFetchOrganizations();
	const StyledCard = styled(Card)(() => ({
		// height: '64px',
		marginBottom: '28px',
		border: 'none',
		boxShadow: 'none',
		width: '100%',
		borderRadius: card.radius,
	}));

	useEffectCustom(() => {
		organizations.execute();
	});

	const handleChangeLocally = (key: keyof Filter, value: Organization | Date | undefined) => {
		setFilter({ ...mFilter, [key]: value });
	};

	const checkDateAfterEnd = (date: Date) => {
		if (date.isSameOrAfter(mFilter.endDate)) {
			setFilter({ ...mFilter, startDate: date, endDate: date.nextDay() });
		} else {
			handleChangeLocally('startDate', date);
		}
	};

	return (
		<StyledCard variant="outlined">
			<Grid container spacing={3} sx={{ alignItems: 'center', pl: 2 }}>
				<Grid item lg={2.25} xl={2.25} md={2.25} sm={12} xs={12}>
					<SelectCounter
						onChange={(item) => {
							handleChangeLocally('pickupLocation', item);
						}}
						icon={StartLocation}
						data={organizations.data}
						mt={0}
						value={mFilter?.pickupLocation ?? undefined}
						innerMarginTop={0}
						innerMarginBottom={0}
					/>
				</Grid>
				<Grid item lg={2.25} xl={2.25} md={2.25} sm={12} xs={12}>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<DatePicker
							value={new Date(mFilter?.startDate || '')}
							minDate={new Date()}
							handleChange={(date) => {
								checkDateAfterEnd(date);
							}}
						/>
					</Stack>
				</Grid>
				<Grid item lg={1} xl={1} md={1} sm={12} xs={12}>
					<ArrowForwardIos sx={{ color: palette.title?.color, fontSize: '24px' }} />
				</Grid>
				<Grid item lg={2.25} xl={2.25} md={2.25} sm={12} xs={12}>
					<SelectCounter
						onChange={(item) => {
							handleChangeLocally('returnLocation', item);
						}}
						icon={StartLocation}
						data={organizations.data}
						mt={0}
						value={mFilter?.returnLocation ?? undefined}
						innerMarginTop={0}
						innerMarginBottom={0}
					/>
				</Grid>
				<Grid item lg={2.25} xl={2.25} md={2.25} sm={12} xs={12}>
					<DatePicker
						value={new Date(mFilter?.endDate || '')}
						handleChange={(date) => {
							handleChangeLocally('endDate', date);
						}}
					/>
				</Grid>
				<Grid item lg={2} xl={2} md={2} sm={12} xs={12} sx={{ height: '100%' }}>
					<DefaultButton
						sx={{
							width: '100%',
							height: '100%',
							borderTopRightRadius: card.radius,
							borderBottomRightRadius: card.radius,
							borderBottomLeftRadius: 0,
							borderTopLeftRadius: 0,
							bgcolor: palette.title?.color,
							'&:hover': {
								bgcolor: palette.title?.color,
							},
						}}
						text={t('search')}
						onClick={() => handleItineraryClick(mFilter)}
					/>
				</Grid>
			</Grid>
		</StyledCard>
	);
};

export default CardItineraryEdit;
