/* eslint-disable no-nested-ternary */
import {
	Button, Dialog, DialogTitle, Grid, Skeleton, Stack,
	useTheme, Card as MUICard, Typography, useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/system';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Dimens } from 'assets';
import { GalleryImage } from 'components/molecules/gallery-carousel/GalleryCarousel';
import { billableItemService, reservationService, cardService } from 'data/api';
import { useAuth, useFeedbackContext, useSettingsContext } from 'providers';
import {
	Category, QuantityDiscount, CampaignDiscount,
	BillableItem, Filter, InitialSettings, ApiResponseBase, Card as NNCard,
} from 'data/models';
import { ReservationCreateData } from 'data/models/reservation.model';
import { getStorageItem, StorageKeys } from 'data/storage';
import {
	getPricePerDayWithDiscount, getReservationQtdDays, dateToString,
	setBillableItemDiscounts, getReservationFinalValue, getPriceWithVAT, generateKey,
} from 'utils/general.utils';
import useEffectCustom from 'hooks/useEffectCustom';
import CategoryShortCard from 'components/molecules/card/CategoryShortCard';
import {
	CalendarViewDay,
	Cancel, Close, Payment, Person,
} from '@mui/icons-material';
import { SelectMode } from '../../../molecules/card-selector/CardSelector';
import LabelIconBox from '../../../atoms/label-icon-box/LabelIconBox';
import {
	Card,
	GalleryCarousel,
	SimpleArticle,
	OptionalItemsSelector,
	PersonalDataConfirm,
	ConfirmDialog,
	CardSelector,
	ReservationItineraryInfo,
} from '../../../molecules';
import {
	TransitionSlideUp, CheckedLabel, LabelIcon, PriceInfo, DefaultButton,
} from '../../../atoms';

interface CategoryDialogProps {
   open: boolean;
   onClose?: () => void;
   category: Category;
   itemsDiscount: QuantityDiscount[];
   campaigns: CampaignDiscount[];
   included: string[];
   notIncluded: string[];
   images?: GalleryImage[];
   loadingImages?: boolean;
   handleSuccessOpen: () => void
}

const CategoryDialog: React.FC<CategoryDialogProps> = ({
	open,
	onClose,
	category,
	itemsDiscount,
	campaigns,
	included,
	notIncluded,
	images,
	loadingImages,
	handleSuccessOpen,
}) => {
	const { t } = useTranslation('translations');

	const [filter, setFilter] = useState<Filter>();
	const [numberOfDays, setNumberOfDays] = useState(0);
	const [optionals, setOptionals] = useState<BillableItem[]>([]);
	const [selectedOptionals, setSelectedOptionals] = useState<BillableItem[]>([]);
	const [selCard, setSelCard] = useState<NNCard>();
	const [openConfirm, setOpenConfirm] = useState<boolean>(false);
	const [stripeLoading, setStripeLoading] = useState<boolean>(false);
	const [cardSelectMode, setCardSelectMode] = useState<SelectMode>('saved-card');
	const [stripeElementComplete, setStripeElementComplete] = useState<boolean>(false);
	const [stripeShouldSaveCard, setStripeShouldSaveCard] = useState<boolean>(false);

	const pricePerDay = getPriceWithVAT(category.pricePerDay);
	const pricePerDayWithDiscount = getPricePerDayWithDiscount(
		numberOfDays,
		category.pricePerDay,
		category.id,
		itemsDiscount,
		campaigns,
		true,
	);

	const [finalValue, setFinalValue] = useState<number>();

	const { user } = useAuth();
	const { setAlert } = useFeedbackContext();
	const theme = useTheme();
	const stripe = useStripe();
	const stripeElements = useElements();
	const appSettings = getStorageItem(StorageKeys.INITIAL_SETTINGS) as InitialSettings;
	const { colors } = useSettingsContext();

	const useFetchOptionals = billableItemService.useFetchOptionals();
	const useNewReservation = reservationService.useNewReservation();
	const useCompletePayment = reservationService.useCompletePayment();
	const useFetchCards = cardService.useFetchPaymentMethods();

	const isSM = useMediaQuery(theme.breakpoints.down('sm'));
	const isXS = useMediaQuery(theme.breakpoints.down('xs'));

	const StyledCard = styled(MUICard)(() => ({
		border: 'none',
		boxShadow: 'none',
		padding: theme.card.padding,
		borderRadius: theme.card.radius,
	}));

	useEffectCustom(() => {
		const mFilter = getStorageItem(StorageKeys.FILTER) as Filter;
		if (mFilter) {
			setFilter(mFilter);
		}
		setSelectedOptionals([]);
		useFetchOptionals.execute();
		useFetchCards.execute();
	}, [open]);

	useEffectCustom(() => {
		const { data } = useFetchCards;
		if (data && data.length > 0) {
			setCardSelectMode('saved-card');
		}
	}, [useFetchCards.data]);

	useEffect(() => {
		if (filter) {
			setNumberOfDays(
				getReservationQtdDays(
					dateToString(filter?.startDate, filter?.startTime),
					dateToString(filter?.endDate, filter?.endTime),
				),
			);
		}
	}, [filter]);

	useEffect(() => {
		if (useFetchOptionals.data) {
			const list = [...useFetchOptionals.data];
			setBillableItemDiscounts(numberOfDays, category.id || -1, list, itemsDiscount, campaigns);
			setOptionals(list);
		}
	}, [campaigns, category.id, itemsDiscount, numberOfDays, useFetchOptionals.data]);

	useEffect(() => {
		const final = getReservationFinalValue(
			numberOfDays,
			getPricePerDayWithDiscount(
				numberOfDays,
				category.pricePerDay,
				category.id || -1,
				itemsDiscount,
				campaigns,
			),
			[...selectedOptionals],
		);
		setFinalValue(final);
	}, [
		selectedOptionals,
		numberOfDays,
		pricePerDay,
		category.id,
		itemsDiscount,
		campaigns,
		category.pricePerDay,
	]);

	const handleError = useCallback((info: ApiResponseBase) => {
		if (info.statusCode === 200) {
			handleSuccessOpen();
		} else {
			setAlert({
				message: info.message,
				type: info.statusCode !== 200 ? 'error' : 'success',
				duration: 3000,
			});
		}
	}, [setAlert, handleSuccessOpen]);

	useEffect(() => {
		if (useNewReservation.info) {
			handleError(useNewReservation.info);
		}
	}, [handleError, useNewReservation]);

	useEffect(() => {
		if (useCompletePayment.info) {
			handleError(useCompletePayment.info);
		}
	}, [handleError, useCompletePayment]);

	const ShortTitle = styled('div')(() => ({
		fontSize: Dimens.TEXT_DEFAULT,
		fontWeight: 600,
		color: theme.disabled,
	}));

	const handleClose = () => {
		if (useNewReservation.loading) return;
		onClose?.call(0);
	};
	const handleClick = () => {
		setOpenConfirm(true);
	};

	const handleCardClick = (item: NNCard | undefined) => {
		setSelCard(item);
	};

	const handleCardSelectModeChange = (mode: SelectMode) => {
		setCardSelectMode(mode);
	};

	const processStripePayment = async (request: ReservationCreateData) => {
		try {
			setStripeLoading(true);
			if (!stripe || !stripeElements) {
				return;
			}
			const intent = await reservationService.createPaymentSalt(request);
			const cardElement = stripeElements.getElement(CardElement);
			if (!cardElement) {
				return;
			}

			const shouldSaveCard = cardSelectMode === 'saved-card' || !stripeShouldSaveCard;

			const payWithNewCard = {
				card: cardElement,
				billing_details: {
					name: user?.name,
					email: user?.email,
				},
				setup_future_usage: cardSelectMode === 'saved-card' || !stripeShouldSaveCard ? 'on_session' : 'off_session',
			};

			const response = await stripe.confirmCardPayment(intent.data.clientSecret, {
				payment_method:
					cardSelectMode === 'saved-card' && request.cardId ? request.cardId : payWithNewCard,
				...(shouldSaveCard ? {} : { setup_future_usage: 'off_session' }),
			});

			if (response.error) {
				setAlert({
					message: response.error.message || '',
					type: 'error',
				});
				return;
			}
			useCompletePayment.execute(intent.data.clientSecret);
		} catch (error) {
			// TODO something
		} finally {
			setStripeLoading(false);
		}
	};
	const processPayment = () => {
		const request = {
			pickupOrganizationId: filter?.pickupLocation?.id,
			returnOrganizationId: filter?.returnLocation?.id,
			categoryId: category.id,
			sugestedCarId: category.suggestedCarId,
			startDate: dateToString(filter?.startDate, filter?.startTime),
			endDate: dateToString(filter?.endDate, filter?.endTime),
			optionals: selectedOptionals.map((item) => item.id),
		} as ReservationCreateData;

		if (appSettings.requireCreditPayment) {
			processStripePayment({ ...request, cardId: selCard?.id });
		} else {
			useNewReservation.execute(request);
		}
	};

	const handleConfirmClick = () => {
		setOpenConfirm(false);
		processPayment();
	};

	const handleConfirmClose = () => {
		setOpenConfirm(false);
	};

	const articleAnswerFont: string = Dimens.TEXT_DEFAULT;

	return (
		<>
			<Dialog
				open={open}
				TransitionComponent={TransitionSlideUp}
				maxWidth="lg"
				fullScreen={isSM || isXS}
				onClose={handleClose}
				PaperProps={{
					style: {
						background: theme.palette.bgSecondary?.firstColor,
					},
				}}
			>
				<>
					<DialogTitle>
						<Stack direction="row">
							<Box sx={{ flexGrow: 1 }} />
							<Button onClick={handleClose} autoFocus color="inherit" disableRipple>
								<Stack alignItems="center">
									<Close />
									<Typography sx={{ fontSize: Dimens.TEXT_SMALL }}>
										Esc
									</Typography>
								</Stack>

							</Button>
						</Stack>
					</DialogTitle>

					<DialogContent>
						<Grid container sx={{ mb: 5 }} spacing={2}>
							<Grid item xs={12} md={5}>
								<Stack spacing={2}>
									<CategoryShortCard
										title={category.cta}
										subtitle={category.name}
										image={category.image}
										pricePerDay={pricePerDay}
										totalPrice={pricePerDay * numberOfDays}
										pricePerDayWithDiscount={pricePerDayWithDiscount}
										totalPriceWithDiscount={pricePerDayWithDiscount * numberOfDays}
										features={category.features}
									/>

									<SimpleArticle childTextSize={articleAnswerFont} title={t('whatSimilarMeans')}>{t('whatSimilarMeansInfo')}</SimpleArticle>

									<ReservationItineraryInfo
										pickupLocation={filter?.pickupLocation?.fantasyName}
										returnLocation={filter?.returnLocation?.fantasyName}
										pickupDate={dateToString(filter?.startDate, filter?.startTime)}
										returnDate={dateToString(filter?.endDate, filter?.endTime)}
									/>

									<OptionalItemsSelector
										title={t('addOptionals').toUpperCase()}
										minHeight={250}
										loading={useFetchOptionals.loading}
										optionals={optionals}
										onSelect={(values) => setSelectedOptionals(values)}
									/>

									<StyledCard>
										<ShortTitle>{t('whatIsIncluded').toUpperCase()}</ShortTitle>
										<Grid container sx={{ mt: 0.5 }} spacing={2}>
											<Grid item xs={12} sm={6} md={6} alignItems="start">
												<Stack direction="column" alignItems="start">
													<CheckedLabel label={t('included').toString()} />
													{included.map((item) => (
														<Typography
															key={generateKey(item)}
															sx={{ borderBottom: `solid 1px ${theme.palette.divider}`, p: 0.5 }}
														>
															{item}
														</Typography>
													))}
												</Stack>
											</Grid>
											<Grid item xs={12} sm={6} md={6}>
												<Stack direction="column" alignItems="start">
													<LabelIcon
														iconColor={colors.error}
														icon={Cancel}
														label={t('notIncluded').toString()}
													/>
													{notIncluded.map((item) => (
														<Typography
															key={generateKey(item)}
															sx={{ borderBottom: `solid 1px ${theme.palette.divider}`, p: 0.5 }}
														>
															{item}
														</Typography>
													))}
												</Stack>
											</Grid>
										</Grid>
									</StyledCard>
								</Stack>
							</Grid>

							<Grid item md={7}>
								<Stack spacing={2}>
									{loadingImages ? (
										<Skeleton variant="rectangular" height={380} width="100%" sx={{ borderRadius: '10px' }} />
									) : (
										<Card
											shadows={false}
											contentSx={{ m: 0 }}
											content={(
												<GalleryCarousel title={t('similarVehicles')} max={{ md: 5, sm: 6, xs: 3 }} data={images || []} />
											)}
										/>
									)}

									<PersonalDataConfirm />

									<CardSelector
										title={t('cards')}
										minHeight={250}
										loading={useFetchCards.loading}
										cards={useFetchCards.data || []}
										onSelect={handleCardClick}
										onSelectModeChange={handleCardSelectModeChange}
										onStripeFormComplete={(complete) => setStripeElementComplete(complete)}
										onShouldSaveCardChange={(should) => setStripeShouldSaveCard(should)}
									/>

									<Stack>
										<ShortTitle>{t('driverRequirements')}</ShortTitle>
										<Grid container item md={12} xs={12} spacing={1} sx={{ mt: 1, mb: 2 }}>
											<Stack direction="row" justifyContent="flex-start">
												<LabelIconBox iconColor={theme.palette.title?.color} icon={Person} label={t('driverAge')} />
												<LabelIconBox iconColor={theme.palette.title?.color} icon={CalendarViewDay} label={t('drivingLicense')} />
												<LabelIconBox iconColor={theme.palette.title?.color} icon={Payment} label={t('creditCards')} />
											</Stack>
										</Grid>

										<Stack direction="row" spacing={2} sx={{ mt: 2, mb: 2 }} alignItems="center">
											<Box sx={{ flexGrow: 1 }} />
											<PriceInfo label={t('day')} pricePerDay={pricePerDayWithDiscount} amount={finalValue} notConvert />
											<DefaultButton
												loading={
													useNewReservation.loading || useCompletePayment.loading || stripeLoading
												}
												disabled={
													user === undefined
													|| (appSettings.requireCreditPayment ? cardSelectMode === 'saved-card' ? !selCard : !stripeElementComplete : false)
												}
												onClick={handleClick}
												sx={{ alignSelf: 'end' }}
												text={t('reserve')}
											/>
											<Box sx={{ flexGrow: 1 }} />
										</Stack>
									</Stack>
								</Stack>
							</Grid>
						</Grid>
					</DialogContent>
				</>
			</Dialog>
			<ConfirmDialog
				open={openConfirm}
				title={t('wantToOrderNow')}
				message={t('clickToConfirmBooking')}
				confirmBtnText={t('reserve')}
				onConfirm={handleConfirmClick}
				onClose={handleConfirmClose}
			/>

		</>
	);
};

export default CategoryDialog;
